import { MOONGATE_PRIVACY_URL, MOONGATE_TERMS_URL } from "@lib/misc";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import {
  braveWallet,
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  rainbowWallet,
  safeWallet,
  trustWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
import { SiweMessage } from "siwe";
import { configureChains, createConfig } from "wagmi";
import { avalanche, mainnet, polygon } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";

const WALLETCONNECT_PROJECT_ID =
  process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID || "";
const projectId = WALLETCONNECT_PROJECT_ID;
const appName = "Moongate";

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, polygon, avalanche],
  [publicProvider()]
);

let wagmiConfig;

export const initializeWagmiConfig = () => {
  if (wagmiConfig) return wagmiConfig;
  if (!projectId) return null;
  const connectors = connectorsForWallets([
    {
      groupName: "Popular",
      wallets: [
        injectedWallet({ chains }),
        metaMaskWallet({ projectId, chains }),
        walletConnectWallet({ projectId, chains }),
        rainbowWallet({ projectId, chains }),
        coinbaseWallet({
          appName,
          chains,
        }),
      ],
    },
    {
      groupName: "More",
      wallets: [
        trustWallet({ projectId, chains }),
        safeWallet({ chains }),
        braveWallet({ chains }),
        // ledgerWallet({ projectId, chains }),
      ],
    },
  ]);

  wagmiConfig = createConfig({
    autoConnect: false,
    connectors,
    publicClient,
    webSocketPublicClient,
  });
  return wagmiConfig;
};

export const signingMessage = async (address: string, nonce: string) => {
  let statement = "Welcome to MOONGATE!\n\n";
  statement += `By signing, you accept the MOONGATE Terms of Service:\n${MOONGATE_TERMS_URL}\nand Privacy Policy:\n${MOONGATE_PRIVACY_URL}\n\n`;
  statement +=
    "This request will not trigger a blockchain transaction or cost any gas fees.\n\n";
  // statement += `To stop hackers using your wallet, here's a unique message ID they can't guess:\n${nonce}`;

  const message = new SiweMessage({
    domain: window.location.host,
    address,
    statement,
    uri: window.location.origin,
    version: "1",
    chainId: 1,
    nonce,
  });
  return message;
};
