import { ComponentStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const Select: ComponentStyleConfig = {
  baseStyle: {
    bg: "rgba(252, 252, 253, 0.25)",
    border: "1px solid",
    borderRadius: "4px",
    borderColor: " #E6E8EC",
    width: "100%",

    field: {
      bg: "transparent",
      // bg: "linear-gradient(180deg,rgba(250, 250, 251, 0.16) 0%,rgba(69, 191, 176, 0.159375) 0.01%,rgba(69, 191, 176, 0.04) 100%)",
    },
  },
  variants: {
    simple: (props) => ({
      field: {
        border: "1px solid",
        borderRadius: "4px",
        /* borderColor: mode("neutral.100", "#E6E8EC")(props), */
        borderColor: mode(
          "rgb(226, 232, 240)",
          "rgba(255, 255, 255, 0.16)"
        )(props),
        _disabled: {
          bg: "#2C2C2C",
          cursor: "not-allowed",
        },
        _invalid: {
          borderColor: "error",
        },
        _focus: {
          bg: "rgba(252, 252, 253, 0.05)",
          borderColor: "none",
        },
        minH: "fit-content",
        height: "auto",
        p: "3",
        lineHeight: "1.2em",
      },
    }),
  },
  defaultProps: {
    variant: "simple",
  },
};

export default Select;
