import { ComponentStyleConfig } from "@chakra-ui/react";

export const Table: ComponentStyleConfig = {
  baseStyle: {
    td: {
      backgroundColor: "neutral.850",
    },
  },
  variants: {
    simple: {
      table: {},
      th: {
        borderBottom: "0 solid",
      },
      td: {
        borderColor: "neutral.600",
        borderBottom: "1px solid",
      },
      tbody: {
        tr: {
          ":first-of-type": {
            td: {
              ":first-of-type": {
                borderTopLeftRadius: "1em",
              },
              ":last-child": {
                borderTopRightRadius: "1em",
              },
            },
          },
          ":last-child": {
            td: {
              borderBottom: "0",
              ":first-of-type": {
                borderBottomLeftRadius: "1em",
              },
              ":last-child": {
                borderBottomRightRadius: "1em",
              },
            },
          },
        },
      },
    },
  },
  defaultProps: {
    variant: "simple",
  },
};

export default Table;
