import { compact, isEmpty, snakeCase } from "lodash";
import { CASE_SENSITIVE_NETWORKS, Network } from "../network";
import { compareText, normalizeId, toJSON, unique } from "../utils";

export const NFT_ID_SEP = ":";
export const NFT_CACHE_PERIOD = 60000;

export enum NFTStandard {
  ERC721 = "ERC721",
  ERC1155 = "ERC1155",
}

export const DEFAULT_DEPLOYMENT_STANDARD = NFTStandard.ERC721;

export const MULTI_OWNER_NFT_STANDARDS: string[] = [NFTStandard.ERC1155];

export const toNFTId = (arr: string[]): string => {
  try {
    return compact(arr).join(NFT_ID_SEP);
  } catch (err) {
    console.error(err);
    return "";
  }
};

export const parseNFTId = (id: string) => {
  let network = "";
  let collectionId = "";
  let tokenId = "";
  try {
    const ids = id.split(NFT_ID_SEP);
    if (ids.length >= 3) {
      network = ids[0];
      collectionId = ids[1];
      tokenId = ids[2];
    } else if (ids.length == 2) {
      network = ids[0];
      tokenId = ids[1];
    }
    return { network, collectionId, tokenId };
  } catch (err) {
    return { network, collectionId, tokenId };
  }
};

export const parseCollectionId = (id: string) => {
  try {
    const [network, collectionId] = id.split(NFT_ID_SEP);
    return { network, collectionId };
  } catch (err) {
    return { network: "", collectionId: "" };
  }
};

export const toNFTCollectionName = (name?: string): string => {
  return normalizeId(snakeCase(name || ""));
};

export const normalizeAddress = (network: Network, address: string): string => {
  try {
    if (!address) return "";
    if (!network) return address;
    return CASE_SENSITIVE_NETWORKS.includes(network)
      ? address
      : normalizeId(address);
  } catch (error) {
    return address;
  }
};
export interface NFTAttribute {
  traitType: string;
  value: string | number;
}

export interface NFTCollectionParam extends Partial<NFTCollection> {
  collectionId: string;
  network: Network;
}

export class NFTCollection implements NFTCollectionParam {
  id: string;
  collectionId: string;
  collectionName: string;
  simplehashId: string;
  webhookId: string;
  standard: NFTStandard | string;
  network: Network;
  description: string;
  tokenCount: number;
  nextCursor: string;
  updatedAt: number;
  imageUrl: string;
  name: string;
  symbol: string;
  deployer: string;
  bannerImageUrl: string;
  externalUrl: string;
  tokens?: string[];

  constructor(params: NFTCollectionParam) {
    this.network = params.network;
    this.collectionId = normalizeId(params.collectionId);
    this.id = toNFTId([this.network, this.collectionId]);
    this.simplehashId = params.simplehashId || "";
    this.webhookId = params.webhookId || "";
    this.name = params.name || params.collectionName || "";
    this.symbol = params.symbol || "";
    this.deployer = normalizeAddress(this.network, params.deployer || "");
    this.collectionName = params.collectionName || this.name;
    this.description = params.description || "";
    this.standard = params.standard || this.network.toUpperCase();
    this.nextCursor = params.nextCursor || "";
    this.tokenCount = Number(params.tokenCount ?? 0);
    this.updatedAt = Number(params.updatedAt || Date.now());
    this.imageUrl = params.imageUrl || "";
    this.bannerImageUrl = params.bannerImageUrl || this.imageUrl;
    this.externalUrl = params.externalUrl || "";
    if (params.tokens) {
      this.tokens = params.tokens;
    } else {
      if (this.network == Network.SOLANA) this.tokens = [];
    }
  }

  get json() {
    return toJSON(this);
  }

  get isRegistered() {
    return (
      this.id &&
      this.network &&
      this.collectionId &&
      this.webhookId &&
      this.simplehashId &&
      this.name &&
      this.standard &&
      this.nextCursor === ""
    );
  }
}

export interface NFTOwner {
  address: string;
  quantity: number;
}

export interface NFTCollectionInfo {
  name?: string;
  description?: string;
  imageUrl?: string;
  url?: string;
}
export interface NFTParam extends Partial<NFT> {
  network: Network;
  collectionId: string;
  tokenId: string;
  collectionImageUrl?: string; // deprecated
}
export class NFT implements NFTParam {
  id: string;
  owners: NFTOwner[];
  ownerAddresses: string[];
  network: Network;
  collectionId: string;
  tokenId: string;
  updatedAt: number;
  standard: NFTStandard | string;
  attributes: NFTAttribute[];

  name: string;
  imageUrl: string;
  videoUrl: string;
  audioUrl: string;
  modelUrl: string;
  marketplaceUrl: string;
  description: string;
  animationUrl?: string;
  collection?: NFTCollectionInfo;

  initial?: boolean; // initial state on Moongate NFT airdrop
  tokenCount: number; // ERC1155
  ownerCount: number; // ERC1155

  constructor(params: NFTParam) {
    this.network = params.network;
    this.collectionId = normalizeId(params.collectionId);
    this.tokenId = normalizeAddress(this.network, params.tokenId);
    this.standard = params.standard
      ? params.standard.toUpperCase()
      : this.network.toUpperCase();
    this.id = toNFTId([this.network, this.collectionId, this.tokenId]);
    this.owners =
      params.owners && !isEmpty(params.owners)
        ? params.owners.map((owner) => ({
            ...owner,
            address: normalizeAddress(this.network, owner.address),
          }))
        : [];
    this.ownerAddresses = unique(this.owners.map((owner) => owner.address));
    this.name = params.name || this.id;
    this.description = params.description || "";
    this.imageUrl = params.imageUrl || "";
    this.videoUrl = params.videoUrl || "";
    this.audioUrl = params.audioUrl || "";
    this.modelUrl = params.modelUrl || "";
    this.marketplaceUrl = params.marketplaceUrl || "";
    this.attributes = params.attributes || [];
    this.updatedAt = Number(params.updatedAt || Date.now());
    if (params.animationUrl) this.animationUrl = params.animationUrl;
    if (params.collection) {
      this.collection = params.collection;
    } else if (params.collectionImageUrl) {
      this.collection = { imageUrl: params.collectionImageUrl };
    }
    if (params.initial) this.initial = true;
    this.tokenCount = Number(params.tokenCount || 1);
    this.ownerCount = Number(params.ownerCount || 1);
  }

  get json() {
    return toJSON(this);
  }

  update() {
    this.updatedAt = Date.now();
    return this;
  }

  updateOwners() {
    this.owners = this.owners.filter((owner) => owner.quantity > 0);
    if (!MULTI_OWNER_NFT_STANDARDS.includes(this.standard))
      this.owners = this.owners.map((owner) => ({
        address: owner.address,
        quantity: 1,
      }));
    this.ownerAddresses = unique(this.owners.map((owner) => owner.address));
    this.tokenCount = this.owners.reduce(
      (acc, cur) => (acc += cur.quantity),
      0
    );
    this.ownerCount = this.owners.length;
    return this;
  }

  ownedBy(address: string) {
    return (
      this.owners.find((owner) => compareText(owner.address, address))
        ?.quantity || 0
    );
  }

  transfer(network: Network, from: string, to: string, quantity: number) {
    if (!to) return this;
    from = normalizeAddress(network, from || "");
    to = normalizeAddress(network, to || "");
    // Multi-Owner NFTs
    if (MULTI_OWNER_NFT_STANDARDS.includes(this.standard)) {
      const toIdx = this.owners.findIndex((owner) =>
        compareText(owner.address, to)
      );
      const fromIdx = this.owners.findIndex((owner) =>
        compareText(owner.address, from)
      );
      if (fromIdx != -1) this.owners[fromIdx].quantity -= quantity;
      toIdx == -1
        ? this.owners.push({ address: to, quantity })
        : (this.owners[toIdx].quantity += quantity);
    } else {
      // Single Owner NFTs
      this.owners = [{ address: to, quantity: 1 }];
    }
    return this.updateOwners();
  }

  toNFTOwnership(owner: string) {
    return new NFTOwnership({
      owner,
      network: this.network,
      collectionId: this.collectionId,
      tokenId: this.tokenId,
      standard: this.standard,
      updatedAt: this.updatedAt,
      quantity:
        this.owners.find((_owner) => _owner.address == owner)?.quantity || 1,
      attributes: this.attributes,
      imageUrl: this.imageUrl,
    });
  }
}

export interface INFTOwnership extends Partial<NFTOwnership> {
  owner: string;
  network: Network;
  collectionId: string;
  tokenId: string;
}

export class NFTOwnership implements INFTOwnership {
  id: string;
  owner: string;
  network: Network;
  collectionId: string;
  tokenId: string;
  updatedAt: number;
  quantity: number;
  standard: string;
  attributes: NFTAttribute[];
  imageUrl: string;

  constructor(params: INFTOwnership) {
    this.network = params.network;
    this.collectionId = normalizeId(params.collectionId);
    this.tokenId = normalizeAddress(this.network, params.tokenId);
    this.standard = params.standard
      ? params.standard.toUpperCase()
      : this.network.toUpperCase();
    this.owner = normalizeAddress(this.network, params.owner);
    this.id = toNFTId([this.network, this.collectionId, this.tokenId]);
    this.updatedAt = Number(params.updatedAt || Date.now());
    this.network = params.network;
    this.quantity = Number(params.quantity || 1);
    this.attributes = params.attributes || [];
    this.imageUrl = params.imageUrl || "";
  }

  get json() {
    return toJSON(this);
  }

  update() {
    this.updatedAt = Date.now();
  }
}
