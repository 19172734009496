import { toJSON } from "../utils";
import { JobParam } from "./job";

export interface RunnerConfigParam {
  jobs: { job: JobParam; critical: boolean; order?: number }[];
}

export class RunnerConfig {
  jobs: { job: JobParam; critical: boolean; order: number }[];

  constructor(params: RunnerConfigParam) {
    this.jobs = params.jobs
      .map((jobConfig, i) => ({
        job: jobConfig.job,
        critical: !!jobConfig.critical,
        order: jobConfig.order || i,
      }))
      .sort((a, b) => a.order - b.order);
  }

  get json() {
    return toJSON(this);
  }
}
