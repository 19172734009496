import { isEmpty } from "lodash";
import { Base } from "../base";
import { REGEX_TEMPLATE_VARIABLE } from "../regex";
import { cryptoId, normalizeId } from "../utils";

export enum JobName {
  AIRDROP_NFT = "airdrop_nft",
  DEPLOY_CONTRACT = "deploy_contract",
  CREATE_DISCOUNT = "create_discount",
}

export enum JobStatus {
  CREATED = "created",
  PENDING = "pending", // Pending to be triggered
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  FAILED = "failed",
}

export const ENDED_JOB_STATUS = [JobStatus.COMPLETED, JobStatus.FAILED];
export const NON_PROCESSABLE_JOB_STATUS = [
  JobStatus.COMPLETED,
  JobStatus.IN_PROGRESS,
];

const JOB_TEMPLATE_VARIABLES = [
  "user",
  "userId",
  "eventId",
  "tierId",
  "transactionId",
] as const;
export type JobTemplateVariable = (typeof JOB_TEMPLATE_VARIABLES)[number];

export const substituteJobTemplateVariables = (
  data: any,
  values: { [key in JobTemplateVariable]?: string }
) => {
  try {
    for (const [key, value] of Object.entries(data)) {
      if (
        !key ||
        !value ||
        typeof value !== "string" ||
        !value.match(REGEX_TEMPLATE_VARIABLE)
      )
        continue;
      const _value = value.replace(/[\{\}]/g, "");
      for (const variable of JOB_TEMPLATE_VARIABLES) {
        if (_value !== variable) continue;
        data[key] = values[variable] || "";
      }
    }
  } catch (error) {}
  return data;
};

export interface JobParam extends Partial<Job> {}
export class Job extends Base implements JobParam {
  name: JobName | string;
  status: JobStatus;
  code: string;
  data: any;
  refIds?: string[]; // can be transactionId => eventId => tierId / productId, etc.
  error?: any;
  result?: any;
  recurrent?: boolean;
  retry: number;

  constructor(params: JobParam) {
    super(params);
    this.name = params.name || "";
    this.status = params.status || JobStatus.CREATED;
    this.code = normalizeId(params.code || cryptoId(4));
    this.data = params.data || {};
    if (params.refIds && !isEmpty(params.refIds)) this.refIds = params.refIds;
    if (params.result) this.result = params.result;
    if (params.error) this.error = params.error;
    if (params.recurrent) this.recurrent = true;
    this.retry = Number(params.retry ?? 0);
  }
}
