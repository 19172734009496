import { default as Image, ImageProps } from "next/image";

export const NextImage = (props?: ImageProps) => {
  return props?.src ? (
    <div className={props.className}>
      <Image
        {...props}
        src={props.src}
        alt={props.alt || ""}
        layout={props.layout || "responsive"}
        loading="lazy"
      />
    </div>
  ) : (
    <></>
  );
};
