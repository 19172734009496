import { WarningIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormErrorMessage,
  Input,
  useColorMode,
} from "@chakra-ui/react";
import { to } from "@lib/utils";
import { REGEX_EMAIL } from "@models/regex";
import { UserError } from "@models/user";
import { useConnect } from "@particle-network/auth-core-modal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MoongateIconColor } from "../../assets";
import Dialog from "../../components/Dialog/Dialog";
import LoadingAnimation from "../../components/Loader/LoadingAnimation";
import { NextImage } from "../../components/NextImage/NextImage";
import { addUserEmail, logOut, useUserStore } from "./auth/services";

const InputEmailModal = () => {
  const user = useUserStore((state) => state.user);
  const authenticating = useUserStore((state) => state.authenticating);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();
  const { colorMode } = useColorMode();
  const { disconnect } = useConnect();

  const close = () => {
    setShow(false);
  };

  const back = () => {
    setError("");
  };

  useEffect(() => {
    if (!user) return;
    setShow(!user.email);
  }, [user]);

  const updateEmail = async (userId: string, email: string) => {
    if (!userId || !email) return;
    setLoading(true);
    const { err } = await to(addUserEmail(userId, email));
    if (err) {
      switch (err) {
        case UserError.EMAIL_INUSE: {
          setError("Email already in use by another user.");
          break;
        }
        default: {
          setError("Error updating email. Please try again.");
        }
      }
    }
    setLoading(false);
  };

  if (!user || authenticating) return <></>;
  return (
    <>
      <Dialog
        isOpen={show}
        isFooter={false}
        isClose={false}
        // onClose={close}
      >
        <div className="p-5 sm:py-14">
          {loading && (
            <div className="flex flex-row justify-center p-16">
              <LoadingAnimation />
            </div>
          )}

          {!loading && !error && (
            <div className="sm:max-w-[20rem] min-w-fit w-72">
              <div className="flex flex-col items-center justify-center mb-8 text-center">
                <div className="w-12 mb-8 svg-primary">
                  <NextImage
                    src={MoongateIconColor}
                    alt="moongate"
                    layout="responsive"
                  />
                </div>
                <p className="text-2xl font-semibold capitalize dark:text-white">
                  One more step
                </p>
                <p className="text-base my-4 dark:text-white">
                  Please enter your email
                </p>
              </div>

              <div className="flex flex-col items-center justify-center">
                <form
                  className="w-full"
                  onSubmit={handleSubmit((data) => {
                    updateEmail(user.id, data.email);
                  })}
                >
                  <FormControl isInvalid={isDirty && !!errors.email}>
                    <Input
                      className="bg-glass-flat rounded-lg w-full border border-neutral-600 cursor-pointer hover:border-primary hover:text-primary dark:text-white"
                      placeholder="Email address"
                      padding="4"
                      {...register("email", {
                        required: "Required",
                        pattern: {
                          value: REGEX_EMAIL,
                          message: "Invalid email address",
                        },
                      })}
                    />
                    <FormErrorMessage>
                      {errors.email && String(errors.email.message || "")}
                    </FormErrorMessage>
                  </FormControl>

                  <button
                    className="mt-4 bg-glass-green rounded-lg p-4 border cursor-pointer border-primary text-primary text-sm font-semibold capitalize justify-center w-full hover:opacity-80"
                    type="submit"
                  >
                    submit
                  </button>
                </form>

                {/* <button
                  className="mt-4 capitalize underline text-sm text-neutral-500 dark:text-secondary"
                  onClick={close}
                >
                  skip
                </button> */}

                <button
                  className="mt-4 capitalize underline text-sm text-neutral-500 dark:text-secondary"
                  onClick={() => {
                    logOut(disconnect);
                  }}
                >
                  cancel
                </button>
              </div>
            </div>
          )}

          {!loading && error && (
            <div className="flex flex-col items-center justify-center m-8 text-center dark:text-white">
              <WarningIcon color="error" boxSize={8} />
              <p className="my-8">{error}</p>
              <button
                className="mt-4 capitalize underline text-sm text-neutral-500 dark:text-secondary"
                onClick={back}
              >
                back
              </button>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default InputEmailModal;
