import { User, UserParam } from "@models/user";
import { Wallet, WalletError } from "@models/wallet";
import * as Sentry from "@sentry/browser";
import { SiweMessage } from "siwe";
import { apiClient, useUserStore } from "./auth";

export const addUserWallet = async (
  userId: string,
  wallet: Wallet,
  siweMessage: SiweMessage,
  signature: string
) => {
  Sentry.addBreadcrumb({
    category: "addUserWallet",
    level: "info",
  });
  const _user: UserParam = await apiClient.post(
    `/users/${userId}/wallets`,
    {
      network: wallet.network,
      address: wallet.address,
      provider: wallet.provider,
      siweMessage,
      signature,
    },
    true
  );
  if (!_user) throw new Error(WalletError.UNKNOWN_ERROR);
  const user = new User(_user);
  Sentry.setUser({
    authId: user.authId,
    email: user.email,
    address: wallet.address,
  });
  useUserStore.setState({ user });
  return user;
};

export const removeUserWallet = async (userId: string, wallet: Wallet) => {
  Sentry.addBreadcrumb({
    category: "removeUserWallet",
    level: "info",
  });
  const _user: UserParam = await apiClient.del(`/users/${userId}/wallets`, {
    network: wallet.network,
    address: wallet.address,
  });
  if (!_user) throw new Error(WalletError.UNKNOWN_ERROR);
  const user = new User(_user);
  Sentry.setUser({
    authId: user.authId,
    email: user.email,
    address: wallet.address,
  });
  useUserStore.setState({ user });
  return user;
};

export const deleteUser = async (user: User) => {
  Sentry.addBreadcrumb({
    category: "deleteUser",
    level: "info",
  });
  Sentry.setUser({
    authId: user.authId,
    email: user.email,
    address: user.walletAddresses.join(","),
  });
  const _user: UserParam = await apiClient.del(`/users/${user.id}`);
  if (!_user) return false;
  useUserStore.setState({ user: null });
  return true;
};

export const addUserEmail = async (userId: string, email: string) => {
  Sentry.addBreadcrumb({
    category: "addUserEmail",
    level: "info",
  });
  const _user: UserParam = await apiClient.patch(
    `/users/${userId}/email`,
    {
      email,
    },
    true
  );
  if (!_user) return false;
  const user = new User(_user);
  Sentry.setUser({
    authId: user.authId,
    email: user.email,
  });
  useUserStore.setState({ user });
  return true;
};
