import { Base } from "../base";
import { normalizeId } from "../utils";

export interface UserParamBase extends Partial<UserBase> {}
export class UserBase extends Base implements UserParamBase {
  email: string;
  username: string;

  constructor(params?: UserParamBase) {
    super(params);
    this.email = normalizeId(params?.email || "");
    this.username = params?.username || "";
  }
}
