import { isEmpty } from "lodash";
import { NFTConstraint, NFTConstraintParam } from "../event/constraint/nft";
import { TokenConstraint } from "../event/constraint/token";
import { FormField } from "../form";
import { div, minus, round, times } from "../math";
import { matchRegex } from "../regex";
import { MS_END } from "../timezone";
import { normalizeId, toJSON, unique } from "../utils";
import { PAYMENT_AMOUNT_PRECISION } from "./constants";
import {
  AutoDiscountConfig,
  AutoDiscountConfigParam,
  Discount,
} from "./discount";
import { IPaymentPrice, PaymentPrice } from "./price";

export enum PaymentMethod {
  PAYPAL = "paypal",
  STRIPE = "stripe",
  COINGATE = "coingate",
}

export interface StripeConfigParam extends Partial<StripeConfig> {}

export enum PaymentError {
  UNKNOWN = "payment/unknown",
  UNKNOWN_TRANSACTION = "payment/unknown_transaction",
  TRANSACTION_PROCESSED = "payment/transaction_processed",
  UNKNOWN_EVENT = "payment/unknown_event",
  UNKNOWN_TIER = "payment/unknown_tier",
  UNKNOWN_USER = "payment/unknown_user",
  UNKNOWN_PRODUCT = "payment/unknown_product",
  HIDDEN_PRODUCT = "payment/hidden_product",
  UNKNOWN_EMAIL = "payment/unknown_email",
  UNKNOWN_PAYMENT_METHOD = "payment/unknown_payment_method",
  SAVE_TRANSACTION_FAILED = "payment/save_transaction_failed",
  CREATE_PAYMENT_SESSION_FAILED = "payment/create_payment_session_failed",
  TRANSACTION_MODIFIED = "payment/transaction_modified",
  INVITE_ONLY = "payment/invite_only",
  WHITELIST_ONLY = "payment/whitelist_only",
  NFT_HOLDER_ONLY = "payment/nft_holder_only",
  TOKEN_HOLDER_ONLY = "payment/token_holder_only",

  DISCOUNT_MODIFIED = "payment/discount_modified",
  PURCHASE_LIMIT_EXCEEDED = "payment/purchase_limit_exceeded",
  DISCOUNT_LIMIT_EXCEEDED = "payment/discount_limit_exceeded",
  SOLD_OUT = "payment/sold_out",
  SALES_NOT_STARTED = "payment/sales_not_started",
  SALES_ENDED = "payment/sales_ended",
  FORBIDDEN = "payment/forbidden",
}

export const DEFAULT_MAX_PER_TRANSACTION = 5;
export interface PaymentConfigParam
  extends Omit<
    Partial<PaymentConfig>,
    "prices" | "nftConstraint" | "autoDiscounts"
  > {
  prices?: IPaymentPrice[];
  nftConstraint?: NFTConstraintParam;
  autoDiscounts?: AutoDiscountConfigParam[];
  stripeConfig?: StripeConfigParam;

  // @deprecated
  maxPerNFT?: number; // changed to nftConvertRatio
}

export class StripeConfig implements StripeConfigParam {
  subscription?: {
    interval: "day" | "week" | "month" | "year";
    intervalCount: number;
  };

  constructor(params: StripeConfigParam) {
    if (params.subscription)
      this.subscription = {
        interval: params.subscription.interval || "month",
        intervalCount: params.subscription.intervalCount || 1,
      };
  }
}

export class PaymentConfig implements PaymentConfigParam {
  prices: PaymentPrice[];
  max: number; // -1 for unlimited.  Equivalent to whitelistConstraint.max
  customForm?: FormField[];
  inviteOnly?: boolean;
  maxPerTransaction: number; // Max quantity per transaction
  maxPerUser?: number; // Max transaction per user account (search by identifiers).
  start: number; // Sales start
  end: number; // Sales end
  nftConstraint?: NFTConstraint;
  nftConvertRatio?: number; // Only if nftConstraint is set. No. of NFT : 1 item. -1 for unlimited.
  tokenConstraint?: TokenConstraint;
  tokenConvertRatio?: number; // Only if tokenConstraint is set. No. of Token : 1 item. -1 for unlimited.
  disableBuyForOthers?: boolean; // If set, set maxPerTransaction to 1
  whitelist?: string[]; // string or regex string. If set, set disableBuyForOthers to true
  externalWhitelist?: boolean; // extra whitelist over size limit stored in another collection
  autoDiscounts?: AutoDiscountConfig[];

  stripeConfig?: StripeConfig;

  constructor(params?: PaymentConfigParam) {
    this.prices =
      params?.prices && !isEmpty(params.prices)
        ? params.prices
            .map((price) => new PaymentPrice(price))
            .sort((a, b) => a.end - b.end || a.price - b.price)
        : [new PaymentPrice()];
    this.max = Number(params?.max || -1);
    this.maxPerTransaction = Number(
      params?.maxPerTransaction || DEFAULT_MAX_PER_TRANSACTION
    );
    this.start = Number(params?.start ?? 0);
    this.end = Number(params?.end ?? MS_END);

    if (params?.customForm) this.customForm = params.customForm;
    if (params?.inviteOnly) this.inviteOnly = !!params.inviteOnly;
    if (params?.nftConstraint) {
      this.nftConstraint = new NFTConstraint(params.nftConstraint);
      this.nftConvertRatio = Number(
        params?.nftConvertRatio || params?.maxPerNFT || -1
      );
    }
    if (params?.tokenConstraint) {
      this.tokenConstraint = new TokenConstraint(params.tokenConstraint);
      this.tokenConvertRatio = Number(params?.tokenConvertRatio || -1);
    }
    if (params?.maxPerUser) this.maxPerUser = Number(params.maxPerUser);
    if (params?.disableBuyForOthers !== undefined)
      this.disableBuyForOthers = !!params.disableBuyForOthers;
    if (params?.whitelist && !isEmpty(params.whitelist)) {
      this.whitelist = unique(params.whitelist.map((w) => normalizeId(w)));
      if (!params?.maxPerTransaction) {
        this.disableBuyForOthers = true;
        this.maxPerTransaction = 1;
      }
    }
    if (params?.externalWhitelist) this.externalWhitelist = true;

    if (this.disableBuyForOthers) this.maxPerTransaction = 1;

    if (params?.autoDiscounts && !isEmpty(params.autoDiscounts)) {
      this.autoDiscounts = params.autoDiscounts
        .filter(
          (d) =>
            d.criteria &&
            d.discount &&
            !isEmpty(d.criteria) &&
            !isEmpty(d.discount)
        )
        .map((d, i) => ({
          priority: d.priority ?? i,
          criteria: d.criteria,
          discount: new Discount({ ...d.discount, reusable: true, auto: true }),
        }))
        .sort((a, b) => a.priority - b.priority);
    }

    if (params?.stripeConfig) {
      this.stripeConfig = new StripeConfig(params.stripeConfig);
    }
  }

  get json() {
    return toJSON(this);
  }

  get defaultPrice() {
    try {
      return this.prices.sort((a, b) => b.end - a.end || b.price - a.price)[0];
    } catch (error) {}
    return this.prices[0];
  }

  get currentPrice() {
    try {
      if (this.prices.length == 1) return this.prices[0];
      const now = Date.now();
      this.prices = this.prices.sort(
        (a, b) => a.end - b.end || a.price - b.price
      );
      const n = this.prices.length;
      for (let i = 0; i < n; i++) {
        const price = this.prices[i];
        if (now < price.end) {
          if (price.max == -1) return price;
          if (price.sold < price.max) return price;
          const _prices = this.prices.slice(i + 1);
          return (
            _prices.find(
              (_price) => _price.max === -1 || _price.sold < _price.max
            ) || this.defaultPrice
          );
        }
      }
    } catch (error) {}
    return this.defaultPrice;
  }

  get sold() {
    return this.prices.reduce((acc, cur) => (acc += cur.sold), 0);
  }

  get currentPriceDiscountPercentage() {
    try {
      if (this.defaultPrice.price == this.currentPrice.price) return 0;
      return Math.max(
        round(
          times(
            div(
              minus(this.defaultPrice.price, this.currentPrice.price),
              this.defaultPrice.price
            ),
            100
          ),
          PAYMENT_AMOUNT_PRECISION
        ),
        0
      );
    } catch (error) {}
    return 0;
  }

  get isWhitelisted() {
    return !isEmpty(this.whitelist);
  }

  get subscriptionInterval() {
    if (
      !this.stripeConfig?.subscription ||
      isEmpty(this.stripeConfig?.subscription)
    )
      return "";
    const interval = this.stripeConfig.subscription.interval;
    const intervalCount = this.stripeConfig.subscription.intervalCount;

    return [
      "every",
      intervalCount > 1 ? intervalCount : "",
      `${interval}${intervalCount > 1 ? "s" : ""}`,
    ].join(" ");
  }

  get isNFTGated() {
    return !isEmpty(this.nftConstraint);
  }

  checkWhitelist(identifier: string) {
    if (!this.whitelist || isEmpty(this.whitelist)) return true;
    identifier = normalizeId(identifier);
    return this.whitelist.some((w) => {
      if (w.startsWith("/") && w.endsWith("/")) {
        const regex = new RegExp(w.replace(/^\//, "").replace(/\/$/, ""), "im");
        return matchRegex(regex, identifier);
      }
      return w === identifier;
    });
  }
}
