import { ComponentStyleConfig } from "@chakra-ui/react";

export const Form: ComponentStyleConfig = {
  baseStyle: {
    requiredIndicator: {
      color: "primary",
    },
    helperText: {
      color: "neutral.100",
      m: 0,
      mb: 2,
      _empty: {
        mb: 0,
      },
    },
  },
  variants: {},
  defaultProps: {},
};

export default Form;
