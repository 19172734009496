import { isEmpty } from "lodash";
import { DEFAULT_LANGUAGE, Language, Translation } from "../language";
import { toJSON } from "../utils";

export enum EmailTemplateType {
  PAYMENT_CONFIRMED = "paymentConfirmed",
  PAYMENT_FAILED = "paymentFailed",
  REDEEM_CODE = "redeemCode",
  AIRDROP_RECEIVED = "airdropReceived",
  BENEFIT_RECEIVED = "benefitReceived",
}

export interface NFTEventTierEmailConfigParam
  extends Partial<NFTEventTierEmailConfig> {}

export interface NFTEventEmailCustomSection {
  title: string;
  content: string;
  links?: { text: string; url: string };
}

export class NFTEventTierEmailConfig implements NFTEventTierEmailConfigParam {
  templates: {
    [type in EmailTemplateType]?: Translation<{
      id: string;
      customSections?: NFTEventEmailCustomSection[];
    }>;
  };

  constructor(params: NFTEventTierEmailConfigParam) {
    this.templates = {};
    if (params.templates && !isEmpty(params.templates)) {
      for (const type of Object.values(EmailTemplateType)) {
        if (type in params.templates) {
          this.templates[type] = params.templates[type];
        }
      }
    }
  }

  get json() {
    return toJSON(this);
  }

  localeTemplate(
    type: EmailTemplateType,
    language: Language = DEFAULT_LANGUAGE
  ) {
    try {
      if (!(type in this.templates)) return { id: "" };
      if (language in this.templates[type]!)
        return this.templates[type]![language];
      return this.templates[type]![DEFAULT_LANGUAGE] || { id: "" };
    } catch (error) {
      return { id: "" };
    }
  }
}
