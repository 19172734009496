import { AppError } from "./error/app";
import { EVM_NETWORKS, Network, SUPPORTED_NETWORKS } from "./network";
import { normalizeAddress } from "./nft";
import { REGEX_EVM_ADDRESS, REGEX_SOL_ADDRESS, matchRegex } from "./regex";
import { toJSON, walletAddressPreview } from "./utils";

export enum WalletError {
  INVALID_WALLET = "invalid_wallet_address",
  WALLET_INUSE = "wallet_in_use",
  UNKNOWN_ERROR = "unknown_error",
}

export enum WalletProvider {
  WEB3AUTH = "web3auth",
  PARTICLE_NETWORK = "particle_network",
  MAGIC_LINK = "magic_link",
}

export const DEFAULT_WALLET_PROVIDER = WalletProvider.PARTICLE_NETWORK;

export interface WalletParam extends Partial<Wallet> {
  network: Network;
  address: string;
  web3auth?: boolean;
}

export class Wallet implements WalletParam {
  network: Network;
  address: string;
  connectedAt: number;
  nickname: string;
  verified: boolean;
  disconnected: boolean;
  provider?: WalletProvider;

  constructor(params: WalletParam) {
    this.network = EVM_NETWORKS.includes(params.network)
      ? Network.EVM
      : params.network;
    this.address = normalizeAddress(this.network, params.address);
    this.connectedAt = Number(params.connectedAt || Date.now());
    this.nickname = params.nickname || "";
    this.verified = !!params.verified;
    this.disconnected = !!params.disconnected;
    if (params.provider) {
      this.provider = params.provider;
    } else if (params.web3auth) {
      this.provider = WalletProvider.WEB3AUTH;
    }
  }

  get json() {
    return toJSON(this);
  }

  get addressPreview() {
    return walletAddressPreview(this.address);
  }
}

export const isEVMAddress = (address: string) => {
  return matchRegex(REGEX_EVM_ADDRESS, address);
};

export const isSolanaAddress = (address: string) => {
  return matchRegex(REGEX_SOL_ADDRESS, address);
};

export const isValidAddress = (address: string) => {
  return isEVMAddress(address) || isSolanaAddress(address);
};

export const validateWalletAddress = (network: Network, address: string) => {
  if (!SUPPORTED_NETWORKS.includes(network)) throw AppError.UNSUPPORTED_NETWORK;
  let valid = false;
  if (network == Network.SOLANA) {
    if (matchRegex(REGEX_SOL_ADDRESS, address)) valid = true;
  } else if (EVM_NETWORKS.includes(network)) {
    if (matchRegex(REGEX_EVM_ADDRESS, address)) valid = true;
  }
  if (!valid) throw WalletError.INVALID_WALLET;
  return true;
};
