import { ComponentStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const Input: ComponentStyleConfig = {
  baseStyle: (props) => ({
    field: {
      bg: "rgba(252, 252, 253, 0.025)",
      border: "1px solid",
      borderRadius: "8px",
      borderColor: mode("neutral.100", "#E6E8EC")(props),
      _disabled: {
        bg: "#2C2C2C",
        cursor: "not-allowed",
      },
      _invalid: {
        borderColor: "error",
      },
      _focus: {
        bg: "rgba(252, 252, 253, 0.05)",
        borderColor: "none",
      },
      minH: "fit-content",
      height: "auto",
      p: "3",
      lineHeight: "1.2em",
    },
  }),
  variants: {},
  defaultProps: {},
};

export default Input;
