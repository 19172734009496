import { ComponentStyleConfig } from "@chakra-ui/react";

export const Modal: ComponentStyleConfig = {
  baseStyle: {
    overlay: {
      bg: "rgba(0, 0, 0, 0.3)",
      backdropFilter: "blur(10px)",
    },
    dialog: {
      boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.05)",
      backdropFilter: "blur(50px)",
      borderRadius: "16px",
      minWidth: "fit-content",
      width: "fit-content",
      maxWidth: "100vw",
      minHeight: "12rem",
      maxHeight: "90vh",
      p: 0,
      overflowY: "scroll",
      _dark: {
        bg: "rgba(0, 0, 0, 0.1)",
      },
    },
    header: {
      fontWeight: 500,
      px: 8,
    },
    body: {
      px: 8,
      py: 4,
      display: "flex",
    },
  },
  variants: {
    transparent: {
      dialog: {
        bg: "transparent",
        boxShadow: "none",
        backdropFilter: "none",
        _dark: {
          bg: "transparent",
        },
      },
    },
  },
  sizes: {
    md: {
      dialog: {
        minWidth: "20rem",
      },
    },
    lg: {
      dialog: {
        minWidth: "30rem",
      },
    },
    ghost: {
      overlay: {
        bg: "rgba(0, 0, 0, 0.5)",
      },
      dialog: {
        maxWidth: "100vw",
        minWidth: "auto",
        p: 0,
        m: 0,
        bgColor: "transparent",
        shadow: "none",
        height: "100%",
        width: "100%",
      },
    },
    full: {
      dialog: {
        borderRadius: "0",
        p: 0,
        mt: 0,
        maxWidth: "100vw",
        minWidth: "100vw",
        maxHeight: "100vh",
      },
      header: {
        fontSize: "2em",
        fontWeight: 500,
        px: 8,
        pt: 16,
        pb: 6,
      },
      body: {
        flexGrow: 1,
      },
      footer: {
        mt: 8,
      },
    },
  },
  defaultProps: {
    size: "md",
  },
};

export default Modal;
