import { APIClient } from "@lib/api";
import { sleep, to } from "@lib/utils";
import { AuthParam } from "@models/auth";
import { AuthError } from "@models/error";
import { Network } from "@models/network";
import { User, UserParam } from "@models/user";
import { WalletProvider } from "@models/wallet";
import * as Sentry from "@sentry/browser";
import {
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  signInWithRedirect,
  signOut,
} from "firebase/auth";
import { SiweMessage } from "siwe";
import { create } from "zustand";
import { firebaseAuth } from "./firebase";

const API_HOST = process.env.NEXT_PUBLIC_API_HOST || "";
if (!API_HOST) throw "Missing NEXT_PUBLIC_API_HOST";

const API_KEY = process.env.NEXT_PUBLIC_API_KEY || "";
if (!API_KEY) throw "Missing NEXT_PUBLIC_API_KEY";

export const apiClient = new APIClient(API_HOST, API_KEY);

export const STORAGE_AUTH_EMAIL_KEY = "moongate-auth-email";
export const STORAGE_AUTH_ID_KEY = "moongate-auth-id";

const GOOGLE_AUTH_PROVIDER = new GoogleAuthProvider();

export enum OAuthProvider {
  GOOGLE = "google",
}

interface UserStore {
  user: User | null;
  initialized: boolean;
  authenticating: boolean;
  fetching: boolean;
  email: string;
  authId: string;
  idToken: string; // firebase idToken
  authToken: string;
  sentEmailLink: boolean;
  firebaseAuthenticating: boolean;
  creatingWallet: boolean;
  supportedBrowser: boolean;
  enableParticle: boolean;
  enableWalletLogin: boolean;
}

export const useUserStore = create<UserStore>((set) => ({
  user: null,
  initialized: false,
  authenticating: false,
  fetching: false,
  email: "", // firebase email
  authId: "", // firebase authId
  idToken: "", // firebase idToken
  authToken: "",
  sentEmailLink: false,
  firebaseAuthenticating: false,
  creatingWallet: false,
  supportedBrowser: true,
  enableParticle: true,
  enableWalletLogin: false,
}));

export const sendSignInEmail = async (email: string) => {
  Sentry.addBreadcrumb({
    category: "sendSignInEmail",
    level: "info",
  });
  Sentry.setUser({
    email,
  });
  const actionCodeSettings = {
    url: `${window.location.origin}${window.location.pathname}${
      window.location.search || "?"
    }&email=${encodeURIComponent(email)}`,
    handleCodeInApp: true,
  };
  console.log("sendSignInEmail", actionCodeSettings);
  const { err } = await to(
    sendSignInLinkToEmail(firebaseAuth, email, actionCodeSettings)
  );
  if (err) {
    console.error(err.code, err.message);
    throw err.code;
  }
  useUserStore.setState({ sentEmailLink: true });
  window.localStorage.setItem(STORAGE_AUTH_EMAIL_KEY, email);
  return true;
};

export const signInWithGoogle = () => {
  Sentry.addBreadcrumb({
    category: "signInWithGoogle",
    level: "info",
  });
  return signInWithRedirect(firebaseAuth, GOOGLE_AUTH_PROVIDER);
};

const setAuthStates = (user: User | null, authToken: string) => {
  useUserStore.setState({
    user,
    authToken,
    fetching: false,
    authenticating: false,
    creatingWallet: false,
  });
  if (user) {
    window.localStorage.setItem(STORAGE_AUTH_ID_KEY, JSON.stringify(user.id));
  }
  // if (authToken) {
  //   window.localStorage.setItem(
  //     STORAGE_AUTH_TOKEN_KEY,
  //     JSON.stringify(authToken)
  //   );
  // }
  // apiClient.session = user?.session || "";
  // apiClient.authId = user?.id || "";
  // apiClient.authToken = authToken;
};

export const signIn = async (
  authId: string,
  authIdToken: string,
  email: string
) => {
  if (useUserStore.getState().fetching || useUserStore.getState().sentEmailLink)
    return null;
  Sentry.addBreadcrumb({
    category: "signIn",
    level: "info",
  });
  Sentry.setUser({
    authId,
    email,
  });
  useUserStore.setState({ fetching: true });
  console.log("signIn", authId, email);

  let user: User | null = null;
  let authToken = "";
  try {
    ({ user, authToken } = await fetchUserData({
      authId,
      authIdToken,
      email,
    }));
    if (!user) throw AuthError.RETRIEVE_USER_DATA_FAILED;
  } catch (error) {
    console.error("signIn", error);
    user = null;
  }
  setAuthStates(user, authToken);
  return user;
};

export const signInWithNewWallet = async (wallet: string) => {
  const authId = useUserStore.getState().authId;
  const authIdToken = useUserStore.getState().idToken;
  const email = useUserStore.getState().email;
  if (
    useUserStore.getState().fetching ||
    useUserStore.getState().sentEmailLink ||
    !wallet ||
    !authId ||
    !authIdToken ||
    !email
  )
    return null;
  Sentry.addBreadcrumb({
    category: "signInWithNewWallet",
    level: "info",
  });
  Sentry.setUser({
    authId,
    email,
    wallet,
  });
  useUserStore.setState({ fetching: true });
  console.log("signInWithNewWallet", authId, email, wallet);

  let user: User | null = null;
  let authToken = "";
  try {
    ({ user, authToken } = await fetchUserData({
      authId,
      authIdToken,
      email,
      address: wallet,
      network: Network.EVM,
      provider: WalletProvider.PARTICLE_NETWORK,
      source: window.location.origin,
    }));
    if (!user) throw AuthError.RETRIEVE_USER_DATA_FAILED;
  } catch (error) {
    console.error("signInWithNewWallet", error);
    user = null;
  }
  setAuthStates(user, authToken);
  return user;
};

export const checkExistingUser = async (
  authId: string,
  authIdToken: string,
  email: string
) => {
  Sentry.addBreadcrumb({
    category: "checkExistingUser",
    level: "info",
  });
  const data = await apiClient.get(
    "/users/check",
    {
      email,
      authId,
      authIdToken,
    },
    undefined,
    10
  );
  if (!data?.id || !data?.identifiers) return null;
  const user = new User(data as UserParam);
  return user;
};

const fetchUserData = async (
  params: AuthParam
): Promise<{ user: User | null; authToken: string }> => {
  Sentry.addBreadcrumb({
    category: "fetchUserData",
    level: "info",
  });
  const data = await apiClient.post("/users/auth", params, undefined, 10);
  if (!data || !data.user || !data.authToken)
    return { user: null, authToken: "" };

  const user = new User(data.user as UserParam);
  console.log("fetchUserData", user);
  const authToken = String(data.authToken);
  Sentry.setUser({
    id: user.id,
    authId: params.authId,
    email: params.email,
    address: params.address,
    network: params.network,
  });
  return { user, authToken };
};

export const signInByAuthToken = async (token: string, authId = "") => {
  Sentry.addBreadcrumb({
    category: "signInByAuthToken",
    level: "info",
  });
  if (!token && !authId) return null;
  useUserStore.setState({ authId });
  try {
    useUserStore.setState({
      authenticating: true,
    });
    console.log("signInByAuthToken", authId, token);
    const data = await apiClient.get(
      `/users/auth`,
      {
        token,
        id: authId,
      },
      undefined,
      10
    );
    if (!data || !data.user || !data.authToken)
      throw AuthError.INVALID_AUTH_TOKEN;
    const { user: UserParam, authToken } = data;
    const user = new User(UserParam as UserParam);
    Sentry.setUser({
      id: user.id,
      email: user.email,
    });
    console.log("signInByAuthToken", authToken, user);
    setAuthStates(user, authToken);
    return user;
  } catch (error) {
    console.error("signInByAuthToken", error);
    return null;
  }
};

export const signInByWallet = async (
  address: string,
  siweMessage: SiweMessage,
  signature: string
) => {
  Sentry.addBreadcrumb({
    category: "signInByWallet",
    level: "info",
  });
  Sentry.setTag("wallet", address);
  console.log("signInByWallet", siweMessage);
  const { user, authToken } = await fetchUserData({
    address,
    siweMessage,
    signature,
  });
  Sentry.setUser({
    id: user?.id,
    email: user?.email,
    wallet: address,
  });
  setAuthStates(user, authToken);
  return user;
};

export const logOut = async (
  disconnectParticle?: () => Promise<void>,
  redirect = "",
  delay?: number
) => {
  window.localStorage.removeItem(STORAGE_AUTH_ID_KEY);
  to(apiClient.post(`/users/auth/logout`));
  // apiClient.session = "";
  // apiClient.authId = "";
  // apiClient.authToken = "";
  if (firebaseAuth.currentUser) await to(signOut(firebaseAuth));
  if (disconnectParticle) {
    console.log("disconnectParticle");
    await to(disconnectParticle());
  }
  if (delay && delay > 0) await to(sleep(delay));
  if (redirect) {
    window.location.href = redirect;
    return;
  }
  window.location.reload();
};
