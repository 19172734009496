import { ComponentStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const FormLabel: ComponentStyleConfig = {
  baseStyle: (props) => ({
    color: mode("", "neutral.10")(props),
    fontWeight: "450",
    my: 2,
    lineHeight: "1.2em",
  }),
  variants: {},
  defaultProps: {},
};

export default FormLabel;
