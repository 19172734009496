import Head from "next/head";
import LoadingAnimation from "./LoadingAnimation";

const LoadingPage = ({
  title,
  children,
  size: size = 48,
  message: message = "",
}: {
  title?: string;
  children?: any;
  size?: number;
  message?: string;
}) => {
  return (
    <>
      {title && (
        <Head>
          <title>{title}</title>
        </Head>
      )}
      <div className="flex flex-col content-center justify-center items-center h-screen w-screen z-50">
        <LoadingAnimation size={size} />
        {message && <p className="max-w-sm text-center py-2">{message}</p>}
        {children && <>{children}</>}
      </div>
    </>
  );
};

export default LoadingPage;
