export const initializeParticleAuthCoreConfig = (
  projectId: string,
  clientKey: string,
  appId: string
) => {
  return {
    projectId,
    clientKey,
    appId,
    //   promptSettingConfig: {
    //     //optional
    //     //set payment password prompt: none, first, every, everyAndNotSkip.
    //     promptPaymentPasswordSettingWhenSign: PromptSettingType.first,
    //     //set master password prompt: none, first, every, everyAndNotSkip.
    //     promptMasterPasswordSettingWhenLogin: PromptSettingType.first,
    //   },
    //   erc4337: {
    //     // optional
    //     name: "SIMPLE", // support 'SIMPLE', 'CYBERCONNECT', 'BICONOMY'
    //     version: "1.0.0",
    //   },
    wallet: {
      visible: false, //show wallet entrance when connect success.
    },
  };
};
