import { Icon, useColorMode } from "@chakra-ui/react";

export const MoongateLogoIcon = (props: any) => {
  const { colorMode } = useColorMode();
  const isDark = colorMode === "dark";
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.9116 14.951C31.0823 6.86495 24.4046 0.807656 16.1983 0.601501H15.8013C7.59495 0.807656 0.917209 6.86495 0.0879548 14.951C-0.748584 23.1293 4.52075 29.3539 9.99769 31.3985C9.84109 31.1681 9.73182 30.9995 9.61403 30.8383C6.57873 26.7224 8.32584 20.6906 13.0925 18.8462C13.7822 18.5794 14.4621 18.4823 14.9926 18.6994C15.5827 18.9419 16.0004 19.5119 16.0004 19.5119C16.2627 19.1613 16.6087 18.882 17.0069 18.6994C17.5375 18.4848 18.221 18.5781 18.907 18.8462C23.6737 20.6906 25.416 26.7285 22.3855 30.8383C22.2641 30.9995 22.1597 31.1681 22.0019 31.3985C27.4788 29.3539 32.7506 23.1293 31.9116 14.951ZM16.0064 18.235C14.7074 18.235 13.0804 18.5987 13.0804 18.5987L16.0295 17.2151L18.9604 18.5987C18.9604 18.5987 17.3117 18.235 16.0064 18.235Z"
        fill="currentColor"
      />
    </svg>
  );
};

export { default as BenefitsFilledIcon } from "@icons/BenefitsFilledIcon";
export { default as BenefitsOutlineIcon } from "@icons/BenefitsOutlineIcon";
export { default as CommunityFilledIcon } from "@icons/CommunityFilledIcon";
export { default as CommunityOutlineIcon } from "@icons/CommunityOutlineIcon";
export { default as ExternalLinkIcon } from "@icons/ExternalLinkIcon";
export { default as OverviewFilledIcon } from "@icons/OverviewFilledIcon";
export { default as OverviewOutlineIcon } from "@icons/OverviewOutlineIcon";
export { default as ProfileFilledIcon } from "@icons/ProfileFilledIcon";
export { default as ProfileOutlineIcon } from "@icons/ProfileOutlineIcon";
export { default as ReferralFilledIcon } from "@icons/ReferralFilledIcon";
export { default as ReferralOutlineIcon } from "@icons/ReferralOutlineIcon";
export { default as SpendingFilledIcon } from "@icons/SpendingFilledIcon";
export { default as SpendingOutlineIcon } from "@icons/SpendingOutlineIcon";
export { default as TicketFilledIcon } from "@icons/TicketFilledIcon";
export { default as TicketOutlineIcon } from "@icons/TicketOutlineIcon";

export const LogoLogin = (props: any) => {
  const { colorMode } = useColorMode();
  const isDark = colorMode === "dark";
  return (
    <svg
      width="153"
      height="25"
      viewBox="0 0 153 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_87_9001)">
        <path
          d="M151.102 13.5915L146.269 8.4752H152.475V5.9646H140.397L147.715 13.7061L140.424 21.3565C141.459 21.3565 143.752 21.3565 143.752 21.3565C143.752 21.3565 148.541 16.303 150.829 13.8855C150.912 13.811 150.984 13.7218 151.102 13.5915Z"
          fill={isDark ? "#DEDEE0" : "#000000"}
        />
        <path
          d="M51.4298 7.68969C49.8379 9.11468 48.871 10.9101 48.7394 13.1162C48.563 16.0956 49.745 18.4203 52.1201 20.1981C51.6972 20.6019 51.3193 20.9625 50.9085 21.3565H61.1693L60.0031 20.1755C64.178 17.1658 64.1374 11.7471 61.6464 8.71875C58.7564 5.2033 54.1304 5.27191 51.4298 7.68969ZM56.0854 18.7927C53.395 18.8201 51.1877 16.5386 51.1756 13.719C51.1626 10.8337 53.2824 8.57074 56.0185 8.54921C57.3045 8.53982 58.5413 9.07046 59.4567 10.0244C60.3722 10.9782 60.8916 12.2773 60.9004 13.6356C60.9092 14.994 60.407 16.3005 59.5039 17.2676C58.6009 18.2347 57.3711 18.7833 56.0854 18.7927Z"
          fill={isDark ? "#DEDEE0" : "#000000"}
        />
        <path
          d="M68.5536 8.10064C67.1296 9.6039 66.3192 11.3855 66.2895 13.5002C66.2505 16.3205 67.4282 18.5136 69.656 20.1639L68.4907 21.3565H78.7821L77.5535 20.1864C81.7453 17.2848 81.863 11.4805 78.8359 8.27804C75.8663 5.13532 71.2016 5.31072 68.5536 8.10064ZM73.6049 18.7821C70.9161 18.7821 68.7288 16.4851 68.7336 13.6638C68.7288 12.6457 69.0101 11.6489 69.5417 10.7997C70.0732 9.95059 70.8314 9.28734 71.7199 8.89396C72.6087 8.5006 73.5877 8.39481 74.5331 8.58999C75.4785 8.78517 76.3479 9.27258 77.0314 9.99042C77.7145 10.7083 78.1807 11.6243 78.3709 12.6225C78.5612 13.6206 78.4668 14.656 78.0999 15.5975C77.7332 16.539 77.11 17.3442 76.3098 17.9111C75.5096 18.478 74.5682 18.7812 73.6049 18.7821Z"
          fill={isDark ? "#DEDEE0" : "#000000"}
        />
        <path
          d="M126.31 8.67149H131.15V21.3565H133.582V8.67732H138.385V6.15698H126.308L126.31 8.67149Z"
          fill={isDark ? "#DEDEE0" : "#000000"}
        />
        <path
          d="M149.567 18.8715L147.168 21.3564H152.475V18.8552L149.567 18.8715Z"
          fill={isDark ? "#DEDEE0" : "#000000"}
        />
        <path
          d="M43.4068 12.3333L38.5443 17.3873C38.4275 17.275 38.344 17.1937 38.2659 17.1173C35.9719 14.7227 33.6769 12.3288 31.3804 9.93556C31.3048 9.86275 31.2455 9.77336 31.2068 9.67383C31.168 9.57427 31.1511 9.46704 31.1569 9.35981C31.168 8.33123 31.1614 7.30167 31.1614 6.15698L38.4989 13.7683L45.7943 6.18504V21.3565H43.4068V12.3333Z"
          fill={isDark ? "#DEDEE0" : "#000000"}
        />
        <path
          d="M112.401 17.9422C110.594 20.744 107.381 21.9633 104.268 21.0658C101.495 20.2677 99.2862 17.378 99.069 14.4303C98.9374 12.8121 99.3031 11.1923 100.113 9.80623C100.922 8.42018 102.134 7.34023 103.571 6.72339C106.475 5.48576 109.623 6.38526 111.427 8.36582L109.789 10.1252C109.291 9.7194 108.878 9.42087 108.372 9.18125C105.191 7.67696 101.937 9.92711 101.533 13.0014C101.163 15.8563 102.913 18.3548 105.65 18.7605C107.203 18.9914 108.586 18.5296 109.716 17.3732C109.923 17.1625 110.086 17.1287 110.346 17.2263C111.022 17.4814 111.702 17.7016 112.401 17.9422Z"
          fill={isDark ? "#DEDEE0" : "#000000"}
        />
        <path
          d="M84.0404 6.15698C84.0404 6.15698 85.3785 7.3991 86.0062 7.99361C89.298 11.1036 92.591 14.2123 95.8852 17.3198C95.9597 17.3842 96.019 17.4657 96.0589 17.558C96.0985 17.6504 96.1179 17.751 96.1149 17.8524C96.1031 18.9911 96.1094 20.1298 96.1094 21.3565L86.4509 12.2565V21.3429H84.3051C84.2694 21.3443 84.234 21.3379 84.201 21.3242C84.168 21.3105 84.1381 21.2897 84.113 21.2632C84.0879 21.2366 84.0685 21.205 84.0555 21.1701C84.0428 21.1353 84.0371 21.098 84.0386 21.0607C84.0386 20.9186 84.0404 6.15698 84.0404 6.15698Z"
          fill={isDark ? "#DEDEE0" : "#000000"}
        />
        <path
          d="M93.7395 6.15698H96.1153V13.6605C96.1153 13.6605 94.5685 12.2201 93.8978 11.5758C93.8097 11.4923 93.7535 11.3774 93.7405 11.2531C93.7323 9.58706 93.7395 6.15698 93.7395 6.15698Z"
          fill={isDark ? "#DEDEE0" : "#000000"}
        />
        <path
          d="M106.362 15.007V12.5061H112.401C112.353 12.5771 112.301 12.6453 112.246 12.7105C111.557 13.4208 110.865 14.1311 110.171 14.8414C110.104 14.9223 110.013 14.9772 109.912 14.9973C108.74 15.0099 107.567 15.007 106.362 15.007Z"
          fill={isDark ? "#DEDEE0" : "#000000"}
        />
        <path
          d="M120.637 5.9646C119.172 9.03809 113.316 21.3546 113.316 21.3546H115.75C115.84 21.3633 115.931 21.3413 116.009 21.2918C116.087 21.2423 116.148 21.1679 116.183 21.0794C116.264 20.906 120.635 11.7345 120.635 11.7345C120.711 11.8932 125.004 20.906 125.088 21.0794C125.123 21.1679 125.184 21.2424 125.262 21.2919C125.339 21.3414 125.43 21.3634 125.521 21.3546H127.955L120.637 5.9646Z"
          fill={isDark ? "#DEDEE0" : "#000000"}
        />
        <path
          d="M24.684 11.4749C24.0533 5.00885 18.9747 0.165097 12.7336 0.000244141H12.4316C6.19049 0.165097 1.11187 5.00885 0.481199 11.4749C-0.155014 18.0147 3.85247 22.9923 8.01785 24.6273C7.89874 24.443 7.81564 24.3082 7.72606 24.1793C5.41762 20.888 6.74636 16.0646 10.3716 14.5897C10.8961 14.3764 11.4131 14.2988 11.8167 14.4724C12.2654 14.6663 12.5831 15.1221 12.5831 15.1221C12.7826 14.8417 13.0457 14.6184 13.3486 14.4724C13.7521 14.3007 14.2719 14.3754 14.7936 14.5897C18.4189 16.0646 19.7439 20.8929 17.4392 24.1793C17.3468 24.3082 17.2674 24.443 17.1474 24.6273C21.3127 22.9923 25.3221 18.0147 24.684 11.4749ZM12.5877 14.101C11.5997 14.101 10.3623 14.3918 10.3623 14.3918L12.6052 13.2854L14.8343 14.3918C14.8343 14.3918 13.5803 14.101 12.5877 14.101Z"
          fill="#45BFB0"
        />
      </g>
      <defs>
        <clipPath id="clip0_87_9001">
          <rect
            width="152.171"
            height="24.8111"
            fill={isDark ? "#DEDEE0" : "#000000"}
            transform="translate(0.414307 0.000244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const VerifyEmailIcon = (props: any) => {
  const { colorMode } = useColorMode();
  const isDark = colorMode === "dark";
  return (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.8332 4C26.8332 6.20914 25.0423 8 22.8332 8C20.624 8 18.8332 6.20914 18.8332 4C18.8332 1.79086 20.624 0 22.8332 0C25.0423 0 26.8332 1.79086 26.8332 4Z"
        fill="#00A28D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1665 2.66667H10.8332C5.80486 2.66667 3.2907 2.66667 1.7286 4.22876C0.166504 5.79086 0.166504 8.30502 0.166504 13.3333C0.166504 18.3616 0.166504 20.8758 1.7286 22.4379C3.2907 24 5.80486 24 10.8332 24H16.1665C21.1948 24 23.709 24 25.2711 22.4379C26.8332 20.8758 26.8332 18.3616 26.8332 13.3333C26.8332 11.3955 26.8332 9.83109 26.7438 8.55066C25.6935 9.454 24.3271 10 22.8332 10C22.1949 10 21.58 9.90035 21.003 9.71575L19.2114 11.2088C18.0296 12.1936 17.0717 12.9919 16.2263 13.5356C15.3456 14.102 14.4879 14.4598 13.4998 14.4598C12.5117 14.4598 11.6541 14.102 10.7734 13.5356C9.92799 12.9919 8.97012 12.1936 7.78831 11.2088L4.85965 8.76822C4.43538 8.41466 4.37805 7.78409 4.73162 7.35982C5.08518 6.93554 5.71574 6.87821 6.14002 7.23178L9.01855 9.63055C10.2625 10.6672 11.1261 11.3846 11.8553 11.8535C12.5611 12.3075 13.0397 12.4598 13.4998 12.4598C13.9599 12.4598 14.4386 12.3075 15.1444 11.8535C15.8735 11.3846 16.7372 10.6672 17.9811 9.63055L19.1004 8.69781C17.7188 7.59858 16.8332 5.90272 16.8332 4C16.8332 3.54184 16.8845 3.09567 16.9818 2.66693C16.7176 2.66667 16.4459 2.66667 16.1665 2.66667Z"
        fill={isDark ? "#DEDEE0" : "#000000"}
      />
    </svg>
  );
};

export const MenuIcon = (props: any) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5C0 4.44772 0.373096 4 0.833333 4H19.1667C19.6269 4 20 4.44772 20 5C20 5.55228 19.6269 6 19.1667 6H0.833333C0.373096 6 0 5.55228 0 5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 15C0 14.4477 0.373096 14 0.833333 14H19.1667C19.6269 14 20 14.4477 20 15C20 15.5523 19.6269 16 19.1667 16H0.833333C0.373096 16 0 15.5523 0 15Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const CloseIcon = (props: any) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      className={props?.className}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1879 4.22499C17.5133 3.89955 17.4606 3.31915 17.07 2.92863C16.6795 2.5381 16.0991 2.48534 15.7737 2.81078L9.99964 8.5848L4.22577 2.81093C3.90033 2.48549 3.31993 2.53825 2.9294 2.92878C2.53888 3.3193 2.48612 3.8997 2.81155 4.22514L8.58543 9.99901L2.81004 15.7744C2.48461 16.0998 2.53737 16.6802 2.92789 17.0708C3.31842 17.4613 3.89882 17.514 4.22426 17.1886L9.99964 11.4132L15.7752 17.1888C16.1006 17.5142 16.681 17.4614 17.0715 17.0709C17.4621 16.6804 17.5148 16.1 17.1894 15.7746L11.4139 9.99901L17.1879 4.22499Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const DarkModeIcon = (props: any) => {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.08595 1.44332C4.76346 1.89973 1.39535 5.55654 1.39535 10C1.39535 14.7522 5.24778 18.6047 10 18.6047C14.4435 18.6047 18.1003 15.2366 18.5567 10.9141C17.3225 12.4808 15.4071 13.4884 13.2558 13.4884C9.5311 13.4884 6.51163 10.4689 6.51163 6.74419C6.51163 4.59291 7.51921 2.67749 9.08595 1.44332ZM0 10C0 4.47715 4.47715 0 10 0C10.6666 0 11.0004 0.531403 11.0575 0.954666C11.1125 1.36184 10.962 1.87968 10.494 2.16248C8.94201 3.10028 7.90698 4.8017 7.90698 6.74419C7.90698 9.69827 10.3017 12.093 13.2558 12.093C15.1983 12.093 16.8997 11.058 17.8375 9.50601C18.1203 9.03799 18.6382 8.88748 19.0453 8.94247C19.4686 8.99963 20 9.33337 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const LightModeIcon = (props: any) => {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx="12" cy="12" r="5" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M12 2V4"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 20V22"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4 12L2 12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M22 12L20 12"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19.7773 4.22217L17.5553 6.25375"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.22266 4.22217L6.44467 6.25375"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.44434 17.5557L4.22211 19.7779"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19.7773 19.7778L17.5553 17.5555"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </Icon>
  );
};

export const LogoutIcon = (props: any) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} fill={"none"}>
      <path
        d="M8.00195 7C8.01406 4.82497 8.11051 3.64706 8.87889 2.87868C9.75757 2 11.1718 2 14.0002 2L15.0002 2C17.8286 2 19.2429 2 20.1215 2.87868C21.0002 3.75736 21.0002 5.17157 21.0002 8L21.0002 16C21.0002 18.8284 21.0002 20.2426 20.1215 21.1213C19.2429 22 17.8286 22 15.0002 22H14.0002C11.1718 22 9.75757 22 8.87889 21.1213C8.11051 20.3529 8.01406 19.175 8.00195 17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14 12L1 12M1 12L4.5 9M1 12L4.5 15"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
