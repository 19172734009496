import { WalletIcon, WarnIcon } from "@assets";
import { walletAddressPreview } from "@models/utils";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { SiweMessage, generateNonce } from "siwe";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";
import Dialog, { DialogProps } from "../../components/Dialog/Dialog";
import { useUserStore } from "./auth";
import { signingMessage } from "./auth/services/rainbowkit";

const ConnectWalletButton = ({
  children,
  disabled,
  onSuccess: onSuccess = () => {},
}: {
  disabled?: boolean;
  children?: ReactNode;
  onSuccess?: (message: SiweMessage, signature: string) => void;
}) => {
  const enableWalletLogin = useUserStore((state) => state.enableWalletLogin);
  if (!enableWalletLogin) return <></>;

  const { address, isConnected, isConnecting } = useAccount();
  const { disconnect } = useDisconnect();
  const [dialog, setDialog] = useState<DialogProps | null>(null);
  const [message, setMessage] = useState<SiweMessage | null>(null);
  const [nonce, setNonce] = useState("");
  const [showConfirmAddress, setShowConfirmAddress] = useState(false);
  const {
    data: signature,
    isSuccess: isSigned,
    error: signError,
    signMessage,
  } = useSignMessage();

  const addressPreview = useMemo(() => {
    if (!address) return "";
    return walletAddressPreview(address);
  }, [address]);

  useEffect(() => {
    disconnect();
  }, []);

  useEffect(() => {
    if (!isConnected) return;
    setShowConfirmAddress(true);
  }, [isConnected, isSigned]);

  useEffect(() => {
    if (!signError) return;
    console.log(signError);
    setDialog(errorDialog);
    setTimeout(() => cancel(), 5000);
  }, [signError]);

  useEffect(() => {
    if (!signature || !nonce || !message) return;
    const verify = async () => {
      try {
        await message.verify({
          signature,
          nonce,
        });
        // setDialog(successDialog);
        // console.log(message, signature);
        onSuccess(message, signature);
        cancel();
        return;
      } catch (error) {
        console.error(error);
        setDialog(errorDialog);
        return;
      }
    };
    verify();
  }, [signature, message, nonce]);

  const reset = () => {
    setShowConfirmAddress(false);
    setDialog(null);
    setMessage(null);
    setNonce("");
  };

  const cancel = () => {
    reset();
    disconnect();
  };

  const handleSignMessage = async (address?: string) => {
    if (!address) {
      setDialog(errorDialog);
      return;
    }
    setShowConfirmAddress(false);
    const nonce = generateNonce();
    setNonce(nonce);
    const message = await signingMessage(address, nonce);
    setMessage(message);
    setDialog(signatureDialog);
    signMessage({ message: message.prepareMessage() });
    return message;
  };

  const closeAction = {
    text: "close",
    primary: true,
    onClick: cancel,
  };

  const cancelAction = {
    text: "cancel",
    onClick: cancel,
  };

  const changeAccountDialog: DialogProps = {
    onClose: cancel,
    icon: WarnIcon,
    title: "Change Account",
    actions: [closeAction],
    children: "Please change your active wallet in your wallet application.",
  };

  const signatureDialog: DialogProps = {
    icon: WalletIcon,
    title: "Verify Wallet",
    isClose: false,
    children:
      "Please continue in your wallet application to verify your wallet ownership by signing the message.",
  };

  const errorDialog: DialogProps = {
    onClose: cancel,
    title: "Error",
    actions: [closeAction],
    children:
      "An error occurred while connecting your wallet. Please try again.",
  };

  return (
    <>
      <ConnectButton.Custom>
        {({ openConnectModal }) => {
          return (
            <>
              <button
                className="min-w-fit"
                onClick={openConnectModal}
                disabled={isConnecting || disabled}
              >
                {children}
              </button>

              {showConfirmAddress && (
                <Dialog
                  isOpen={isConnected && !!address && !dialog}
                  onClose={cancel}
                  icon={WalletIcon}
                  title="Confirm Wallet"
                  actions={[
                    {
                      text: "Change Account",
                      onClick: () => {
                        setDialog(changeAccountDialog);
                      },
                    },
                    {
                      text: "Confirm",
                      primary: true,
                      onClick: () => {
                        handleSignMessage(address);
                      },
                    },
                  ]}
                >
                  <div className="flex flex-col justify-center items-center">
                    <p>You are connecting the following address:</p>
                    <p className="bg-neutral-850 px-4 py-2 rounded-full w-3/4 text-center mt-4 text-white">
                      {addressPreview}
                    </p>
                  </div>
                </Dialog>
              )}

              {dialog && (
                <Dialog
                  isOpen={!!dialog}
                  isClose={dialog.isClose}
                  onClose={dialog.onClose}
                  title={dialog.title}
                  actions={dialog.actions}
                  loading={dialog.loading}
                  icon={dialog.icon}
                  onOpen={dialog.onOpen}
                >
                  <p className="w-full text-center">{dialog.children}</p>
                </Dialog>
              )}
            </>
          );
        }}
      </ConnectButton.Custom>
    </>
  );
};

export default ConnectWalletButton;
