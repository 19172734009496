import { ComponentStyleConfig } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const solid = (props) => ({
  bgColor: "#9C7B64",
  color: "#252F3A",
  border: "1px solid #9C7B64",
  _hover: {
    bgColor: "#9C7B64",
    border: "1px solid #9C7B64",
    _disabled: {
      bgColor: "neutral.200",
      borderColor: "neutral.200",
    },
  },
  _focus: {
    bgColor: "#9C7B64",
    border: "1px solid #9C7B64",
  },
  _disabled: {
    bgColor: "neutral.200",
    borderColor: "neutral.200",
    color: mode("white", "neutral.600")(props),
    cursor: "not-allowed",
    opacity: 1,
  },
});

export const Button: ComponentStyleConfig = {
  baseStyle: (props) => ({
    fontWeight: "medium",
    fontSize: "12px",
    borderRadius: "8px",
    color: "#252F3A",
    border: "none",
    px: "10px",
    py: "10px",
    paddingInline: "10px",
    height: "auto",
    textTransform: "none",
    _focus: {
      boxShadow: "none",
    },
    _focusVisible: {
      boxShadow: "none",
    },
  }),
  variants: {
    outline: {
      bgColor: "rgba(255, 255, 255, 0.05)",
      color: "#777E90",
      border: "1px solid #DEDEE0",
      filter: "drop-shadow(0px 0px 50px rgba(0, 0, 0, 0.05))",
      _hover: {
        bgColor: "rgb(224, 244, 242, 0.1)",
        _disabled: {
          bgColor: "transparent",
          borderColor: "transparent",
        },
      },
      _focus: {
        bgColor: "rgb(224, 244, 242, 0.1)",
      },
      _disabled: {
        bgColor: "transparent",
        borderColor: "transparent",
        cursor: "not-allowed",
      },
      _dark: {
        color: "#DEDEE0",
        bgColor: "rgba(255, 255, 255, 0.05)",
        boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.05)",
      },
    },
    solid: (props) => ({
      ...solid(props),
    }),
    ghost: {
      height: "auto",
      bgColor: "transparent",
      color: "primary.300",
      border: "none",
      p: 1,
      _hover: {
        color: "primary.700",
        bgColor: "transparent",
        _disabled: {
          bgColor: "transparent",
          borderColor: "transparent",
        },
      },
      _focus: {
        color: "primary.700",
        bgColor: "transparent",
      },
      _active: {
        color: "primary.700",
        bgColor: "transparent",
      },
      _disabled: {
        color: "neutral.600",
        bgColor: "transparent",
        borderColor: "transparent",
        cursor: "not-allowed",
      },
    },
    dark: {
      bgColor: "rgba(255, 255, 255, 0.05)",
      color: "white",
      _hover: {
        bgColor: "rgb(224, 244, 242, 0.1)",
        _disabled: {
          bgColor: "neutral.200",
          borderColor: "neutral.200",
        },
      },
      _focus: {
        bgColor: "rgb(224, 244, 242, 0.1)",
      },
      _disabled: {
        bgColor: "neutral.200",
        borderColor: "neutral.200",
        color: "neutral.600",
        cursor: "not-allowed",
        opacity: 1,
      },
    },
    error: {
      bgColor: "transparent",
      border: "1px solid",
      borderColor: "error",
      color: "error",
      _hover: {
        bgColor: "rgb(224, 244, 242, 0.1)",
        _disabled: {
          bgColor: "transparent",
          borderColor: "transparent",
        },
      },
      _focus: {
        bgColor: "rgb(224, 244, 242, 0.1)",
      },
      _disabled: {
        bgColor: "transparent",
        borderColor: "transparent",
        cursor: "not-allowed",
      },
    },
    gradient: (props) => ({
      bgGradient:
        // "linear(to-r, rgba(0, 249, 216, 0.8), rgba(128, 137, 216, 0.64), rgba(133, 43, 250, 0.64))",
        "linear(180deg, rgba(250, 250, 251, 0.16) 0%, rgba(69, 191, 176, 0.159375) 0.01%, rgba(69, 191, 176, 0.04) 100%)",
      borderColor: "primary.500",
      border: "1px solid",
      color: mode("primary.500", "white")(props),
      _hover: {
        /* bgColor: "primary.500", */
        bgColor: "rgb(224, 244, 242, 0.1)",
        _disabled: {
          bgColor: "neutral.200",
          borderColor: "neutral.200",
        },
      },
      _focus: {
        bgColor: "rgb(224, 244, 242, 0.1)",
      },
      _disabled: {
        bgColor: "neutral.200",
        borderColor: "neutral.200",
        color: "white",
        cursor: "not-allowed",
        opacity: 0.6,
      },
    }),
    symbol: (props) => ({
      ...solid(props),
      fontSize: "1.5rem",
      color: "#252F3A",
      borderRadius: "md",
      _disabled: {
        cursor: "not-allowed",
      },
    }),
  },
  sizes: {
    sm: {
      h: "auto",
    },
    md: {
      h: "auto",
    },
    symbol: {
      width: "1.75rem",
      height: "1.75rem",
    },
  },
  defaultProps: {
    variant: "solid",
  },
};

export default Button;
