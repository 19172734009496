export * from "./wallet";

export const REGEX_ALPHANUMERIC = /[a-z\d\s]/gi;
export const REGEX_NON_ALPHANUMERIC = /[^a-z\d\s]/gi;
export const REGEX_EMAIL =
  /^([\w_\.\-\+!#$%&'*\/=?^`{|}~])+\@(([\w\-])+\.)+(\w{2,})+$/gim;
export const REGEX_TEMPLATE_VARIABLE = /^\{\{\w+\}\}$/m;
export const REGEX_SLUG = /^[\w\-]+$/im;

export const REGEX_URL_STRING = "^http[s]?://[^\\s]+\\.[^\\s]+$";
export const REGEX_URL = new RegExp(REGEX_URL_STRING, "im");

export const REGEX_SPECIAL_CHARACTERS = [
  ".",
  "+",
  "*",
  "?",
  "^",
  "$",
  "(",
  ")",
  "[",
  "]",
  "{",
  "}",
  "|",
  "-",
];

export const escapeRegexSpecialChars = (str: string) => {
  str = str.replace(/\\/g, "\\").trim();
  for (const char of REGEX_SPECIAL_CHARACTERS) {
    str = str.replace(new RegExp("\\" + char, "g"), "\\" + char);
  }
  return str;
};

export const matchRegex = (regex: RegExp, str: string) => {
  return str.match(regex) !== null;
};
