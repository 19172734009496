import { VerifyEmailIcon } from "@assets";
import { useColorMode } from "@chakra-ui/react";
import * as Sentry from "@sentry/browser";
import { NextImage } from "../../components/NextImage/NextImage";

const VerifyEmail = ({
  email,
  resend: resend = () => {},
}: {
  email: string;
  resend: () => void;
}) => {
  const { colorMode } = useColorMode();

  Sentry.addBreadcrumb({
    category: "sentEmailLink",
    level: "info",
  });
  Sentry.setTag("email", email);
  return (
    <div className="flex flex-col justify-center items-center text-center">
      <NextImage
        className="w-12 svg-primary"
        src={VerifyEmailIcon}
        alt="Check your email"
      />
      <h1 className="capitalize text-2xl m-8 font-bold">Check your email</h1>
      <p className="m-1 text-lg">
        We sent an <span className="text-primary font-bold"> one-time </span>{" "}
        sign in link to <em className="font-bold text-primary">{email}</em>.
      </p>
      <p className="m-1 text-lg">Please click the link to continue.</p>

      <h2 className={"mt-8 " + (colorMode === "dark" ? "text-secondary" : "")}>
        Did not receive the email?
      </h2>
      <p className={colorMode === "dark" ? "text-secondary" : ""}>
        Also check your junk / spam inbox. Or
        <a
          className="text-primary underline mx-1 font-semibold"
          onClick={resend}
        >
          resend link
        </a>
        .
      </p>
    </div>
  );
};

export default VerifyEmail;
