export * from "./center";
export * from "./magiceden";
export * from "./moralis";
export * from "./opensea";
export * from "./solscan";

export enum NFTApiProvider {
  OPENSEA = "opensea",
  MORALIS = "moralis",
  MAGICEDEN = "magiceden",
  SIMPLEHASH = "simplehash",
  SOLSCAN = "solscan",
  CENTER = "center",
}
