import { isEmpty, omit, pick } from "lodash";
import { EVM_NETWORKS, Network } from "../network";
import { normalizeAddress } from "../nft";
import { cryptoId, normalizeId, unique } from "../utils";
import { Wallet, WalletParam, validateWalletAddress } from "../wallet";
import { UserBase } from "./base";

export interface UserParam extends Omit<Partial<User>, "wallets"> {
  wallets?: WalletParam[];
}

const privateProperties = ["qrCode"];
export class User extends UserBase implements UserParam {
  authId: string;
  identifiers: string[];
  wallets: Wallet[];
  walletAddresses: string[];
  networks: Network[];
  moonpassIds: string[];
  session: string;
  lastActive: number;
  source?: string;
  events?: string[];
  communities: string[];
  qrCode?: string;

  constructor(params?: UserParam) {
    super(params);
    this.authId = params?.authId || "";
    this.wallets = (
      params?.wallets && params?.wallets.length > 0
        ? params.wallets
            .filter((w) => w.network && w.address)
            .map((w) => new Wallet(w))
        : []
    ).sort((a, b) => (a.provider ? 1 : -1) || a.connectedAt - b.connectedAt);
    this.walletAddresses = unique(this.wallets.map((wallet) => wallet.address));
    this.identifiers = unique(
      [...(params?.identifiers || []), this.email, ...this.walletAddresses].map(
        (i) => normalizeId(i)
      )
    );
    this.networks = unique(this.wallets.map((wallet) => wallet.network));
    this.moonpassIds = params?.moonpassIds || [];
    this.communities = params?.communities || [];
    this.session = params?.session || "";
    this.lastActive = Date.now();
    if (params?.username) this.username = params.username;
    if (params?.source) this.source = params.source;
    if (params?.events && !isEmpty(params.events)) this.events = params.events;
    if (params?.qrCode) this.qrCode = params.qrCode;
    this.updateWalletAddresses();
  }

  get publicData() {
    return omit(this.json, privateProperties) as UserParam;
  }

  get privateData(): Partial<UserParam> {
    return pick(this.json, ["id", ...privateProperties]);
  }

  get evmWallets(): string[] {
    return unique(
      this.wallets
        .filter((wallet) => EVM_NETWORKS.includes(wallet.network))
        .map((wallet) => wallet.address)
    );
  }

  get basicInfo(): Partial<UserParam> {
    return pick(this.json, ["id", "email", "wallets", "username"]);
  }

  get defaultWallet(): Wallet | null {
    if (!this.wallets || isEmpty(this.wallets)) return null;
    return this.wallets[0];
  }

  updateNetworks() {
    this.networks = unique(this.wallets.map((wallet) => wallet.network));
  }

  updateWalletAddresses() {
    this.walletAddresses = unique(this.wallets.map((wallet) => wallet.address));
    this.identifiers = unique(
      [...this.identifiers, ...this.walletAddresses].map((i) => normalizeId(i))
    );
    this.updateNetworks();
  }

  update() {
    this.updateWalletAddresses();
    this.lastActive = Date.now();
    this.updatedAt = Date.now();
  }

  addWallet(wallet: Wallet) {
    if (this.isWalletOwner(wallet.network, wallet.address)) return true;
    if (!validateWalletAddress(wallet.network, wallet.address)) return false;
    this.wallets.push(wallet);
    this.update();
    return true;
  }

  removeWallet(network: Network, walletAddress: string) {
    walletAddress = normalizeAddress(network, walletAddress);
    this.wallets = this.wallets.filter(
      (wallet) => wallet.address != walletAddress || wallet.network != network
    );
    this.update();
    return true;
  }

  removeWalletsByAddress(walletAddress: string) {
    walletAddress = normalizeId(walletAddress);
    this.wallets = this.wallets.filter(
      (wallet) => normalizeId(wallet.address) !== walletAddress
    );
    this.update();
    return true;
  }

  wallet(network: Network, walletAddress: string): Wallet | null {
    walletAddress = normalizeAddress(network, walletAddress);
    return (
      this.wallets.find(
        (w) => w.network == network && w.address == walletAddress
      ) || null
    );
  }

  isWalletOwner(network: Network, address: string): boolean {
    return !!this.wallet(network, address);
  }

  isNFTOwner(address: string): boolean {
    return this.walletAddresses
      .map((w) => normalizeId(w))
      .includes(normalizeId(address));
  }

  getWalletsByNetwork(network: Network): Wallet[] {
    if (EVM_NETWORKS.includes(network))
      return this.wallets.filter((wallet) =>
        EVM_NETWORKS.includes(wallet.network)
      );
    return this.wallets
      .filter((wallet) => wallet.network == network)
      .sort((a, b) => b.connectedAt - a.connectedAt);
  }

  getWalletAddressesByNetwork(network: Network): string[] {
    return unique(
      this.getWalletsByNetwork(network).map((wallet) => wallet.address)
    );
  }

  addMoonpass(moonpassId: string) {
    if (this.moonpassIds.includes(moonpassId)) return true;
    this.moonpassIds.push(moonpassId);
    return true;
  }

  removeMoonpass(moonpassId: string) {
    this.moonpassIds = this.moonpassIds.filter(
      (_moonpassId) => _moonpassId != moonpassId
    );
    return true;
  }

  isMoonpassOwner(moonpassId: string): boolean {
    return this.moonpassIds.includes(moonpassId);
  }

  useNewSession(sessionId = "") {
    this.update();
    this.session = `${this.id}:${sessionId || cryptoId(4)}`;
    return this.session;
  }
}
