import { StyleFunctionProps } from "@chakra-ui/react";

export const styles = {
  global: (props: StyleFunctionProps) => ({
    body: {
      bg: "transparent",
      color: props.colorMode === "dark" ? "#A0A0A7" : "#23262F",
      fontFamily: "'Clash Grotesk', 'Figtree', 'Circular Std', sans-serif",
      fontWeight: 500,
    },
    "input::placeholder": {
      fontSize: "0.75em",
    },
    span: {
      color: props.colorMode === "dark" ? "#A0A0A7" : "#777E90",
    },
    "h1, h2, h3, h4, h5, h6": {
      color: props.colorMode === "dark" ? "#DEDEE0" : "#23262F",
      fontWeight: "500",
      fontStyle: "normal",
    },
    h1: {
      color: props.colorMode === "dark" ? "#FFF" : "#23262F",
      fontSize: "24px",
      lineHeight: "40px",
    },
    h2: {
      fontSize: "20px",
      lineHeight: "25px",
    },
    h4: {
      fontSize: "12px",
      lineHeight: "20px",
    },
    ".nav-active": {
      bg: props.colorMode === "dark" ? "rgba(255, 255, 255, 0.1)" : "#F3FBFA",
      color: "#00A28D",
    },
    ".nav-default": {
      color: "#A0A0A7",
    },
    ".chakra-card": {
      bg: props.colorMode === "dark" ? "rgba(255, 255, 255, 0.1)" : "#fff",
      boxShadow:
        props.colorMode === "dark"
          ? "none"
          : "0px 0px 50px rgba(0, 0, 0, 0.05)",
      border: props.colorMode === "dark" ? "" : "none",
    },
    a: {
      color: "primary",
    },
    /* Remove Chakra UI Focus*/
    ":focus, :focus-visible, *[data-focus]": {
      boxShadow: "none !important",
      outline: "none !important",
      borderColor: "inherit !important",
    },
  }),
};

export default styles;
