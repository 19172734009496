import { ComponentStyleConfig } from "@chakra-ui/react";

export const Accordion: ComponentStyleConfig = {
  baseStyle: {
    root: {
      borderRadius: "8px",
      boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.05)",
      overflow: "hidden",
      _dark: {
        borderRadius: "8px",
        boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.05)",
      },
    },
    container: {
      borderRadius: "8px",
      border: "none",
    },
    button: {
      background: "rgba(255, 255, 255, 0.1)",
      minHeight: "62px",
      border: "none",
      _focus: {
        background: "rgba(255, 255, 255, 0.1)",
        boxShadow: "none",
      },
      _hover: {
        background: "rgba(255, 255, 255, 0.1)",
        boxShadow: "none",
      },
    },
    panel: {
      paddingTop: 0,
      _dark: {
        border: "none",
        paddingTop: 0,
        background: "rgba(255, 255, 255, 0.1)",
      },
    },
    icon: {
      _dark: {},
    },
  },
  defaultProps: {},
};

export default Accordion;
