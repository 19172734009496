import { toJSON } from "../../utils";

export interface NFTEventTierMoonpassConstraintParam
  extends Partial<NFTEventTierMoonpassConstraint> {}

// Only set if user owns specific moonpass, allow access without further checking
export class NFTEventTierMoonpassConstraint
  implements NFTEventTierMoonpassConstraintParam
{
  edition: string; // Moonpass Edition ID

  constructor(params?: NFTEventTierMoonpassConstraintParam) {
    this.edition = params?.edition || "all";
  }

  get json() {
    return toJSON(this);
  }
}
