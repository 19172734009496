import { isEmpty, omit, pick } from "lodash";
import { Base, BaseIdOnly } from "../base";
import { Currency, toCurrency, toUSD } from "../currency";
import { DEFAULT_LANGUAGE, Language } from "../language";
import { ceil, minus, sum, times } from "../math";
import { Network } from "../network";
import { PAYMENT_AMOUNT_PRECISION } from "./constants";
import { Discount, DiscountParam } from "./discount";
import { PaymentConfig, PaymentMethod } from "./payment";

export const DEFAULT_TRANSACTION_CONTACT_FIELD_KEYS = [
  "name",
  "email",
  "wallet",
];

export enum TransactionStatus {
  INITIAL = "initial",
  SUBMITTED = "submitted",
  PENDING = "pending",
  PROCESSING = "processing",
  SUCCEEDED = "succeeded",
  REFUNDED = "refunded",
  FAILED = "failed",
}

export enum SubscriptionStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export interface TransactionContact {
  email?: string;
  name?: string;
  wallet?: string;
  phone?: string;

  // invoice
  billingCompany?: string;
  billingAddress?: string;
  vatNumber?: string;
}

export interface TransactionItemParam extends Partial<TransactionItem> {
  productId: string; // usually tierId
}
export class TransactionItem extends BaseIdOnly {
  productId: string; // usually tierId
  name: string; // usually tier name
  price: number;
  quantity: number;
  contact: TransactionContact;
  priceId: string;
  extraInfo?: { key: string; value: string | string[] }[];
  createdAt: number;
  valid?: boolean;

  constructor(params: TransactionItemParam) {
    super(params);
    this.productId = params.productId;
    this.priceId = params.priceId || "";
    this.name = params.name || "";
    this.price = Number(params.price || 0);
    this.quantity = Number(params.quantity || 1) || 1;
    this.contact = params.contact || {};
    this.createdAt = Number(params.createdAt || Date.now());
    if (params.extraInfo && !isEmpty(params.extraInfo))
      this.extraInfo = params.extraInfo;
    if (params.valid) this.valid = params.valid;
  }
}

export interface TransactionAirdropResult {
  itemId: string;
  recipient: string;
  network: Network;
  contractAddress: string;
  txnHash: string;
  txnFee?: number;
  userId?: string;
  email?: string;
}

export interface TransactionParam
  extends Omit<Partial<Transaction>, "items" | "discount"> {
  userId: string;
  eventId: string;
  items?: TransactionItemParam[];
  discount?: DiscountParam;
}

const privateProperties = ["emailEvents", "webhookEvents", "refIds"];
export class Transaction extends Base implements TransactionParam {
  userId: string;
  eventId: string;
  tierId: string;
  organizerId: string;
  contact: TransactionContact;

  eventName: string;
  language: Language;
  currency: Currency;
  status: TransactionStatus;
  items: TransactionItem[];
  fee: number; // 0.05 = 5%
  vatRate?: number; // 0.05 = 5%
  amount: number;
  finalAmount: number;
  refIds?: { key: string; value: string }[];
  webhookEvents?: any[];
  airdropResults: TransactionAirdropResult[];
  emailEvents?: any[];
  runnerResults?: any[];

  paymentMethod?: PaymentMethod;
  discount?: Discount;
  error?: string;
  referrer?: string; // referrer email / wallet
  referrerId?: string;
  referralCode?: string;

  metadata: {
    [key: string]: string;
  };
  createdBy?: string;

  subscription?: boolean;
  subscriptionStatus?: SubscriptionStatus;
  rewardId?: string;

  constructor(params: TransactionParam) {
    super(params);
    this.userId = params.userId;
    this.eventId = params.eventId;
    this.organizerId = params.organizerId || "";
    this.contact = params.contact || {};
    this.eventName = params.eventName || "";

    this.status = params.status || TransactionStatus.INITIAL;
    this.language = params.language || DEFAULT_LANGUAGE;
    this.currency = toCurrency(params.currency);
    this.items = (params.items || [])
      .map((item) => new TransactionItem(item))
      .sort((a, b) => a.createdAt - b.createdAt);
    this.tierId = this.items.length > 0 ? this.items[0].productId || "" : "";
    this.airdropResults =
      params.airdropResults && !isEmpty(params.airdropResults)
        ? params.airdropResults
        : [];
    this.fee = Number(params.fee ?? 0);
    this.amount = this.originalAmount;
    this.finalAmount = ceil(
      sum([this.amount, this.transactionFee, this.VAT]),
      PAYMENT_AMOUNT_PRECISION
    );
    this.metadata = params.metadata || {};

    if (params.webhookEvents && !isEmpty(params.webhookEvents))
      this.webhookEvents = params.webhookEvents;
    if (params.emailEvents && !isEmpty(params.emailEvents))
      this.emailEvents = params.emailEvents;
    if (params.refIds && !isEmpty(params.refIds)) this.refIds = params.refIds;

    if (params.vatRate) this.vatRate = Number(params.vatRate ?? 0);
    if (params.paymentMethod) this.paymentMethod = params.paymentMethod;
    if (params.error) this.error = params.error;
    if (params.referrer) this.referrer = params.referrer;
    if (params.referrerId) this.referrerId = params.referrerId;
    if (params.referralCode) this.referralCode = params.referralCode;
    if (params.discount) {
      this.discount = new Discount(params.discount);
    }
    if (params.runnerResults && !isEmpty(params.runnerResults))
      this.runnerResults = params.runnerResults;
    if (params.createdBy) this.createdBy = params.createdBy;
    if (params.subscription) {
      this.subscription = params.subscription;
      if (params.subscription) {
        this.paymentMethod = PaymentMethod.STRIPE;
      }
    }
    if (params.subscriptionStatus)
      this.subscriptionStatus = params.subscriptionStatus;
    if (params.rewardId) this.rewardId = params.rewardId;

    this.calculateFinalAmount();
  }

  get publicData(): TransactionParam {
    return omit(this.json, privateProperties) as any;
  }

  get privateData(): Partial<TransactionParam> {
    return pick(this.json, ["id", ...privateProperties]);
  }

  get originalAmount() {
    return ceil(
      sum(this.items.map((item) => times(item.price, item.quantity))),
      PAYMENT_AMOUNT_PRECISION
    );
  }

  get transactionFee() {
    if (!this.fee) return 0;
    return ceil(times(this.amount, this.fee), PAYMENT_AMOUNT_PRECISION);
  }

  get VAT() {
    if (!this.vatRate) return 0;
    return ceil(times(this.amount, this.vatRate), PAYMENT_AMOUNT_PRECISION);
  }

  get discountAmount() {
    try {
      if (!this.discount) return 0;
      const items =
        this.discount.applyLimit == -1
          ? this.items
          : this.items
              .filter((item) =>
                this.discount && this.discount.productId
                  ? item.productId == this.discount.productId
                  : true
              )
              .slice(0, this.discount.applyLimit);

      return Math.min(
        items.reduce(
          (acc, cur) =>
            (acc = sum([
              acc,
              this.discount &&
              (this.discount.productId
                ? cur.productId == this.discount.productId
                : true)
                ? this.discount.calculateDiscount(
                    times(cur.price, cur.quantity)
                  )
                : 0,
            ])),
          0
        ),
        this.originalAmount
      );
    } catch (error) {}
    return 0;
  }

  get isReferral() {
    return !!this.referrerId;
  }

  get USD() {
    return toUSD(this.currency, this.finalAmount);
  }

  get amountUSD() {
    return toUSD(this.currency, this.amount);
  }

  get originalAmountUSD() {
    return toUSD(this.currency, this.originalAmount);
  }

  get isProcessed() {
    if (
      ![TransactionStatus.SUCCEEDED, TransactionStatus.REFUNDED].includes(
        this.status
      )
    )
      return false;
    if (
      !isEmpty(this.airdropResults) &&
      this.airdropResults.length > 0 &&
      this.airdropResults.length === this.items.length
    )
      return true;
    if (
      this.emailEvents &&
      !isEmpty(this.emailEvents) &&
      this.emailEvents.length > 0 &&
      this.emailEvents.filter((e) => e.event === "delivered").length >=
        this.items.length
    )
      return true;
    return false;
  }

  // apply discount to each of the items
  applyDiscount(discount: Discount) {
    if (
      !discount?.code ||
      discount.value == undefined ||
      discount.value == null
    )
      return this;
    this.discount = discount;
    this.amount = ceil(
      Math.max(minus(this.originalAmount, this.discountAmount), 0),
      PAYMENT_AMOUNT_PRECISION
    );
    this.finalAmount = ceil(
      sum([this.amount, this.transactionFee, this.VAT]),
      PAYMENT_AMOUNT_PRECISION
    );
    if (this.discount.isReferral) {
      if (this.discount.referrer) this.referrer = this.discount.referrer;
      if (this.discount.referrerId) this.referrerId = this.discount.referrerId;
      if (this.discount.code) this.referralCode = this.discount.code;
    }
    return this;
  }

  removeDiscount() {
    if (this.discount) {
      if (this.discount.isReferral) {
        if (this.discount.referrer === this.referrer) delete this.referrer;
        if (this.discount.referrerId === this.referrerId)
          delete this.referrerId;
        if (this.discount.code === this.referralCode) delete this.referralCode;
      }
      delete this.discount;
    }
    return this.calculateFinalAmount();
  }

  calculateFinalAmount() {
    this.amount = this.originalAmount;
    this.finalAmount = ceil(
      sum([this.amount, this.transactionFee, this.VAT]),
      PAYMENT_AMOUNT_PRECISION
    );
    if (this.discount) this.applyDiscount(this.discount);
    return this;
  }

  update() {
    this.updatedAt = Date.now();
    this.calculateFinalAmount();
    return this;
  }

  getAutoDiscount(paymentConfig: PaymentConfig) {
    if (!paymentConfig.autoDiscounts || isEmpty(paymentConfig.autoDiscounts))
      return null;
    for (const discountConfig of paymentConfig.autoDiscounts.sort(
      (a, b) => a.priority - b.priority
    )) {
      if (isEmpty(discountConfig.discount)) continue;
      // Quantity
      if (discountConfig.criteria.quantity) {
        if (this.items.length >= discountConfig.criteria.quantity) {
          return discountConfig.discount;
        }
      }
    }
    return null;
  }
}
