import { matchRegex } from "@models/regex";
import { browserName, detectOS } from "detect-browser";

const UNSUPPORTED_BROWSERS = [
  "aol",
  //   "edge",
  //   "edge-ios",
  "yandexbrowser",
  "kakaotalk",
  "samsung",
  "silk",
  "miui",
  "beaker",
  //   "edge-chromium",
  //   "chrome",
  "chromium-webview",
  "phantomjs",
  //   "crios",
  //   "firefox",
  "fxios",
  "opera-mini",
  "opera",
  "pie",
  "netfront",
  "ie",
  "bb10",
  //   "android",
  //   "ios",
  //   "safari",
  "facebook",
  "instagram",
  "ios-webview",
  "curl",
  "searchbot",
];

const WEBVIEW_RULES = [
  // if it says it's a webview, let's go with that
  "WebView",
  // iOS webview will be the same as safari but missing "Safari"
  "(iPhone|iPod|iPad)(?!.*Safari)",
  // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
  // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
  "Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})",
  // old chrome android webview agent
  "Linux; U; Android",
];

const ua = () => {
  try {
    return window.navigator.userAgent;
  } catch (error) {}
  return "";
};

const browser = () => {
  try {
    const _browser = browserName(ua()) || "";
    // console.log(`Browser: ${_browser}`);
    return _browser;
  } catch (error) {}
  return "";
};

export const isWebview = () => {
  try {
    return !!ua().match(new RegExp("(" + WEBVIEW_RULES.join("|") + ")", "ig"));
  } catch (error) {}
  return true;
};

export const isSupportedMobileBrowser = () => {
  try {
    if (isWebview()) return false;
    if (!isMobile()) return true;
    return !UNSUPPORTED_BROWSERS.includes(browser());
  } catch (error) {}
  return false;
};

export const isMobile = () => {
  try {
    return (
      window.screen.width <= 640 ||
      window.screen.height <= 640 ||
      matchRegex(/Mobi|Android/i, ua()) ||
      [
        "edge-ios",
        "crios",
        "fxios",
        "android",
        "ios",
        "facebook",
        "instagram",
        "ios-webview",
      ].includes(browser())
    );
  } catch (error) {}
  return false;
};

export const isAndroid = () => {
  try {
    const _ua = ua().toLowerCase();
    return (
      browser() === "android" ||
      _ua.includes("android") ||
      detectOS(_ua) === "Android OS"
    );
  } catch (error) {}
  return false;
};

export const isPhantomBrowser = () => {
  return isMobile() && ua().toLowerCase().includes("phantom");
};

export const isSafari = () => {
  try {
    return (
      browser() === "safari" ||
      matchRegex(/^((?!chrome|android).)*safari/i, window.navigator.userAgent)
    );
  } catch (error) {}
  return false;
};
