import { cssVar, defineStyleConfig } from "@chakra-ui/react";

const $startColor = cssVar("skeleton-start-color");
const $endColor = cssVar("skeleton-end-color");

export const Skeleton = defineStyleConfig({
  baseStyle: {
    [$startColor.variable]: "rgba(255, 255, 255, 0.1)",
    [$endColor.variable]: "rgba(255, 255, 255, 0.2)",
    _light: {
      [$startColor.variable]: "colors.gray.200",
      [$endColor.variable]: "colors.gray.300",
    },
  },
  sizes: {},
  variants: {},
  defaultProps: {},
});

export default Skeleton;
