import {
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorMode,
  useMediaQuery,
} from "@chakra-ui/react";
import { ReactElement, ReactNode, Suspense, useCallback, useMemo } from "react";
import LoadingAnimation from "../Loader/LoadingAnimation";
import { NextImage } from "../NextImage/NextImage";

const DialogHeader = ({
  icon,
  isError,
  title,
  color,
}: {
  icon: JSX.Element;
  isError?: boolean;
  title: string;
  color: string;
}) => {
  return (
    <Suspense fallback={<></>}>
      {isError ? (
        <h2 className="auto-center text-2xl">
          <div className="mb-4">{icon}</div>
          <Text color={color} fontSize={20} lineHeight="1.25em">
            {title}
          </Text>
        </h2>
      ) : (
        <h2
          className="text-center items-center flex justify-center gap-2 text-2xl"
          color={color}
        >
          {icon}
          <Text color={color} fontSize={20} lineHeight="1.25em">
            {title}
          </Text>
        </h2>
      )}
    </Suspense>
  );
};

const DialogBody = ({ body }: { body: JSX.Element | ReactNode }) => {
  return (
    <Suspense
      fallback={
        <div className="m-auto">
          <LoadingAnimation />
        </div>
      }
    >
      {body}
    </Suspense>
  );
};

const DialogFooter = ({ actions }: { actions: JSX.Element }) => {
  return <Suspense fallback={<></>}>{actions}</Suspense>;
};

export interface DialogProps {
  loading?: boolean;
  icon?: any;
  iconNode?: ReactElement | null;
  title?: any;
  children?: ReactNode;
  isOpen?: boolean;
  isClose?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  actions?: {
    text: string;
    onClick: () => void;
    primary?: boolean;
  }[];
  size?: string;
  contentPadding?: any;
  headerPadding?: any;
  bodyPadding?: any;
  height?: any;
  maxHeight?: any;
  isError?: boolean;
  width?: any;
  isFooter?: boolean;
  isHeader?: boolean;
  isSuccess?: boolean;
  isCloseDrawer?: boolean;
}

const Dialog = ({
  loading,
  icon: icon = "",
  iconNode,
  title: title = "",
  children,
  isOpen: isOpen = false,
  isClose,
  onOpen,
  onClose: onClose = () => {},
  actions: actions = [],
  size: size = "md",
  contentPadding: contentPadding = null,
  headerPadding: headerPadding = null,
  bodyPadding: bodyPadding = null,
  height,
  maxHeight,
  isError: isError = false,
  width,
  isFooter: isFooter = true,
  isHeader: isHeader = true,
  isSuccess: isSuccess = false,
  isCloseDrawer: isCloseDrawer = false,
}: DialogProps) => {
  const { colorMode } = useColorMode();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  size = isMobile ? "md" : size || "md";
  isClose = loading ? false : isClose ?? true;

  const DialogIcon = useMemo(() => {
    if (loading) return <LoadingAnimation />;
    return !!iconNode ? (
      iconNode
    ) : icon ? (
      <NextImage className="w-6 svg-primary" src={icon} />
    ) : (
      <></>
    );
  }, [loading, iconNode, icon]);

  const color = useMemo(() => {
    if (isError) return "#EB5757";
    return isSuccess
      ? colorMode === "dark"
        ? "#45BFB0"
        : "#00A28D"
      : colorMode === "dark"
        ? "#FFFFF"
        : "#23262F";
  }, [isError, isSuccess, colorMode]);

  const onDialogClose = useCallback(loading ? () => {} : onClose, [
    loading,
    onClose,
  ]);

  const DialogMain = useMemo(() => {
    return loading ? (
      <div className="flex flex-col gap-4 m-auto">
        <LoadingAnimation />
        {children || <></>}
      </div>
    ) : (
      children || <></>
    );
  }, [loading, children]);

  const DialogActions = useMemo(() => {
    return actions && actions.length > 0 ? (
      <ButtonGroup variant="ghost" className="self-end" spacing="4">
        {actions.map((action, i) => {
          return (
            <Button
              textTransform="capitalize"
              color={action.primary ? "primary.300" : "neutral.200"}
              onClick={action.onClick}
              key={i}
              fontSize="0.9em"
            >
              {action.text}
            </Button>
          );
        })}
      </ButtonGroup>
    ) : (
      <></>
    );
  }, [actions?.length]);

  if (isMobile) {
    return (
      <Drawer
        placement="bottom"
        onClose={onDialogClose}
        isOpen={isOpen}
        blockScrollOnMount={false}
      >
        <DrawerOverlay />
        <DrawerContent
          maxHeight={maxHeight}
          minHeight={height}
          transform="none !important"
          padding={contentPadding}
        >
          {!loading && isCloseDrawer && <DrawerCloseButton />}
          {!loading && isHeader && (
            <DrawerHeader padding={headerPadding}>
              <DialogHeader icon={DialogIcon} title={title} color={color} />
            </DrawerHeader>
          )}
          <DrawerBody padding={bodyPadding}>
            <DialogBody body={DialogMain} />
          </DrawerBody>
          {!loading && (
            <DrawerFooter>
              <DialogFooter actions={DialogActions} />
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    );
  }

  return (
    <Modal
      onClose={onDialogClose}
      isOpen={isOpen}
      isCentered
      size={size}
      blockScrollOnMount={false}
    >
      <ModalOverlay />
      <ModalContent
        padding={contentPadding}
        minHeight={height}
        maxWidth={width}
      >
        {!loading && isHeader && (
          <ModalHeader padding={headerPadding}>
            <DialogHeader icon={DialogIcon} title={title} color={color} />
          </ModalHeader>
        )}
        {isClose && !loading && <ModalCloseButton />}
        <ModalBody padding={bodyPadding}>
          <DialogBody body={DialogMain} />
        </ModalBody>
        {isFooter && !loading && (
          <ModalFooter>
            <DialogFooter actions={DialogActions} />
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
export default Dialog;
