import { v4 as uuid } from "uuid";
import { toJSON } from "./utils";

export interface IBaseIdOnly extends Partial<BaseIdOnly> {}
export class BaseIdOnly {
  id: string;

  constructor(params?: IBaseIdOnly) {
    this.id = params?.id || uuid();
  }

  get json() {
    return toJSON(this);
  }
}
export type OmitId = "id" | "json";

export interface IBase extends Partial<Base> {}
export class Base extends BaseIdOnly {
  createdAt: number;
  updatedAt: number;

  constructor(params?: IBase) {
    super(params);
    this.createdAt = Number(params?.createdAt || Date.now());
    this.updatedAt = Number(params?.updatedAt || Date.now());
  }
}
export type OmitTimestamp = OmitId | "createdAt" | "updatedAt";
