import { isEmpty } from "lodash";
import { div, pow } from "../../math";
import { Network } from "../../network";
import { ERC20TokenBalance } from "../../token";
import { NFT, NFTOwnership, normalizeAddress, toNFTId } from "../nft";

export const MORALIS_SUPPORTED_EVM_NETWORKS = [
  Network.ETHEREUM,
  Network.POLYGON,
  Network.BSC,
  Network.AVALANCHE,
  Network.FANTOM,
  Network.CRONOS,

  Network.ETHEREUM_GOERLI,
  Network.POLYGON_MUMBAI,
];
export interface MoralisSolanaAsset {
  associatedTokenAddress: string;
  mint: string;
}

export interface MoralisAsset {
  token_address: string;
  token_id: string;
  owner_of: string;
  block_number: string;
  block_number_minted: string;
  token_hash: string;
  amount: string;
  updated_at?: string;
  contract_type?: string;
  name?: string;
  symbol?: string;
  token_uri: string;
  metadata: string;
  last_token_uri_sync: string;
  last_metadata_sync: string;
}

export interface MoralisNFTResponse {
  total: number;
  page: number;
  page_size: number;
  cursor: string;
  result: MoralisAsset[];
}

export const moralisAssetToNFT = (
  network: Network,
  asset: MoralisAsset,
  owner: string
): NFT => {
  let metadata: any = {};
  try {
    if (asset.metadata) metadata = JSON.parse(asset.metadata);
  } catch (error) {}
  return new NFT({
    network,
    owners: [{ address: owner, quantity: 1 }],
    name: metadata.name || asset.name,
    collectionId: asset.token_address,
    tokenId: asset.token_id,

    imageUrl: metadata.image
      ? metadata.image.replace("ipfs://", "https://ipfs.io/ipfs/")
      : "",
    attributes: !isEmpty(metadata)
      ? metadata.attributes.map((attribute: any) => ({
          traitType: attribute.trait_type,
          value: attribute.value,
        }))
      : [],
    animationUrl: metadata.animation_url,
  });
};

export const moralisAssetToNFTOwnership = (
  network: Network,
  asset: MoralisAsset,
  owner: string
): NFTOwnership => {
  let metadata: any = {};
  try {
    if (asset.metadata) metadata = JSON.parse(asset.metadata);
  } catch (error) {}
  return new NFTOwnership({
    network,
    standard: asset.contract_type,
    owner,
    collectionId: asset.token_address,
    tokenId: asset.token_id,
    quantity: parseInt(asset.amount),
    attributes: !isEmpty(metadata)
      ? metadata.attributes?.map((attribute: any) => ({
          traitType: attribute.trait_type,
          value: attribute.value,
        }))
      : [],
  });
};

export const moralisSolanaAssetToNFT = (
  network: Network,
  asset: MoralisSolanaAsset,
  owner: string
): NFT => {
  return new NFT({
    network,
    owners: [{ address: owner, quantity: 1 }],
    collectionId: "",
    tokenId: asset.mint,
  });
};

export interface MoralisTokenBalance {
  token_address: string;
  name: string;
  symbol: string;
  logo: string | null;
  thumbnail: string | null;
  decimals: number;
  balance: string;
  possible_spam: boolean;
}

export const moralisBalanceToTokenBalance = (
  network: Network,
  balance: MoralisTokenBalance,
  owner: string
): ERC20TokenBalance => {
  let quantity = parseInt(balance.balance);
  if (balance.decimals) {
    const decimals = parseInt(String(balance.decimals));
    if (decimals > 0) {
      quantity = div(quantity, pow(10, decimals));
    }
  }
  owner = normalizeAddress(network, owner);
  const address = normalizeAddress(network, balance.token_address);
  const id = toNFTId([network, address]);

  return {
    id,
    network,
    address,
    owner,
    name: balance.name || "",
    symbol: balance.symbol || "",
    logo: balance.logo || "",
    balance: quantity,
  };
};
