import { isEmpty, omit, pick } from "lodash";
import { Base } from "../base";
import {
  DEFAULT_DEPLOYMENT_NETWORK,
  Network,
  SUPPORTED_NETWORKS,
  toMainnet,
} from "../network";
import {
  DEFAULT_DEPLOYMENT_STANDARD,
  NFTStandard,
  normalizeAddress,
} from "./nft";

export enum ContractError {
  MISSING_PROVIDER = "missing_provider",
  UNKNOWN_METHOD = "unknown_method",
  UNKNOWN_RPC = "unknown_rpc",
  UNKNOWN_CONTRACT = "unknown_contract",
  UNKNOWN_FACTORY = "unknown_factory",
  UNAUTHORIZED = "unauthorized",
  UNSUPPORTED_NETWORK = "unsupported_network",
  EXECUTION_ERROR = "execution_error",
  EXECUTION_TIMEOUT = "execution_timeout",
  TRANSACTION_TIMEOUT = "transaction_timeout",
}

export interface SmartContractParam extends Partial<SmartContract> {}

const privateProperties = ["abi"];
const immutableProperties = ["address"];

export class SmartContract extends Base {
  name: string;
  symbol: string;
  abi?: any[];
  address?: string;
  network: Network;
  standard: NFTStandard;
  ipfsOnly: boolean;
  royaltyReceiver: string;
  royaltyFee: number;
  factory?: ContractFactoryName;
  description?: string;

  // @deprecated
  logo?: string;

  constructor(params?: SmartContractParam) {
    super(params);
    this.name = params?.name || "";
    this.symbol = params?.symbol || "";
    this.network = toMainnet(params?.network || DEFAULT_DEPLOYMENT_NETWORK);
    this.standard = params?.standard || DEFAULT_DEPLOYMENT_STANDARD;
    if (params?.abi && !isEmpty(params.abi)) this.abi = params.abi;
    if (params?.address)
      this.address = normalizeAddress(this.network, params.address);
    this.ipfsOnly = !!params?.ipfsOnly;
    this.royaltyReceiver = params?.royaltyReceiver
      ? normalizeAddress(this.network, params?.royaltyReceiver)
      : "";
    this.royaltyFee = params?.royaltyFee ?? 0;
    if (params?.logo) this.logo = params.logo || "";
    if (params?.description) this.description = params.description || "";
    if (params?.factory) this.factory = params.factory;
  }

  get publicData() {
    return omit(this.json, privateProperties) as SmartContractParam;
  }

  get updatableData() {
    return omit(this.publicData, immutableProperties) as SmartContractParam;
  }

  get privateData(): Partial<SmartContractParam> {
    return pick(this.json, [
      "id",
      ...privateProperties,
      ...immutableProperties,
    ]);
  }
}

export enum ContractFactoryName {
  ERC721_AIRDROP = "erc721-airdrop",
  ERC721_SOULBOUND_AIRDROP = "erc721-soulbound-airdrop",
  ERC1155_AIRDROP = "erc1155-airdrop",
}

export interface SmartContractParamFactory extends SmartContractFactory {
  name: ContractFactoryName;
}
export class SmartContractFactory extends Base {
  name: ContractFactoryName;
  contracts: { network: Network; address: string }[];
  abi: any[];
  templateAbi: any[];
  standard: NFTStandard;

  constructor(params: SmartContractParamFactory) {
    super(params);
    this.name = params.name;
    this.contracts =
      params.contracts && !isEmpty(params.contracts)
        ? params.contracts
            .filter((c) => SUPPORTED_NETWORKS.includes(c.network))
            .map((c) => ({
              network: toMainnet(c.network),
              address: normalizeAddress(c.network, c.address),
            }))
        : [];
    this.abi = params.abi || [];
    this.templateAbi = params.templateAbi || [];
    this.standard = params.standard;
  }

  getAddress(network: Network) {
    network = toMainnet(network);
    return (
      this.contracts.find((contract) => contract.network === network)
        ?.address || ""
    );
  }
}
