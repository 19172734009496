import { isEmpty, sum } from "lodash";
import { Logic } from "../../misc";
import { Network } from "../../network";
import { normalizeAddress, toNFTId } from "../../nft";
import { ERC20TokenBalance } from "../../token";
import { toJSON } from "../../utils";

export interface TokenConstraintContractConfigParam {
  id?: string;
  network: Network;
  address: string;
}

export class TokenConstraintContractConfig
  implements TokenConstraintContractConfigParam
{
  id: string;
  network: Network;
  address: string;

  constructor(params: TokenConstraintContractConfigParam) {
    this.network = params.network;
    this.address = normalizeAddress(this.network, params.address);
    this.id = toNFTId([this.network, this.address]);
  }

  validate(balance: ERC20TokenBalance) {
    if (balance.network != this.network) return false;
    if (balance.address != this.address) return false;
    return balance.balance > 0;
  }
}

export interface TokenConstraintResult {
  contracts: ERC20TokenBalance[];
  quantity: number;
}

export interface TokenConstraintParam
  extends Omit<Partial<TokenConstraint>, "contracts"> {
  contracts?: TokenConstraintContractConfigParam[];
}

export class TokenConstraint implements TokenConstraintParam {
  contracts: TokenConstraintContractConfig[];
  logic: Logic;

  constructor(params?: TokenConstraintParam) {
    this.contracts =
      params?.contracts && params.contracts.length > 0
        ? params.contracts
            .filter((c) => c && c.network && c.address)
            .map((c) => new TokenConstraintContractConfig(c))
        : [];
    this.logic = params?.logic || Logic.AND;
  }

  get json() {
    return toJSON(this);
  }

  validate(balances: ERC20TokenBalance[]) {
    const results: ERC20TokenBalance[] = [];
    if (isEmpty(this.contracts)) return [];
    for (const contract of this.contracts) {
      const balance = balances.find((b) => contract.validate(b));
      if (!balance && this.logic === Logic.AND) return [];
      if (balance) results.push(balance);
    }
    return results;
  }

  countValidEntry(balances: ERC20TokenBalance[]) {
    if (!balances || isEmpty(balances)) return 0;
    const validBalances = this.validate(balances);
    return this.logic == Logic.OR
      ? sum(validBalances.map((b) => b.balance))
      : Math.min(...validBalances.map((b) => b.balance));
  }

  getValidCount(balances: ERC20TokenBalance[]) {
    return this.countValidEntry(balances);
  }

  getValidResult(balances: ERC20TokenBalance[]): TokenConstraintResult {
    const contracts = this.validate(balances);
    const quantity = this.countValidEntry(contracts);
    return {
      contracts,
      quantity,
    };
  }
}
