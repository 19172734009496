import { isMobile, isSafari } from "@lib/browser";
import { sleep, to } from "@lib/utils";
import { ParticleNetwork } from "@particle-network/auth";
import * as Sentry from "@sentry/browser";
import { isEmpty } from "lodash";
import { useUserStore } from "../auth";
import {
  PARTICLE_APP_ID,
  PARTICLE_CLIENT_KEY,
  PARTICLE_PROJECT_ID,
} from "./const";

let PARTICLE: ParticleNetwork;

export const initParticleNetwork = () => {
  if (!PARTICLE_PROJECT_ID) throw "Missing PARTICLE_PROJECT_ID";
  if (!PARTICLE_APP_ID) throw "Missing PARTICLE_APP_ID";
  if (!PARTICLE_CLIENT_KEY) throw "Missing PARTICLE_CLIENT_KEY";
  if (PARTICLE) return PARTICLE;
  PARTICLE = new ParticleNetwork({
    projectId: PARTICLE_PROJECT_ID,
    clientKey: PARTICLE_CLIENT_KEY,
    appId: PARTICLE_APP_ID,
    chainName: "Ethereum", //optional: current chain name, default Ethereum.
    chainId: 1, //optional: current chain id, default 1.
    wallet: {
      displayWalletEntry: false, //show wallet entry when connect particle.
      supportChains: [
        { id: 1, name: "Ethereum" },
        { id: 137, name: "Polygon" },
        { id: 43114, name: "Avalanche" },
      ], // optional: web wallet support chains.
      customStyle: {}, //optional: custom wallet style
    },
  });
  return PARTICLE;
};

const loginParticleWallet = async (idToken?: string) => {
  Sentry.addBreadcrumb({
    category: "loginParticleWallet",
    level: "info",
  });
  idToken = idToken || useUserStore.getState().idToken;
  if (!idToken) return null;
  const pn = initParticleNetwork();
  if (isMobile() || isSafari()) await sleep(1);
  console.log("loginParticleWallet", pn, idToken);
  const { err, res } = await to(
    pn.auth.login({
      preferredAuthType: "jwt",
      account: idToken,
      hideLoading: true,
    }),
    0
  );
  if (err || !res || isEmpty(res.wallets)) {
    Sentry.captureException(err);
    console.log("loginParticleWallet", err);
    return null;
  }
  console.log("loginParticleWallet", res?.wallets);
  return res.wallets;
};

export const getParticleWallet = async (idToken?: string) => {
  const wallets = await loginParticleWallet(idToken);
  if (!wallets) return "";
  return wallets.find((w) => w.chain_name == "evm_chain")?.public_address || "";
};

export const openParticleWallet = async () => {
  Sentry.addBreadcrumb({
    category: "openParticleWallet",
    level: "info",
  });
  const pn = initParticleNetwork();
  console.log("openParticleWallet", pn.auth.isLogin());
  if (!pn.auth.isLogin()) await loginParticleWallet();
  return pn.openWallet();
};
