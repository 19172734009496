import { AuthError } from "@models/auth";
import * as Sentry from "@sentry/browser";
import { CrossIcon } from "../../assets";
import { NextImage } from "../../components/NextImage/NextImage";

const UnsupportedBrowser = () => {
  return (
    <>
      <p className="m-2 text-error font-semibold text-lg">
        This browser is not supported.
      </p>
      <p className="m-2">
        Please open the original link in another browser. <br />
      </p>
      <p className="m-2">
        We recommend using: <br /> Chrome / Safari / Firefox / Edge
      </p>
    </>
  );
};

const InvalidLink = () => {
  return (
    <>
      <p className="m-2 text-error font-semibold text-lg">
        This sign-in link has been used / is expired.
      </p>
      <p className="m-2">
        Please{" "}
        <a className="text-primary underline" href="/">
          sign in again
        </a>{" "}
        to request a new sign-in link. <br />
      </p>
    </>
  );
};

const AuthErrorMessage = ({
  error: error = "",
}: {
  error: string | AuthError;
}) => {
  const ErrorMessage = () => {
    switch (error) {
      case AuthError.UNSUPPORTED_BROWSER:
        // Sentry.captureMessage(error);
        return <UnsupportedBrowser />;
      case AuthError.INVALID_LINK:
      case AuthError.EXPIRED_LINK:
        // Sentry.captureMessage(error);
        return <InvalidLink />;
      default:
        Sentry.captureException(new Error(error));
        return (
          <>
            <p className="m-4">
              Please retry and sign in again.
              <br /> If the problem persist, please{" "}
              <a className="text-primary" href="mailto:support@moongate.id">
                contact support
              </a>{" "}
              and provide the following error code:
            </p>
            <p className="m-2 rounded-full text-white bg-neutral-800 text-sm p-2 px-4">
              {error}
            </p>
            <div className="mt-8">
              <a className="text-primary" href="/">
                Back
              </a>
            </div>
          </>
        );
    }
  };
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center text-center">
      <NextImage className="w-10 mb-4" src={CrossIcon} />
      <ErrorMessage />
    </div>
  );
};

export default AuthErrorMessage;
