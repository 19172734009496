import OGMeta from "./OGMeta";
import PWAMeta from "./PWAMeta";

const DefaultMeta = ({
  title: title = "Moongate",
  description:
    description = "Empowering the next generation of memberships and ticketing with dynamic NFTs.",
  url: url = "https://app.moongate.id/",
  image: image = "https://app.moongate.id/images/logo-color.svg",
}: {
  title?: string;
  description?: string;
  url?: string;
  image?: string;
}) => {
  return (
    <>
      <OGMeta title={title} description={description} url={url} image={image} />
      <PWAMeta />
    </>
  );
};

export default DefaultMeta;
