import { round } from "lodash";
import { NFT_CACHE_PERIOD } from "../nft";
import { MS_ONE_HOUR } from "../timezone";

export const OWNERSHIP_PROOF_REQUEST_DELAY = 2000;
export const OWNERSHIP_PROOF_EXPIRATION = 15000;
export const OWNERSHIP_PROOF_EXPIRATION_SECOND = round(
  OWNERSHIP_PROOF_EXPIRATION / 1000
);
export const NFT_VALID_BUFFER = NFT_CACHE_PERIOD + OWNERSHIP_PROOF_EXPIRATION;
export const EVENT_START_BUFFER = 12 * MS_ONE_HOUR;
export const EVENT_END_BUFFER = 2 * MS_ONE_HOUR;
export const TOKEN_EXPIRATION = OWNERSHIP_PROOF_EXPIRATION * 2;
