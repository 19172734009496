import { ChakraProvider } from "@chakra-ui/react";
import DefaultMeta from "@components/Meta/DefaultMeta";
import { FixedThemeProvider } from "@components/ToggleTheme";
import GoogleAnalyticsProvider from "@providers/analytic/GoogleAnalytics";
import { AuthProvider } from "@providers/auth";
import "@rainbow-me/rainbowkit/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "next-themes";
import type { AppProps } from "next/app";
import Head from "next/head";
import "styles/globals.scss";
import theme from "styles/theme";

const HOST = process.env.NEXT_PUBLIC_HOST || "https://siam.moongate.id";
if (!HOST) throw "Missing NEXT_PUBLIC_HOST";

const API_HOST = process.env.NEXT_PUBLIC_API_HOST || "";
if (!API_HOST) throw "Missing NEXT_PUBLIC_API_HOST";

const API_KEY = process.env.NEXT_PUBLIC_API_KEY || "";
if (!API_KEY) throw "Missing NEXT_PUBLIC_API_KEY";

const FIREBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || "";
if (!FIREBASE_PROJECT_ID) throw "Missing NEXT_PUBLIC_FIREBASE_PROJECT_ID";

const FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY || "";
if (!FIREBASE_API_KEY) throw "Missing NEXT_PUBLIC_FIREBASE_API_KEY";

const FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID || "";
if (!FIREBASE_APP_ID) throw "Missing NEXT_PUBLIC_FIREBASE_APP_ID";

const PARTICLE_PROJECT_ID = process.env.NEXT_PUBLIC_PARTICLE_PROJECT_ID || "";
if (!PARTICLE_PROJECT_ID)
  console.error("Missing NEXT_PUBLIC_PARTICLE_PROJECT_ID");

const PARTICLE_CLIENT_KEY = process.env.NEXT_PUBLIC_PARTICLE_CLIENT_KEY || "";
if (!PARTICLE_CLIENT_KEY)
  console.error("Missing NEXT_PUBLIC_PARTICLE_CLIENT_KEY");

const PARTICLE_APP_ID = process.env.NEXT_PUBLIC_PARTICLE_APP_ID || "";
if (!PARTICLE_APP_ID) console.error("Missing NEXT_PUBLIC_PARTICLE_APP_ID");

const WALLETCONNECT_PROJECT_ID =
  process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID || "";
if (!WALLETCONNECT_PROJECT_ID)
  console.error("Missing NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID");

const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID || "";

const AUTH_FALLBACK_PATH = "/";

const PUBLIC_ROUTES_WITH_AUTH = [/.*/];
// Public route without checking
const PUBLIC_ROUTES_NO_AUTH = [];

// Protected routes
const PROTECTED_ROUTES = [
  /^\/[\w\-]+\/checkout\/[\w\-]+\/success/im,
  /^\/[\w\-]+\/checkout\/[\w\-]+\/cancel/im,
];

const THEME = "dark";

function App({ Component, pageProps }: AppProps) {
  const queryClient = new QueryClient();
  const props = pageProps as any;
  const title = props?.title || "Moongate";
  const description =
    props?.description ||
    "Empowering the next generation of memberships and ticketing with dynamic NFTs.";
  const url = props?.url || HOST || "https://siam.moongate.id/";
  const image = props?.image || "https://app.moongate.id/images/logo-color.svg";

  return (
    <>
      <Head>
        <title>{title}</title>
        <DefaultMeta
          title={title}
          description={description}
          url={url}
          image={image}
        />
      </Head>
      <ThemeProvider
        attribute="class"
        defaultTheme={THEME}
        storageKey="chakra-ui-color-mode"
      >
        <ChakraProvider theme={theme}>
          <GoogleAnalyticsProvider measurementId={GA_MEASUREMENT_ID}>
            <QueryClientProvider client={queryClient}>
              <FixedThemeProvider theme={THEME}>
                <AuthProvider
                  appHost={HOST}
                  apiHost={API_HOST}
                  apiKey={API_KEY}
                  firebaseProjectId={FIREBASE_PROJECT_ID}
                  firebaseAppId={FIREBASE_APP_ID}
                  firebaseAPIKey={FIREBASE_API_KEY}
                  walletConnectProjectId={WALLETCONNECT_PROJECT_ID}
                  particleProjectId={PARTICLE_PROJECT_ID}
                  particleClientKey={PARTICLE_CLIENT_KEY}
                  particleAppId={PARTICLE_APP_ID}
                  authFallbackPath={AUTH_FALLBACK_PATH}
                  publicRoutesWithAuth={PUBLIC_ROUTES_WITH_AUTH}
                  publicRoutesNoAuth={PUBLIC_ROUTES_NO_AUTH}
                  protectedRoutes={PROTECTED_ROUTES}
                  requireEmail={true}
                >
                  <Component {...pageProps} />
                </AuthProvider>
              </FixedThemeProvider>
            </QueryClientProvider>
          </GoogleAnalyticsProvider>
        </ChakraProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
