const OGMeta = ({
  title: title = "Moongate",
  description:
    description = "Empowering the next generation of memberships and ticketing with dynamic NFTs.",
  url: url = "https://app.moongate.id/",
  image: image = "https://app.moongate.id/images/logo-color.svg",
}: {
  title?: string;
  description?: string;
  url?: string;
  image?: string;
}) => {
  return (
    <>
      <title>{title}</title>
      <meta key="description" name="description" content={description} />
      <meta
        key="og:description"
        property="og:description"
        content={description}
      />
      <meta key="og:url" property="og:url" content={url} />
      <meta key="og:title" property="og:title" content={title} />
      <meta key="og:site_name" property="og:site_name" content={title} />
      <meta key="og:image" property="og:image" content={image} />
      <meta key="og:image:alt" property="og:image:alt" content={title} />

      <meta property="og:type" content="website" />
    </>
  );
};

export default OGMeta;
