import Big from "big.js";
import { isEmpty, isUndefined } from "lodash";

export const round = (
  x: number | string | Big.Big,
  dp = 0,
  mode?: Big.RoundingMode
) => {
  try {
    const b = new Big(String(x));
    return b.round(dp ?? 0, mode).toNumber();
  } catch (error) {
    return Number(x);
  }
};

export const ceil = (x: number | string | Big.Big, dp = 0) => {
  return round(x, dp, Big.roundUp);
};

export const floor = (x: number | string | Big.Big, dp = 0) => {
  return round(x, dp, Big.roundDown);
};

export const sum = (numbers: number[]) => {
  if (isEmpty(numbers) || numbers.length == 0) return 0;
  let s = new Big(0);
  for (const n of numbers) {
    s = s.plus(n);
  }
  return s.toNumber();
};

export const minus = (minuend: number, subtrahend: number) => {
  if (isUndefined(minuend) || isUndefined(subtrahend)) return 0;
  try {
    return new Big(minuend).minus(subtrahend).toNumber();
  } catch (error) {
    return 0;
  }
};

export const times = (multiplicand: number, multiplier: number) => {
  if (isUndefined(multiplicand) || isUndefined(multiplier)) return 0;
  try {
    return new Big(multiplicand).times(multiplier).toNumber();
  } catch (error) {
    return 0;
  }
};

export const div = (dividend: number, divisor: number) => {
  if (isUndefined(dividend) || isUndefined(divisor)) return 0;
  try {
    return new Big(dividend).div(divisor).toNumber();
  } catch (error) {
    return 0;
  }
};

export const pow = (base: number, exponent: number) => {
  if (isUndefined(base) || isUndefined(exponent)) return 0;
  try {
    return new Big(base).pow(exponent).toNumber();
  } catch (error) {
    return 0;
  }
};

export const minBigInt = (...args: bigint[]): bigint => {
  return args.reduce((min, current) => (current < min ? current : min));
};
