import { FirebaseApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const FIREBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || "";
if (!FIREBASE_PROJECT_ID) throw "Missing NEXT_PUBLIC_FIREBASE_PROJECT_ID";

const FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY || "";
if (!FIREBASE_API_KEY) throw "Missing NEXT_PUBLIC_FIREBASE_API_KEY";

const FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID || "";
if (!FIREBASE_APP_ID) throw "Missing NEXT_PUBLIC_FIREBASE_APP_ID";

const FIREBASE_AUTH_DOMAIN =
  process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ||
  `${FIREBASE_PROJECT_ID}.firebaseapp.com`;

let firebaseApp: FirebaseApp;

export const initFirebaseClient = (): FirebaseApp => {
  if (firebaseApp) return firebaseApp;
  firebaseApp = initializeApp({
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECT_ID,
    appId: FIREBASE_APP_ID,
    // messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
    // storageBucket: NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    // databaseURL: NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  });
  return firebaseApp;
};

firebaseApp = initFirebaseClient();
export const firebaseAuth = getAuth(firebaseApp);
