import { ComponentStyleConfig } from "@chakra-ui/react";

export const Tooltip: ComponentStyleConfig = {
  baseStyle: {
    color: "primary.500",
    bg: "primary.50",
    borderRadius: "5px",
    padding: "1rem",
  },
};

export default Tooltip;
