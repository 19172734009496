export const MS_ONE_MINUTE = 60000;
export const MS_ONE_HOUR = 3600000;
export const MS_ONE_DAY = 86400000;
export const MS_END = 9999999999999;

export enum Timezone {
  "Africa/Algiers" = "Africa/Algiers",
  "Atlantic/Cape_Verde" = "Atlantic/Cape_Verde",
  "Africa/Ndjamena" = "Africa/Ndjamena",
  "Africa/Abidjan" = "Africa/Abidjan",
  "Africa/Bamako" = "Africa/Bamako",
  "Africa/Banjul" = "Africa/Banjul",
  "Africa/Conakry" = "Africa/Conakry",
  "Africa/Dakar" = "Africa/Dakar",
  "Africa/Freetown" = "Africa/Freetown",
  "Africa/Lome" = "Africa/Lome",
  "Africa/Nouakchott" = "Africa/Nouakchott",
  "Africa/Ouagadougou" = "Africa/Ouagadougou",
  "Atlantic/St_Helena" = "Atlantic/St_Helena",
  "Africa/Cairo" = "Africa/Cairo",
  "Africa/Accra" = "Africa/Accra",
  "Africa/Bissau" = "Africa/Bissau",
  "Africa/Nairobi" = "Africa/Nairobi",
  "Africa/Addis_Ababa" = "Africa/Addis_Ababa",
  "Africa/Asmara" = "Africa/Asmara",
  "Africa/Dar_es_Salaam" = "Africa/Dar_es_Salaam",
  "Africa/Djibouti" = "Africa/Djibouti",
  "Africa/Kampala" = "Africa/Kampala",
  "Africa/Mogadishu" = "Africa/Mogadishu",
  "Indian/Antananarivo" = "Indian/Antananarivo",
  "Indian/Comoro" = "Indian/Comoro",
  "Indian/Mayotte" = "Indian/Mayotte",
  "Africa/Monrovia" = "Africa/Monrovia",
  "Africa/Tripoli" = "Africa/Tripoli",
  "Indian/Mauritius" = "Indian/Mauritius",
  "Africa/Casablanca" = "Africa/Casablanca",
  "Africa/El_Aaiun" = "Africa/El_Aaiun",
  "Africa/Maputo" = "Africa/Maputo",
  "Africa/Blantyre" = "Africa/Blantyre",
  "Africa/Bujumbura" = "Africa/Bujumbura",
  "Africa/Gaborone" = "Africa/Gaborone",
  "Africa/Harare" = "Africa/Harare",
  "Africa/Kigali" = "Africa/Kigali",
  "Africa/Lubumbashi" = "Africa/Lubumbashi",
  "Africa/Lusaka" = "Africa/Lusaka",
  "Africa/Windhoek" = "Africa/Windhoek",
  "Africa/Lagos" = "Africa/Lagos",
  "Africa/Bangui" = "Africa/Bangui",
  "Africa/Brazzaville" = "Africa/Brazzaville",
  "Africa/Douala" = "Africa/Douala",
  "Africa/Kinshasa" = "Africa/Kinshasa",
  "Africa/Libreville" = "Africa/Libreville",
  "Africa/Luanda" = "Africa/Luanda",
  "Africa/Malabo" = "Africa/Malabo",
  "Africa/Niamey" = "Africa/Niamey",
  "Africa/Porto-Novo" = "Africa/Porto-Novo",
  "Indian/Reunion" = "Indian/Reunion",
  "Africa/Sao_Tome" = "Africa/Sao_Tome",
  "Indian/Mahe" = "Indian/Mahe",
  "Africa/Johannesburg" = "Africa/Johannesburg",
  "Africa/Maseru" = "Africa/Maseru",
  "Africa/Mbabane" = "Africa/Mbabane",
  "Africa/Khartoum" = "Africa/Khartoum",
  "Africa/Juba" = "Africa/Juba",
  "Africa/Tunis" = "Africa/Tunis",
  "Antarctica/Casey" = "Antarctica/Casey",
  "Antarctica/Davis" = "Antarctica/Davis",
  "Antarctica/Mawson" = "Antarctica/Mawson",
  "Indian/Kerguelen" = "Indian/Kerguelen",
  "Antarctica/DumontDUrville" = "Antarctica/DumontDUrville",
  "Antarctica/Syowa" = "Antarctica/Syowa",
  "Antarctica/Troll" = "Antarctica/Troll",
  "Antarctica/Vostok" = "Antarctica/Vostok",
  "Antarctica/Rothera" = "Antarctica/Rothera",
  "Asia/Kabul" = "Asia/Kabul",
  "Asia/Yerevan" = "Asia/Yerevan",
  "Asia/Baku" = "Asia/Baku",
  "Asia/Dhaka" = "Asia/Dhaka",
  "Asia/Thimphu" = "Asia/Thimphu",
  "Indian/Chagos" = "Indian/Chagos",
  "Asia/Brunei" = "Asia/Brunei",
  "Asia/Yangon" = "Asia/Yangon",
  "Asia/Shanghai" = "Asia/Shanghai",
  "Asia/Urumqi" = "Asia/Urumqi",
  "Asia/Hong_Kong" = "Asia/Hong_Kong",
  "Asia/Taipei" = "Asia/Taipei",
  "Asia/Macau" = "Asia/Macau",
  "Asia/Nicosia" = "Asia/Nicosia",
  "Asia/Famagusta" = "Asia/Famagusta",
  "Europe/Nicosia" = "Europe/Nicosia",
  "Asia/Tbilisi" = "Asia/Tbilisi",
  "Asia/Dili" = "Asia/Dili",
  "Asia/Kolkata" = "Asia/Kolkata",
  "Asia/Jakarta" = "Asia/Jakarta",
  "Asia/Pontianak" = "Asia/Pontianak",
  "Asia/Makassar" = "Asia/Makassar",
  "Asia/Jayapura" = "Asia/Jayapura",
  "Asia/Tehran" = "Asia/Tehran",
  "Asia/Baghdad" = "Asia/Baghdad",
  "Asia/Jerusalem" = "Asia/Jerusalem",
  "Asia/Tokyo" = "Asia/Tokyo",
  "Asia/Amman" = "Asia/Amman",
  "Asia/Almaty" = "Asia/Almaty",
  "Asia/Qyzylorda" = "Asia/Qyzylorda",
  "Asia/Qostanay" = "Asia/Qostanay",
  "Asia/Aqtobe" = "Asia/Aqtobe",
  "Asia/Aqtau" = "Asia/Aqtau",
  "Asia/Atyrau" = "Asia/Atyrau",
  "Asia/Oral" = "Asia/Oral",
  "Asia/Bishkek" = "Asia/Bishkek",
  "Asia/Seoul" = "Asia/Seoul",
  "Asia/Pyongyang" = "Asia/Pyongyang",
  "Asia/Beirut" = "Asia/Beirut",
  "Asia/Kuala_Lumpur" = "Asia/Kuala_Lumpur",
  "Asia/Kuching" = "Asia/Kuching",
  "Indian/Maldives" = "Indian/Maldives",
  "Asia/Hovd" = "Asia/Hovd",
  "Asia/Ulaanbaatar" = "Asia/Ulaanbaatar",
  "Asia/Choibalsan" = "Asia/Choibalsan",
  "Asia/Kathmandu" = "Asia/Kathmandu",
  "Asia/Karachi" = "Asia/Karachi",
  "Asia/Gaza" = "Asia/Gaza",
  "Asia/Hebron" = "Asia/Hebron",
  "Asia/Manila" = "Asia/Manila",
  "Asia/Qatar" = "Asia/Qatar",
  "Asia/Bahrain" = "Asia/Bahrain",
  "Asia/Riyadh" = "Asia/Riyadh",
  "Asia/Aden" = "Asia/Aden",
  "Asia/Kuwait" = "Asia/Kuwait",
  "Asia/Singapore" = "Asia/Singapore",
  "Asia/Colombo" = "Asia/Colombo",
  "Asia/Damascus" = "Asia/Damascus",
  "Asia/Dushanbe" = "Asia/Dushanbe",
  "Asia/Bangkok" = "Asia/Bangkok",
  "Asia/Phnom_Penh" = "Asia/Phnom_Penh",
  "Asia/Vientiane" = "Asia/Vientiane",
  "Asia/Ashgabat" = "Asia/Ashgabat",
  "Asia/Dubai" = "Asia/Dubai",
  "Asia/Muscat" = "Asia/Muscat",
  "Asia/Samarkand" = "Asia/Samarkand",
  "Asia/Tashkent" = "Asia/Tashkent",
  "Asia/Ho_Chi_Minh" = "Asia/Ho_Chi_Minh",
  "Australia/Darwin" = "Australia/Darwin",
  "Australia/Perth" = "Australia/Perth",
  "Australia/Eucla" = "Australia/Eucla",
  "Australia/Brisbane" = "Australia/Brisbane",
  "Australia/Lindeman" = "Australia/Lindeman",
  "Australia/Adelaide" = "Australia/Adelaide",
  "Australia/Hobart" = "Australia/Hobart",
  "Australia/Currie" = "Australia/Currie",
  "Australia/Melbourne" = "Australia/Melbourne",
  "Australia/Sydney" = "Australia/Sydney",
  "Australia/Broken_Hill" = "Australia/Broken_Hill",
  "Australia/Lord_Howe" = "Australia/Lord_Howe",
  "Antarctica/Macquarie" = "Antarctica/Macquarie",
  "Indian/Christmas" = "Indian/Christmas",
  "Indian/Cocos" = "Indian/Cocos",
  "Pacific/Fiji" = "Pacific/Fiji",
  "Pacific/Gambier" = "Pacific/Gambier",
  "Pacific/Marquesas" = "Pacific/Marquesas",
  "Pacific/Tahiti" = "Pacific/Tahiti",
  "Pacific/Guam" = "Pacific/Guam",
  "Pacific/Saipan" = "Pacific/Saipan",
  "Pacific/Tarawa" = "Pacific/Tarawa",
  "Pacific/Enderbury" = "Pacific/Enderbury",
  "Pacific/Kiritimati" = "Pacific/Kiritimati",
  "Pacific/Majuro" = "Pacific/Majuro",
  "Pacific/Kwajalein" = "Pacific/Kwajalein",
  "Pacific/Chuuk" = "Pacific/Chuuk",
  "Pacific/Pohnpei" = "Pacific/Pohnpei",
  "Pacific/Kosrae" = "Pacific/Kosrae",
  "Pacific/Nauru" = "Pacific/Nauru",
  "Pacific/Noumea" = "Pacific/Noumea",
  "Pacific/Auckland" = "Pacific/Auckland",
  "Pacific/Chatham" = "Pacific/Chatham",
  "Antarctica/McMurdo" = "Antarctica/McMurdo",
  "Pacific/Rarotonga" = "Pacific/Rarotonga",
  "Pacific/Niue" = "Pacific/Niue",
  "Pacific/Norfolk" = "Pacific/Norfolk",
  "Pacific/Palau" = "Pacific/Palau",
  "Pacific/Port_Moresby" = "Pacific/Port_Moresby",
  "Pacific/Bougainville" = "Pacific/Bougainville",
  "Pacific/Pitcairn" = "Pacific/Pitcairn",
  "Pacific/Pago_Pago" = "Pacific/Pago_Pago",
  "Pacific/Midway" = "Pacific/Midway",
  "Pacific/Apia" = "Pacific/Apia",
  "Pacific/Guadalcanal" = "Pacific/Guadalcanal",
  "Pacific/Fakaofo" = "Pacific/Fakaofo",
  "Pacific/Tongatapu" = "Pacific/Tongatapu",
  "Pacific/Funafuti" = "Pacific/Funafuti",
  "Pacific/Wake" = "Pacific/Wake",
  "Pacific/Efate" = "Pacific/Efate",
  "Pacific/Wallis" = "Pacific/Wallis",
  "Africa/Asmera" = "Africa/Asmera",
  "Africa/Timbuktu" = "Africa/Timbuktu",
  "America/Argentina/ComodRivadavia" = "America/Argentina/ComodRivadavia",
  "America/Atka" = "America/Atka",
  "America/Buenos_Aires" = "America/Buenos_Aires",
  "America/Catamarca" = "America/Catamarca",
  "America/Coral_Harbour" = "America/Coral_Harbour",
  "America/Cordoba" = "America/Cordoba",
  "America/Ensenada" = "America/Ensenada",
  "America/Fort_Wayne" = "America/Fort_Wayne",
  "America/Indianapolis" = "America/Indianapolis",
  "America/Jujuy" = "America/Jujuy",
  "America/Knox_IN" = "America/Knox_IN",
  "America/Louisville" = "America/Louisville",
  "America/Mendoza" = "America/Mendoza",
  "America/Montreal" = "America/Montreal",
  "America/Porto_Acre" = "America/Porto_Acre",
  "America/Rosario" = "America/Rosario",
  "America/Santa_Isabel" = "America/Santa_Isabel",
  "America/Shiprock" = "America/Shiprock",
  "America/Virgin" = "America/Virgin",
  "Antarctica/South_Pole" = "Antarctica/South_Pole",
  "Asia/Ashkhabad" = "Asia/Ashkhabad",
  "Asia/Calcutta" = "Asia/Calcutta",
  "Asia/Chongqing" = "Asia/Chongqing",
  "Asia/Chungking" = "Asia/Chungking",
  "Asia/Dacca" = "Asia/Dacca",
  "Asia/Harbin" = "Asia/Harbin",
  "Asia/Kashgar" = "Asia/Kashgar",
  "Asia/Katmandu" = "Asia/Katmandu",
  "Asia/Macao" = "Asia/Macao",
  "Asia/Rangoon" = "Asia/Rangoon",
  "Asia/Saigon" = "Asia/Saigon",
  "Asia/Tel_Aviv" = "Asia/Tel_Aviv",
  "Asia/Thimbu" = "Asia/Thimbu",
  "Asia/Ujung_Pandang" = "Asia/Ujung_Pandang",
  "Asia/Ulan_Bator" = "Asia/Ulan_Bator",
  "Atlantic/Faeroe" = "Atlantic/Faeroe",
  "Atlantic/Jan_Mayen" = "Atlantic/Jan_Mayen",
  "Australia/ACT" = "Australia/ACT",
  "Australia/Canberra" = "Australia/Canberra",
  "Australia/LHI" = "Australia/LHI",
  "Australia/NSW" = "Australia/NSW",
  "Australia/North" = "Australia/North",
  "Australia/Queensland" = "Australia/Queensland",
  "Australia/South" = "Australia/South",
  "Australia/Tasmania" = "Australia/Tasmania",
  "Australia/Victoria" = "Australia/Victoria",
  "Australia/West" = "Australia/West",
  "Australia/Yancowinna" = "Australia/Yancowinna",
  "Brazil/Acre" = "Brazil/Acre",
  "Brazil/DeNoronha" = "Brazil/DeNoronha",
  "Brazil/East" = "Brazil/East",
  "Brazil/West" = "Brazil/West",
  "Canada/Atlantic" = "Canada/Atlantic",
  "Canada/Central" = "Canada/Central",
  "Canada/Eastern" = "Canada/Eastern",
  "Canada/Mountain" = "Canada/Mountain",
  "Canada/Newfoundland" = "Canada/Newfoundland",
  "Canada/Pacific" = "Canada/Pacific",
  "Canada/Saskatchewan" = "Canada/Saskatchewan",
  "Canada/Yukon" = "Canada/Yukon",
  "Chile/Continental" = "Chile/Continental",
  "Chile/EasterIsland" = "Chile/EasterIsland",
  Cuba = "Cuba",
  Egypt = "Egypt",
  Eire = "Eire",
  "Etc/UCT" = "Etc/UCT",
  "Europe/Belfast" = "Europe/Belfast",
  "Europe/Tiraspol" = "Europe/Tiraspol",
  GB = "GB",
  "GB-Eire" = "GB-Eire",
  "GMT+0" = "GMT+0",
  "GMT-0" = "GMT-0",
  GMT0 = "GMT0",
  Greenwich = "Greenwich",
  Hongkong = "Hongkong",
  Iceland = "Iceland",
  Iran = "Iran",
  Israel = "Israel",
  Jamaica = "Jamaica",
  Japan = "Japan",
  Kwajalein = "Kwajalein",
  Libya = "Libya",
  "Mexico/BajaNorte" = "Mexico/BajaNorte",
  "Mexico/BajaSur" = "Mexico/BajaSur",
  "Mexico/General" = "Mexico/General",
  NZ = "NZ",
  "NZ-CHAT" = "NZ-CHAT",
  Navajo = "Navajo",
  PRC = "PRC",
  "Pacific/Johnston" = "Pacific/Johnston",
  "Pacific/Ponape" = "Pacific/Ponape",
  "Pacific/Samoa" = "Pacific/Samoa",
  "Pacific/Truk" = "Pacific/Truk",
  "Pacific/Yap" = "Pacific/Yap",
  Poland = "Poland",
  Portugal = "Portugal",
  ROC = "ROC",
  ROK = "ROK",
  Singapore = "Singapore",
  Turkey = "Turkey",
  UCT = "UCT",
  "US/Alaska" = "US/Alaska",
  "US/Aleutian" = "US/Aleutian",
  "US/Arizona" = "US/Arizona",
  "US/Central" = "US/Central",
  "US/East-Indiana" = "US/East-Indiana",
  "US/Eastern" = "US/Eastern",
  "US/Hawaii" = "US/Hawaii",
  "US/Indiana-Starke" = "US/Indiana-Starke",
  "US/Michigan" = "US/Michigan",
  "US/Mountain" = "US/Mountain",
  "US/Pacific" = "US/Pacific",
  "US/Samoa" = "US/Samoa",
  UTC = "UTC",
  Universal = "Universal",
  "W-SU" = "W-SU",
  Zulu = "Zulu",
  "Etc/GMT" = "Etc/GMT",
  "Etc/UTC" = "Etc/UTC",
  GMT = "GMT",
  "Etc/Universal" = "Etc/Universal",
  "Etc/Zulu" = "Etc/Zulu",
  "Etc/Greenwich" = "Etc/Greenwich",
  "Etc/GMT-0" = "Etc/GMT-0",
  "Etc/GMT+0" = "Etc/GMT+0",
  "Etc/GMT0" = "Etc/GMT0",
  "Etc/GMT-14" = "Etc/GMT-14",
  "Etc/GMT-13" = "Etc/GMT-13",
  "Etc/GMT-12" = "Etc/GMT-12",
  "Etc/GMT-11" = "Etc/GMT-11",
  "Etc/GMT-10" = "Etc/GMT-10",
  "Etc/GMT-9" = "Etc/GMT-9",
  "Etc/GMT-8" = "Etc/GMT-8",
  "Etc/GMT-7" = "Etc/GMT-7",
  "Etc/GMT-6" = "Etc/GMT-6",
  "Etc/GMT-5" = "Etc/GMT-5",
  "Etc/GMT-4" = "Etc/GMT-4",
  "Etc/GMT-3" = "Etc/GMT-3",
  "Etc/GMT-2" = "Etc/GMT-2",
  "Etc/GMT-1" = "Etc/GMT-1",
  "Etc/GMT+1" = "Etc/GMT+1",
  "Etc/GMT+2" = "Etc/GMT+2",
  "Etc/GMT+3" = "Etc/GMT+3",
  "Etc/GMT+4" = "Etc/GMT+4",
  "Etc/GMT+5" = "Etc/GMT+5",
  "Etc/GMT+6" = "Etc/GMT+6",
  "Etc/GMT+7" = "Etc/GMT+7",
  "Etc/GMT+8" = "Etc/GMT+8",
  "Etc/GMT+9" = "Etc/GMT+9",
  "Etc/GMT+10" = "Etc/GMT+10",
  "Etc/GMT+11" = "Etc/GMT+11",
  "Etc/GMT+12" = "Etc/GMT+12",
  "Europe/London" = "Europe/London",
  "Europe/Jersey" = "Europe/Jersey",
  "Europe/Guernsey" = "Europe/Guernsey",
  "Europe/Isle_of_Man" = "Europe/Isle_of_Man",
  "Europe/Dublin" = "Europe/Dublin",
  WET = "WET",
  CET = "CET",
  MET = "MET",
  EET = "EET",
  "Europe/Tirane" = "Europe/Tirane",
  "Europe/Andorra" = "Europe/Andorra",
  "Europe/Vienna" = "Europe/Vienna",
  "Europe/Minsk" = "Europe/Minsk",
  "Europe/Brussels" = "Europe/Brussels",
  "Europe/Sofia" = "Europe/Sofia",
  "Europe/Prague" = "Europe/Prague",
  "Europe/Copenhagen" = "Europe/Copenhagen",
  "Atlantic/Faroe" = "Atlantic/Faroe",
  "America/Danmarkshavn" = "America/Danmarkshavn",
  "America/Scoresbysund" = "America/Scoresbysund",
  "America/Godthab" = "America/Godthab",
  "America/Thule" = "America/Thule",
  "Europe/Tallinn" = "Europe/Tallinn",
  "Europe/Helsinki" = "Europe/Helsinki",
  "Europe/Mariehamn" = "Europe/Mariehamn",
  "Europe/Paris" = "Europe/Paris",
  "Europe/Berlin" = "Europe/Berlin",
  "Europe/Busingen" = "Europe/Busingen",
  "Europe/Gibraltar" = "Europe/Gibraltar",
  "Europe/Athens" = "Europe/Athens",
  "Europe/Budapest" = "Europe/Budapest",
  "Atlantic/Reykjavik" = "Atlantic/Reykjavik",
  "Europe/Rome" = "Europe/Rome",
  "Europe/Vatican" = "Europe/Vatican",
  "Europe/San_Marino" = "Europe/San_Marino",
  "Europe/Riga" = "Europe/Riga",
  "Europe/Vaduz" = "Europe/Vaduz",
  "Europe/Vilnius" = "Europe/Vilnius",
  "Europe/Luxembourg" = "Europe/Luxembourg",
  "Europe/Malta" = "Europe/Malta",
  "Europe/Chisinau" = "Europe/Chisinau",
  "Europe/Monaco" = "Europe/Monaco",
  "Europe/Amsterdam" = "Europe/Amsterdam",
  "Europe/Oslo" = "Europe/Oslo",
  "Arctic/Longyearbyen" = "Arctic/Longyearbyen",
  "Europe/Warsaw" = "Europe/Warsaw",
  "Europe/Lisbon" = "Europe/Lisbon",
  "Atlantic/Azores" = "Atlantic/Azores",
  "Atlantic/Madeira" = "Atlantic/Madeira",
  "Europe/Bucharest" = "Europe/Bucharest",
  "Europe/Kaliningrad" = "Europe/Kaliningrad",
  "Europe/Moscow" = "Europe/Moscow",
  "Europe/Simferopol" = "Europe/Simferopol",
  "Europe/Astrakhan" = "Europe/Astrakhan",
  "Europe/Volgograd" = "Europe/Volgograd",
  "Europe/Saratov" = "Europe/Saratov",
  "Europe/Kirov" = "Europe/Kirov",
  "Europe/Samara" = "Europe/Samara",
  "Europe/Ulyanovsk" = "Europe/Ulyanovsk",
  "Asia/Yekaterinburg" = "Asia/Yekaterinburg",
  "Asia/Omsk" = "Asia/Omsk",
  "Asia/Barnaul" = "Asia/Barnaul",
  "Asia/Novosibirsk" = "Asia/Novosibirsk",
  "Asia/Tomsk" = "Asia/Tomsk",
  "Asia/Novokuznetsk" = "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk" = "Asia/Krasnoyarsk",
  "Asia/Irkutsk" = "Asia/Irkutsk",
  "Asia/Chita" = "Asia/Chita",
  "Asia/Yakutsk" = "Asia/Yakutsk",
  "Asia/Vladivostok" = "Asia/Vladivostok",
  "Asia/Khandyga" = "Asia/Khandyga",
  "Asia/Sakhalin" = "Asia/Sakhalin",
  "Asia/Magadan" = "Asia/Magadan",
  "Asia/Srednekolymsk" = "Asia/Srednekolymsk",
  "Asia/Ust-Nera" = "Asia/Ust-Nera",
  "Asia/Kamchatka" = "Asia/Kamchatka",
  "Asia/Anadyr" = "Asia/Anadyr",
  "Europe/Belgrade" = "Europe/Belgrade",
  "Europe/Ljubljana" = "Europe/Ljubljana",
  "Europe/Podgorica" = "Europe/Podgorica",
  "Europe/Sarajevo" = "Europe/Sarajevo",
  "Europe/Skopje" = "Europe/Skopje",
  "Europe/Zagreb" = "Europe/Zagreb",
  "Europe/Bratislava" = "Europe/Bratislava",
  "Europe/Madrid" = "Europe/Madrid",
  "Africa/Ceuta" = "Africa/Ceuta",
  "Atlantic/Canary" = "Atlantic/Canary",
  "Europe/Stockholm" = "Europe/Stockholm",
  "Europe/Zurich" = "Europe/Zurich",
  "Europe/Istanbul" = "Europe/Istanbul",
  "Asia/Istanbul" = "Asia/Istanbul",
  "Europe/Kiev" = "Europe/Kiev",
  "Europe/Uzhgorod" = "Europe/Uzhgorod",
  "Europe/Zaporozhye" = "Europe/Zaporozhye",
  Factory = "Factory",
  EST = "EST",
  MST = "MST",
  HST = "HST",
  EST5EDT = "EST5EDT",
  CST6CDT = "CST6CDT",
  MST7MDT = "MST7MDT",
  PST8PDT = "PST8PDT",
  "America/New_York" = "America/New_York",
  "America/Chicago" = "America/Chicago",
  "America/North_Dakota/Center" = "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem" = "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah" = "America/North_Dakota/Beulah",
  "America/Denver" = "America/Denver",
  "America/Los_Angeles" = "America/Los_Angeles",
  "America/Juneau" = "America/Juneau",
  "America/Sitka" = "America/Sitka",
  "America/Metlakatla" = "America/Metlakatla",
  "America/Yakutat" = "America/Yakutat",
  "America/Anchorage" = "America/Anchorage",
  "America/Nome" = "America/Nome",
  "America/Adak" = "America/Adak",
  "Pacific/Honolulu" = "Pacific/Honolulu",
  "America/Phoenix" = "America/Phoenix",
  "America/Boise" = "America/Boise",
  "America/Indiana/Indianapolis" = "America/Indiana/Indianapolis",
  "America/Indiana/Marengo" = "America/Indiana/Marengo",
  "America/Indiana/Vincennes" = "America/Indiana/Vincennes",
  "America/Indiana/Tell_City" = "America/Indiana/Tell_City",
  "America/Indiana/Petersburg" = "America/Indiana/Petersburg",
  "America/Indiana/Knox" = "America/Indiana/Knox",
  "America/Indiana/Winamac" = "America/Indiana/Winamac",
  "America/Indiana/Vevay" = "America/Indiana/Vevay",
  "America/Kentucky/Louisville" = "America/Kentucky/Louisville",
  "America/Kentucky/Monticello" = "America/Kentucky/Monticello",
  "America/Detroit" = "America/Detroit",
  "America/Menominee" = "America/Menominee",
  "America/St_Johns" = "America/St_Johns",
  "America/Goose_Bay" = "America/Goose_Bay",
  "America/Halifax" = "America/Halifax",
  "America/Glace_Bay" = "America/Glace_Bay",
  "America/Moncton" = "America/Moncton",
  "America/Blanc-Sablon" = "America/Blanc-Sablon",
  "America/Toronto" = "America/Toronto",
  "America/Thunder_Bay" = "America/Thunder_Bay",
  "America/Nipigon" = "America/Nipigon",
  "America/Rainy_River" = "America/Rainy_River",
  "America/Atikokan" = "America/Atikokan",
  "America/Winnipeg" = "America/Winnipeg",
  "America/Regina" = "America/Regina",
  "America/Swift_Current" = "America/Swift_Current",
  "America/Edmonton" = "America/Edmonton",
  "America/Vancouver" = "America/Vancouver",
  "America/Dawson_Creek" = "America/Dawson_Creek",
  "America/Fort_Nelson" = "America/Fort_Nelson",
  "America/Creston" = "America/Creston",
  "America/Pangnirtung" = "America/Pangnirtung",
  "America/Iqaluit" = "America/Iqaluit",
  "America/Resolute" = "America/Resolute",
  "America/Rankin_Inlet" = "America/Rankin_Inlet",
  "America/Cambridge_Bay" = "America/Cambridge_Bay",
  "America/Yellowknife" = "America/Yellowknife",
  "America/Inuvik" = "America/Inuvik",
  "America/Whitehorse" = "America/Whitehorse",
  "America/Dawson" = "America/Dawson",
  "America/Cancun" = "America/Cancun",
  "America/Merida" = "America/Merida",
  "America/Matamoros" = "America/Matamoros",
  "America/Monterrey" = "America/Monterrey",
  "America/Mexico_City" = "America/Mexico_City",
  "America/Ojinaga" = "America/Ojinaga",
  "America/Chihuahua" = "America/Chihuahua",
  "America/Hermosillo" = "America/Hermosillo",
  "America/Mazatlan" = "America/Mazatlan",
  "America/Bahia_Banderas" = "America/Bahia_Banderas",
  "America/Tijuana" = "America/Tijuana",
  "America/Nassau" = "America/Nassau",
  "America/Barbados" = "America/Barbados",
  "America/Belize" = "America/Belize",
  "Atlantic/Bermuda" = "Atlantic/Bermuda",
  "America/Costa_Rica" = "America/Costa_Rica",
  "America/Havana" = "America/Havana",
  "America/Santo_Domingo" = "America/Santo_Domingo",
  "America/El_Salvador" = "America/El_Salvador",
  "America/Guatemala" = "America/Guatemala",
  "America/Port-au-Prince" = "America/Port-au-Prince",
  "America/Tegucigalpa" = "America/Tegucigalpa",
  "America/Jamaica" = "America/Jamaica",
  "America/Martinique" = "America/Martinique",
  "America/Managua" = "America/Managua",
  "America/Panama" = "America/Panama",
  "America/Cayman" = "America/Cayman",
  "America/Puerto_Rico" = "America/Puerto_Rico",
  "America/Miquelon" = "America/Miquelon",
  "America/Grand_Turk" = "America/Grand_Turk",
  "US/Pacific-New" = "US/Pacific-New",
  "America/Argentina/Buenos_Aires" = "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba" = "America/Argentina/Cordoba",
  "America/Argentina/Salta" = "America/Argentina/Salta",
  "America/Argentina/Tucuman" = "America/Argentina/Tucuman",
  "America/Argentina/La_Rioja" = "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan" = "America/Argentina/San_Juan",
  "America/Argentina/Jujuy" = "America/Argentina/Jujuy",
  "America/Argentina/Catamarca" = "America/Argentina/Catamarca",
  "America/Argentina/Mendoza" = "America/Argentina/Mendoza",
  "America/Argentina/San_Luis" = "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos" = "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia" = "America/Argentina/Ushuaia",
  "America/Aruba" = "America/Aruba",
  "America/La_Paz" = "America/La_Paz",
  "America/Noronha" = "America/Noronha",
  "America/Belem" = "America/Belem",
  "America/Santarem" = "America/Santarem",
  "America/Fortaleza" = "America/Fortaleza",
  "America/Recife" = "America/Recife",
  "America/Araguaina" = "America/Araguaina",
  "America/Maceio" = "America/Maceio",
  "America/Bahia" = "America/Bahia",
  "America/Sao_Paulo" = "America/Sao_Paulo",
  "America/Campo_Grande" = "America/Campo_Grande",
  "America/Cuiaba" = "America/Cuiaba",
  "America/Porto_Velho" = "America/Porto_Velho",
  "America/Boa_Vista" = "America/Boa_Vista",
  "America/Manaus" = "America/Manaus",
  "America/Eirunepe" = "America/Eirunepe",
  "America/Rio_Branco" = "America/Rio_Branco",
  "America/Santiago" = "America/Santiago",
  "America/Punta_Arenas" = "America/Punta_Arenas",
  "Pacific/Easter" = "Pacific/Easter",
  "Antarctica/Palmer" = "Antarctica/Palmer",
  "America/Bogota" = "America/Bogota",
  "America/Curacao" = "America/Curacao",
  "America/Lower_Princes" = "America/Lower_Princes",
  "America/Kralendijk" = "America/Kralendijk",
  "America/Guayaquil" = "America/Guayaquil",
  "Pacific/Galapagos" = "Pacific/Galapagos",
  "Atlantic/Stanley" = "Atlantic/Stanley",
  "America/Cayenne" = "America/Cayenne",
  "America/Guyana" = "America/Guyana",
  "America/Asuncion" = "America/Asuncion",
  "America/Lima" = "America/Lima",
  "Atlantic/South_Georgia" = "Atlantic/South_Georgia",
  "America/Paramaribo" = "America/Paramaribo",
  "America/Port_of_Spain" = "America/Port_of_Spain",
  "America/Anguilla" = "America/Anguilla",
  "America/Antigua" = "America/Antigua",
  "America/Dominica" = "America/Dominica",
  "America/Grenada" = "America/Grenada",
  "America/Guadeloupe" = "America/Guadeloupe",
  "America/Marigot" = "America/Marigot",
  "America/Montserrat" = "America/Montserrat",
  "America/St_Barthelemy" = "America/St_Barthelemy",
  "America/St_Kitts" = "America/St_Kitts",
  "America/St_Lucia" = "America/St_Lucia",
  "America/St_Thomas" = "America/St_Thomas",
  "America/St_Vincent" = "America/St_Vincent",
  "America/Tortola" = "America/Tortola",
  "America/Montevideo" = "America/Montevideo",
  "America/Caracas" = "America/Caracas",
}

export const TIMEZONE_DISPLAY_NAMES = {
  "Pacific/Niue": "(GMT-11:00) Niue Time",
  "Pacific/Pago_Pago": "(GMT-11:00) Samoa Standard Time",
  "Pacific/Rarotonga": "(GMT-10:00) Cook Islands Standard Time",
  "Pacific/Honolulu": "(GMT-10:00) Hawaii-Aleutian Standard Time",
  "Pacific/Tahiti": "(GMT-10:00) Tahiti Time",
  "Pacific/Marquesas": "(GMT-09:30) Marquesas Time",
  "Pacific/Gambier": "(GMT-09:00) Gambier Time",
  "America/Adak": "(GMT-09:00) Hawaii-Aleutian Time (Adak)",
  "America/Anchorage": "(GMT-08:00) Alaska Time - Anchorage",
  "America/Juneau": "(GMT-08:00) Alaska Time - Juneau",
  "America/Metlakatla": "(GMT-08:00) Alaska Time - Metlakatla",
  "America/Nome": "(GMT-08:00) Alaska Time - Nome",
  "America/Sitka": "(GMT-08:00) Alaska Time - Sitka",
  "America/Yakutat": "(GMT-08:00) Alaska Time - Yakutat",
  "Pacific/Pitcairn": "(GMT-08:00) Pitcairn Time",
  "America/Hermosillo":
    "(GMT-07:00) Mexican Pacific Standard Time - Hermosillo",
  "America/Mazatlan": "(GMT-07:00) Mexican Pacific Standard Time - Mazatlan",
  "America/Dawson_Creek": "(GMT-07:00) Mountain Standard Time - Dawson Creek",
  "America/Fort_Nelson": "(GMT-07:00) Mountain Standard Time - Fort Nelson",
  "America/Phoenix": "(GMT-07:00) Mountain Standard Time - Phoenix",
  "America/Los_Angeles": "(GMT-07:00) Pacific Time - Los Angeles",
  "America/Tijuana": "(GMT-07:00) Pacific Time - Tijuana",
  "America/Vancouver": "(GMT-07:00) Pacific Time - Vancouver",
  "America/Dawson": "(GMT-07:00) Yukon Time - Dawson",
  "America/Whitehorse": "(GMT-07:00) Yukon Time - Whitehorse",
  "America/Bahia_Banderas":
    "(GMT-06:00) Central Standard Time - Bah\u00eda de Banderas",
  "America/Belize": "(GMT-06:00) Central Standard Time - Belize",
  "America/Chihuahua": "(GMT-06:00) Central Standard Time - Chihuahua",
  "America/Costa_Rica": "(GMT-06:00) Central Standard Time - Costa Rica",
  "America/El_Salvador": "(GMT-06:00) Central Standard Time - El Salvador",
  "America/Guatemala": "(GMT-06:00) Central Standard Time - Guatemala",
  "America/Managua": "(GMT-06:00) Central Standard Time - Managua",
  "America/Merida": "(GMT-06:00) Central Standard Time - M\u00e9rida",
  "America/Mexico_City": "(GMT-06:00) Central Standard Time - Mexico City",
  "America/Monterrey": "(GMT-06:00) Central Standard Time - Monterrey",
  "America/Regina": "(GMT-06:00) Central Standard Time - Regina",
  "America/Swift_Current": "(GMT-06:00) Central Standard Time - Swift Current",
  "America/Tegucigalpa": "(GMT-06:00) Central Standard Time - Tegucigalpa",
  "Pacific/Easter": "(GMT-06:00) Easter Island Time",
  "Pacific/Galapagos": "(GMT-06:00) Galapagos Time",
  "America/Boise": "(GMT-06:00) Mountain Time - Boise",
  "America/Cambridge_Bay": "(GMT-06:00) Mountain Time - Cambridge Bay",
  "America/Ciudad_Juarez": "(GMT-06:00) Mountain Time - Ciudad Ju\u00e1rez",
  "America/Denver": "(GMT-06:00) Mountain Time - Denver",
  "America/Edmonton": "(GMT-06:00) Mountain Time - Edmonton",
  "America/Inuvik": "(GMT-06:00) Mountain Time - Inuvik",
  "America/Yellowknife": "(GMT-06:00) Mountain Time - Yellowknife",
  "America/Eirunepe": "(GMT-05:00) Acre Standard Time - Eirunepe",
  "America/Rio_Branco": "(GMT-05:00) Acre Standard Time - Rio Branco",
  "America/North_Dakota/Beulah":
    "(GMT-05:00) Central Time - Beulah, North Dakota",
  "America/North_Dakota/Center":
    "(GMT-05:00) Central Time - Center, North Dakota",
  "America/Chicago": "(GMT-05:00) Central Time - Chicago",
  "America/Indiana/Knox": "(GMT-05:00) Central Time - Knox, Indiana",
  "America/Matamoros": "(GMT-05:00) Central Time - Matamoros",
  "America/Menominee": "(GMT-05:00) Central Time - Menominee",
  "America/North_Dakota/New_Salem":
    "(GMT-05:00) Central Time - New Salem, North Dakota",
  "America/Ojinaga": "(GMT-05:00) Central Time - Ojinaga",
  "America/Rainy_River": "(GMT-05:00) Central Time - Rainy River",
  "America/Rankin_Inlet": "(GMT-05:00) Central Time - Rankin Inlet",
  "America/Resolute": "(GMT-05:00) Central Time - Resolute",
  "America/Indiana/Tell_City": "(GMT-05:00) Central Time - Tell City, Indiana",
  "America/Winnipeg": "(GMT-05:00) Central Time - Winnipeg",
  "America/Bogota": "(GMT-05:00) Colombia Standard Time",
  "America/Cancun": "(GMT-05:00) Eastern Standard Time - Canc\u00fan",
  "America/Jamaica": "(GMT-05:00) Eastern Standard Time - Jamaica",
  "America/Panama": "(GMT-05:00) Eastern Standard Time - Panama",
  "America/Guayaquil": "(GMT-05:00) Ecuador Time",
  "America/Lima": "(GMT-05:00) Peru Standard Time",
  "America/Boa_Vista": "(GMT-04:00) Amazon Standard Time - Boa Vista",
  "America/Campo_Grande": "(GMT-04:00) Amazon Standard Time - Campo Grande",
  "America/Cuiaba": "(GMT-04:00) Amazon Standard Time - Cuiaba",
  "America/Manaus": "(GMT-04:00) Amazon Standard Time - Manaus",
  "America/Porto_Velho": "(GMT-04:00) Amazon Standard Time - Porto Velho",
  "America/Barbados": "(GMT-04:00) Atlantic Standard Time - Barbados",
  "America/Martinique": "(GMT-04:00) Atlantic Standard Time - Martinique",
  "America/Puerto_Rico": "(GMT-04:00) Atlantic Standard Time - Puerto Rico",
  "America/Santo_Domingo": "(GMT-04:00) Atlantic Standard Time - Santo Domingo",
  "America/La_Paz": "(GMT-04:00) Bolivia Time",
  "America/Santiago": "(GMT-04:00) Chile Time",
  "America/Havana": "(GMT-04:00) Cuba Time",
  "America/Detroit": "(GMT-04:00) Eastern Time - Detroit",
  "America/Grand_Turk": "(GMT-04:00) Eastern Time - Grand Turk",
  "America/Indiana/Indianapolis": "(GMT-04:00) Eastern Time - Indianapolis",
  "America/Iqaluit": "(GMT-04:00) Eastern Time - Iqaluit",
  "America/Kentucky/Louisville": "(GMT-04:00) Eastern Time - Louisville",
  "America/Indiana/Marengo": "(GMT-04:00) Eastern Time - Marengo, Indiana",
  "America/Kentucky/Monticello":
    "(GMT-04:00) Eastern Time - Monticello, Kentucky",
  "America/New_York": "(GMT-04:00) Eastern Time - New York",
  "America/Nipigon": "(GMT-04:00) Eastern Time - Nipigon",
  "America/Pangnirtung": "(GMT-04:00) Eastern Time - Pangnirtung",
  "America/Indiana/Petersburg":
    "(GMT-04:00) Eastern Time - Petersburg, Indiana",
  "America/Port-au-Prince": "(GMT-04:00) Eastern Time - Port-au-Prince",
  "America/Thunder_Bay": "(GMT-04:00) Eastern Time - Thunder Bay",
  "America/Toronto": "(GMT-04:00) Eastern Time - Toronto",
  "America/Indiana/Vevay": "(GMT-04:00) Eastern Time - Vevay, Indiana",
  "America/Indiana/Vincennes": "(GMT-04:00) Eastern Time - Vincennes, Indiana",
  "America/Indiana/Winamac": "(GMT-04:00) Eastern Time - Winamac, Indiana",
  "America/Guyana": "(GMT-04:00) Guyana Time",
  "America/Asuncion": "(GMT-04:00) Paraguay Time",
  "America/Caracas": "(GMT-04:00) Venezuela Time",
  "America/Argentina/Buenos_Aires":
    "(GMT-03:00) Argentina Standard Time - Buenos Aires",
  "America/Argentina/Catamarca":
    "(GMT-03:00) Argentina Standard Time - Catamarca",
  "America/Argentina/Cordoba": "(GMT-03:00) Argentina Standard Time - Cordoba",
  "America/Argentina/Jujuy": "(GMT-03:00) Argentina Standard Time - Jujuy",
  "America/Argentina/La_Rioja":
    "(GMT-03:00) Argentina Standard Time - La Rioja",
  "America/Argentina/Mendoza": "(GMT-03:00) Argentina Standard Time - Mendoza",
  "America/Argentina/Rio_Gallegos":
    "(GMT-03:00) Argentina Standard Time - Rio Gallegos",
  "America/Argentina/Salta": "(GMT-03:00) Argentina Standard Time - Salta",
  "America/Argentina/San_Juan":
    "(GMT-03:00) Argentina Standard Time - San Juan",
  "America/Argentina/San_Luis":
    "(GMT-03:00) Argentina Standard Time - San Luis",
  "America/Argentina/Tucuman": "(GMT-03:00) Argentina Standard Time - Tucuman",
  "America/Argentina/Ushuaia": "(GMT-03:00) Argentina Standard Time - Ushuaia",
  "Atlantic/Bermuda": "(GMT-03:00) Atlantic Time - Bermuda",
  "America/Glace_Bay": "(GMT-03:00) Atlantic Time - Glace Bay",
  "America/Goose_Bay": "(GMT-03:00) Atlantic Time - Goose Bay",
  "America/Halifax": "(GMT-03:00) Atlantic Time - Halifax",
  "America/Moncton": "(GMT-03:00) Atlantic Time - Moncton",
  "America/Thule": "(GMT-03:00) Atlantic Time - Thule",
  "America/Araguaina": "(GMT-03:00) Brasilia Standard Time - Araguaina",
  "America/Bahia": "(GMT-03:00) Brasilia Standard Time - Bahia",
  "America/Belem": "(GMT-03:00) Brasilia Standard Time - Belem",
  "America/Fortaleza": "(GMT-03:00) Brasilia Standard Time - Fortaleza",
  "America/Maceio": "(GMT-03:00) Brasilia Standard Time - Maceio",
  "America/Recife": "(GMT-03:00) Brasilia Standard Time - Recife",
  "America/Santarem": "(GMT-03:00) Brasilia Standard Time - Santarem",
  "America/Sao_Paulo": "(GMT-03:00) Brasilia Standard Time - Sao Paulo",
  "Atlantic/Stanley": "(GMT-03:00) Falkland Islands Standard Time",
  "America/Cayenne": "(GMT-03:00) French Guiana Time",
  "Antarctica/Palmer": "(GMT-03:00) Palmer Time",
  "America/Punta_Arenas": "(GMT-03:00) Punta Arenas Time",
  "Antarctica/Rothera": "(GMT-03:00) Rothera Time",
  "America/Paramaribo": "(GMT-03:00) Suriname Time",
  "America/Montevideo": "(GMT-03:00) Uruguay Standard Time",
  "America/St_Johns": "(GMT-02:30) Newfoundland Time",
  "America/Noronha": "(GMT-02:00) Fernando de Noronha Standard Time",
  "Atlantic/South_Georgia": "(GMT-02:00) South Georgia Time",
  "America/Miquelon": "(GMT-02:00) St. Pierre & Miquelon Time",
  "America/Nuuk": "(GMT-02:00) West Greenland Time",
  "Atlantic/Cape_Verde": "(GMT-01:00) Cape Verde Standard Time",
  "Atlantic/Azores": "(GMT+00:00) Azores Time",
  UTC: "(GMT+00:00) Coordinated Universal Time",
  "America/Scoresbysund": "(GMT+00:00) East Greenland Time",
  "Etc/GMT": "(GMT+00:00) Greenwich Mean Time",
  "Africa/Abidjan": "(GMT+00:00) Greenwich Mean Time - Abidjan",
  "Africa/Bissau": "(GMT+00:00) Greenwich Mean Time - Bissau",
  "America/Danmarkshavn": "(GMT+00:00) Greenwich Mean Time - Danmarkshavn",
  "Africa/Monrovia": "(GMT+00:00) Greenwich Mean Time - Monrovia",
  "Atlantic/Reykjavik": "(GMT+00:00) Greenwich Mean Time - Reykjavik",
  "Africa/Sao_Tome": "(GMT+00:00) Greenwich Mean Time - S\u00e3o Tom\u00e9",
  "Africa/Algiers": "(GMT+01:00) Central European Standard Time - Algiers",
  "Africa/Tunis": "(GMT+01:00) Central European Standard Time - Tunis",
  "Europe/Dublin": "(GMT+01:00) Ireland Time",
  "Africa/Casablanca": "(GMT+01:00) Morocco Time",
  "Europe/London": "(GMT+01:00) United Kingdom Time",
  "Africa/Lagos": "(GMT+01:00) West Africa Standard Time - Lagos",
  "Africa/Ndjamena": "(GMT+01:00) West Africa Standard Time - Ndjamena",
  "Atlantic/Canary": "(GMT+01:00) Western European Time - Canary",
  "Atlantic/Faroe": "(GMT+01:00) Western European Time - Faroe",
  "Europe/Lisbon": "(GMT+01:00) Western European Time - Lisbon",
  "Atlantic/Madeira": "(GMT+01:00) Western European Time - Madeira",
  "Africa/El_Aaiun": "(GMT+01:00) Western Sahara Time",
  "Africa/Juba": "(GMT+02:00) Central Africa Time - Juba",
  "Africa/Khartoum": "(GMT+02:00) Central Africa Time - Khartoum",
  "Africa/Maputo": "(GMT+02:00) Central Africa Time - Maputo",
  "Africa/Windhoek": "(GMT+02:00) Central Africa Time - Windhoek",
  "Europe/Amsterdam": "(GMT+02:00) Central European Time - Amsterdam",
  "Europe/Andorra": "(GMT+02:00) Central European Time - Andorra",
  "Europe/Belgrade": "(GMT+02:00) Central European Time - Belgrade",
  "Europe/Berlin": "(GMT+02:00) Central European Time - Berlin",
  "Europe/Brussels": "(GMT+02:00) Central European Time - Brussels",
  "Europe/Budapest": "(GMT+02:00) Central European Time - Budapest",
  "Africa/Ceuta": "(GMT+02:00) Central European Time - Ceuta",
  "Europe/Copenhagen": "(GMT+02:00) Central European Time - Copenhagen",
  "Europe/Gibraltar": "(GMT+02:00) Central European Time - Gibraltar",
  "Europe/Luxembourg": "(GMT+02:00) Central European Time - Luxembourg",
  "Europe/Madrid": "(GMT+02:00) Central European Time - Madrid",
  "Europe/Malta": "(GMT+02:00) Central European Time - Malta",
  "Europe/Monaco": "(GMT+02:00) Central European Time - Monaco",
  "Europe/Oslo": "(GMT+02:00) Central European Time - Oslo",
  "Europe/Paris": "(GMT+02:00) Central European Time - Paris",
  "Europe/Prague": "(GMT+02:00) Central European Time - Prague",
  "Europe/Rome": "(GMT+02:00) Central European Time - Rome",
  "Europe/Stockholm": "(GMT+02:00) Central European Time - Stockholm",
  "Europe/Tirane": "(GMT+02:00) Central European Time - Tirane",
  "Europe/Vienna": "(GMT+02:00) Central European Time - Vienna",
  "Europe/Warsaw": "(GMT+02:00) Central European Time - Warsaw",
  "Europe/Zurich": "(GMT+02:00) Central European Time - Zurich",
  "Europe/Kaliningrad":
    "(GMT+02:00) Eastern European Standard Time - Kaliningrad",
  "Africa/Tripoli": "(GMT+02:00) Eastern European Standard Time - Tripoli",
  "Africa/Johannesburg": "(GMT+02:00) South Africa Standard Time",
  "Antarctica/Troll": "(GMT+02:00) Troll Time",
  "Asia/Baghdad": "(GMT+03:00) Arabian Standard Time - Baghdad",
  "Asia/Qatar": "(GMT+03:00) Arabian Standard Time - Qatar",
  "Asia/Riyadh": "(GMT+03:00) Arabian Standard Time - Riyadh",
  "Africa/Nairobi": "(GMT+03:00) East Africa Time",
  "Europe/Athens": "(GMT+03:00) Eastern European Time - Athens",
  "Asia/Beirut": "(GMT+03:00) Eastern European Time - Beirut",
  "Europe/Bucharest": "(GMT+03:00) Eastern European Time - Bucharest",
  "Africa/Cairo": "(GMT+03:00) Eastern European Time - Cairo",
  "Europe/Chisinau": "(GMT+03:00) Eastern European Time - Chisinau",
  "Asia/Gaza": "(GMT+03:00) Eastern European Time - Gaza",
  "Asia/Hebron": "(GMT+03:00) Eastern European Time - Hebron",
  "Europe/Helsinki": "(GMT+03:00) Eastern European Time - Helsinki",
  "Europe/Kiev": "(GMT+03:00) Eastern European Time - Kyiv",
  "Asia/Nicosia": "(GMT+03:00) Eastern European Time - Nicosia",
  "Europe/Riga": "(GMT+03:00) Eastern European Time - Riga",
  "Europe/Sofia": "(GMT+03:00) Eastern European Time - Sofia",
  "Europe/Tallinn": "(GMT+03:00) Eastern European Time - Tallinn",
  "Europe/Uzhgorod": "(GMT+03:00) Eastern European Time - Uzhhorod",
  "Europe/Vilnius": "(GMT+03:00) Eastern European Time - Vilnius",
  "Europe/Zaporozhye": "(GMT+03:00) Eastern European Time - Zaporozhye",
  "Asia/Famagusta": "(GMT+03:00) Famagusta Time",
  "Asia/Jerusalem": "(GMT+03:00) Israel Time",
  "Asia/Amman": "(GMT+03:00) Jordan Time",
  "Europe/Kirov": "(GMT+03:00) Kirov Time",
  "Europe/Minsk": "(GMT+03:00) Moscow Standard Time - Minsk",
  "Europe/Moscow": "(GMT+03:00) Moscow Standard Time - Moscow",
  "Europe/Simferopol": "(GMT+03:00) Moscow Standard Time - Simferopol",
  "Asia/Damascus": "(GMT+03:00) Syria Time",
  "Europe/Istanbul": "(GMT+03:00) T\u00fcrkiye Time",
  "Europe/Volgograd": "(GMT+03:00) Volgograd Standard Time",
  "Asia/Tehran": "(GMT+03:30) Iran Standard Time",
  "Asia/Yerevan": "(GMT+04:00) Armenia Standard Time",
  "Europe/Astrakhan": "(GMT+04:00) Astrakhan Time",
  "Asia/Baku": "(GMT+04:00) Azerbaijan Standard Time",
  "Asia/Tbilisi": "(GMT+04:00) Georgia Standard Time",
  "Asia/Dubai": "(GMT+04:00) Gulf Standard Time",
  "Indian/Mauritius": "(GMT+04:00) Mauritius Standard Time",
  "Indian/Reunion": "(GMT+04:00) R\u00e9union Time",
  "Europe/Samara": "(GMT+04:00) Samara Standard Time",
  "Europe/Saratov": "(GMT+04:00) Saratov Time",
  "Indian/Mahe": "(GMT+04:00) Seychelles Time",
  "Europe/Ulyanovsk": "(GMT+04:00) Ulyanovsk Time",
  "Asia/Kabul": "(GMT+04:30) Afghanistan Time",
  "Indian/Kerguelen": "(GMT+05:00) French Southern & Antarctic Time",
  "Indian/Maldives": "(GMT+05:00) Maldives Time",
  "Antarctica/Mawson": "(GMT+05:00) Mawson Time",
  "Asia/Karachi": "(GMT+05:00) Pakistan Standard Time",
  "Asia/Dushanbe": "(GMT+05:00) Tajikistan Time",
  "Asia/Ashgabat": "(GMT+05:00) Turkmenistan Standard Time",
  "Asia/Samarkand": "(GMT+05:00) Uzbekistan Standard Time - Samarkand",
  "Asia/Tashkent": "(GMT+05:00) Uzbekistan Standard Time - Tashkent",
  "Asia/Aqtau": "(GMT+05:00) West Kazakhstan Time - Aqtau",
  "Asia/Aqtobe": "(GMT+05:00) West Kazakhstan Time - Aqtobe",
  "Asia/Atyrau": "(GMT+05:00) West Kazakhstan Time - Atyrau",
  "Asia/Oral": "(GMT+05:00) West Kazakhstan Time - Oral",
  "Asia/Qyzylorda": "(GMT+05:00) West Kazakhstan Time - Qyzylorda",
  "Asia/Yekaterinburg": "(GMT+05:00) Yekaterinburg Standard Time",
  "Asia/Colombo": "(GMT+05:30) India Standard Time - Colombo",
  "Asia/Kolkata": "(GMT+05:30) India Standard Time - Kolkata",
  "Asia/Kathmandu": "(GMT+05:45) Nepal Time",
  "Asia/Dhaka": "(GMT+06:00) Bangladesh Standard Time",
  "Asia/Thimphu": "(GMT+06:00) Bhutan Time",
  "Asia/Almaty": "(GMT+06:00) East Kazakhstan Time - Almaty",
  "Asia/Qostanay": "(GMT+06:00) East Kazakhstan Time - Kostanay",
  "Indian/Chagos": "(GMT+06:00) Indian Ocean Time",
  "Asia/Bishkek": "(GMT+06:00) Kyrgyzstan Time",
  "Asia/Omsk": "(GMT+06:00) Omsk Standard Time",
  "Asia/Urumqi": "(GMT+06:00) Urumqi Time",
  "Antarctica/Vostok": "(GMT+06:00) Vostok Time",
  "Indian/Cocos": "(GMT+06:30) Cocos Islands Time",
  "Asia/Yangon": "(GMT+06:30) Myanmar Time",
  "Asia/Barnaul": "(GMT+07:00) Barnaul Time",
  "Indian/Christmas": "(GMT+07:00) Christmas Island Time",
  "Antarctica/Davis": "(GMT+07:00) Davis Time",
  "Asia/Hovd": "(GMT+07:00) Hovd Standard Time",
  "Asia/Bangkok": "(GMT+07:00) Indochina Time - Bangkok",
  "Asia/Ho_Chi_Minh": "(GMT+07:00) Indochina Time - Ho Chi Minh City",
  "Asia/Krasnoyarsk": "(GMT+07:00) Krasnoyarsk Standard Time - Krasnoyarsk",
  "Asia/Novokuznetsk": "(GMT+07:00) Krasnoyarsk Standard Time - Novokuznetsk",
  "Asia/Novosibirsk": "(GMT+07:00) Novosibirsk Standard Time",
  "Asia/Tomsk": "(GMT+07:00) Tomsk Time",
  "Asia/Jakarta": "(GMT+07:00) Western Indonesia Time - Jakarta",
  "Asia/Pontianak": "(GMT+07:00) Western Indonesia Time - Pontianak",
  "Australia/Perth": "(GMT+08:00) Australian Western Standard Time",
  "Asia/Brunei": "(GMT+08:00) Brunei Darussalam Time",
  "Asia/Makassar": "(GMT+08:00) Central Indonesia Time",
  "Asia/Macau": "(GMT+08:00) China Standard Time - Macao",
  "Asia/Shanghai": "(GMT+08:00) China Standard Time - Shanghai",
  "Asia/Hong_Kong": "(GMT+08:00) Hong Kong Standard Time",
  "Asia/Irkutsk": "(GMT+08:00) Irkutsk Standard Time",
  "Asia/Kuala_Lumpur": "(GMT+08:00) Malaysia Time - Kuala Lumpur",
  "Asia/Kuching": "(GMT+08:00) Malaysia Time - Kuching",
  "Asia/Manila": "(GMT+08:00) Philippine Standard Time",
  "Asia/Singapore": "(GMT+08:00) Singapore Standard Time",
  "Asia/Taipei": "(GMT+08:00) Taipei Standard Time",
  "Asia/Choibalsan": "(GMT+08:00) Ulaanbaatar Standard Time - Choibalsan",
  "Asia/Ulaanbaatar": "(GMT+08:00) Ulaanbaatar Standard Time - Ulaanbaatar",
  "Australia/Eucla": "(GMT+08:45) Australian Central Western Standard Time",
  "Asia/Dili": "(GMT+09:00) East Timor Time",
  "Asia/Jayapura": "(GMT+09:00) Eastern Indonesia Time",
  "Asia/Tokyo": "(GMT+09:00) Japan Standard Time",
  "Asia/Pyongyang": "(GMT+09:00) Korean Standard Time - Pyongyang",
  "Asia/Seoul": "(GMT+09:00) Korean Standard Time - Seoul",
  "Pacific/Palau": "(GMT+09:00) Palau Time",
  "Asia/Chita": "(GMT+09:00) Yakutsk Standard Time - Chita",
  "Asia/Khandyga": "(GMT+09:00) Yakutsk Standard Time - Khandyga",
  "Asia/Yakutsk": "(GMT+09:00) Yakutsk Standard Time - Yakutsk",
  "Australia/Darwin": "(GMT+09:30) Australian Central Standard Time",
  "Australia/Adelaide": "(GMT+09:30) Central Australia Time - Adelaide",
  "Australia/Broken_Hill": "(GMT+09:30) Central Australia Time - Broken Hill",
  "Australia/Brisbane":
    "(GMT+10:00) Australian Eastern Standard Time - Brisbane",
  "Australia/Lindeman":
    "(GMT+10:00) Australian Eastern Standard Time - Lindeman",
  "Pacific/Guam": "(GMT+10:00) Chamorro Standard Time",
  "Pacific/Chuuk": "(GMT+10:00) Chuuk Time",
  "Australia/Hobart": "(GMT+10:00) Eastern Australia Time - Hobart",
  "Antarctica/Macquarie": "(GMT+10:00) Eastern Australia Time - Macquarie",
  "Australia/Melbourne": "(GMT+10:00) Eastern Australia Time - Melbourne",
  "Australia/Sydney": "(GMT+10:00) Eastern Australia Time - Sydney",
  "Pacific/Port_Moresby": "(GMT+10:00) Papua New Guinea Time",
  "Asia/Ust-Nera": "(GMT+10:00) Vladivostok Standard Time - Ust-Nera",
  "Asia/Vladivostok": "(GMT+10:00) Vladivostok Standard Time - Vladivostok",
  "Australia/Lord_Howe": "(GMT+10:30) Lord Howe Time",
  "Pacific/Bougainville": "(GMT+11:00) Bougainville Time",
  "Antarctica/Casey": "(GMT+11:00) Casey Time",
  "Pacific/Kosrae": "(GMT+11:00) Kosrae Time",
  "Asia/Magadan": "(GMT+11:00) Magadan Standard Time",
  "Pacific/Noumea": "(GMT+11:00) New Caledonia Standard Time",
  "Pacific/Norfolk": "(GMT+11:00) Norfolk Island Time",
  "Pacific/Pohnpei": "(GMT+11:00) Ponape Time",
  "Asia/Sakhalin": "(GMT+11:00) Sakhalin Standard Time",
  "Pacific/Guadalcanal": "(GMT+11:00) Solomon Islands Time",
  "Asia/Srednekolymsk": "(GMT+11:00) Srednekolymsk Time",
  "Asia/Anadyr": "(GMT+12:00) Anadyr Standard Time",
  "Pacific/Fiji": "(GMT+12:00) Fiji Standard Time",
  "Pacific/Tarawa": "(GMT+12:00) Gilbert Islands Time",
  "Pacific/Kwajalein": "(GMT+12:00) Marshall Islands Time - Kwajalein",
  "Pacific/Majuro": "(GMT+12:00) Marshall Islands Time - Majuro",
  "Pacific/Nauru": "(GMT+12:00) Nauru Time",
  "Pacific/Auckland": "(GMT+12:00) New Zealand Time",
  "Asia/Kamchatka": "(GMT+12:00) Petropavlovsk-Kamchatski Standard Time",
  "Pacific/Funafuti": "(GMT+12:00) Tuvalu Time",
  "Pacific/Wake": "(GMT+12:00) Wake Island Time",
  "Pacific/Wallis": "(GMT+12:00) Wallis & Futuna Time",
  "Pacific/Chatham": "(GMT+12:45) Chatham Time",
  "Pacific/Apia": "(GMT+13:00) Apia Standard Time",
  "Pacific/Kanton": "(GMT+13:00) Phoenix Islands Time",
  "Pacific/Fakaofo": "(GMT+13:00) Tokelau Time",
  "Pacific/Tongatapu": "(GMT+13:00) Tonga Standard Time",
  "Pacific/Kiritimati": "(GMT+14:00) Line Islands Time",
};
