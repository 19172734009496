import { Moonpass } from "../moonpass";
import { NFTOwnership } from "../nft";
import { Transaction } from "../payment";
import { normalizeId, toJSON, unique } from "../utils";
import { NFTConstraintResult } from "./constraint/nft";
import { NFTEventUsage } from "./usage";

export enum VerificationError {
  INVALID = "invalid_code",
  NOT_ELIGIBLE = "not_eligible",
  REGISTRATION_REQUIRED = "registration_required",
  NFT_HOLDER_ONLY = "nft_holder_only",
  EVENT_NOT_STARTED = "event_not_started",
  EVENT_ENDED = "event_ended",
  EXPIRED = "code_expired",
  MISSING_NFT_COLLECTION = "missing_nft_collection",
  UNKNOWN_EVENT = "unknown_event",
  UNKNOWN_NFT_COLLECTION = "unknown_nft_collection",
  UNKNOWN_TOKEN_ADDRESS = "unknown_token_address",
  USAGE_LIMIT_EXCEEDED = "usage_limit_exceeded",
  INVALID_SESSION = "invalid_session",
  INVALID_WALLET = "invalid_wallet",
  USAGE_RECORD_ERROR = "error_saving_record",
}

export interface VerificationToken {
  i: string; // identifier
  c: "i" | "w"; // i for userId, w for wallet address
  t?: number; // timestamp
  s?: string; // session token
}

export interface NFTEventTierVerificationResultParam
  extends Partial<NFTEventTierVerificationResult> {
  eventId: string;
  tierId: string;
  tierName: string;
  identifiers: string[];
}

export class NFTEventTierVerificationResult
  implements NFTEventTierVerificationResultParam
{
  eventId: string;
  tierId: string;
  tierName: string;
  identifiers: string[];
  eligible: boolean;
  validCount: number;
  usageCount: number;
  usageLimit: number;
  userId: string;
  wallets: string[];
  email: string;
  validNFTCollections?: NFTConstraintResult;
  validWhitelists?: {
    quantity: number;
    whitelists: Transaction[];
  };
  previousUsages?: NFTEventUsage[];

  constructor(params: NFTEventTierVerificationResultParam) {
    this.eventId = params.eventId;
    this.tierId = params.tierId;
    this.tierName = params.tierName || "";
    this.eligible = !!params.eligible;
    this.validCount = params.validCount ?? (this.eligible ? 1 : 0);
    this.usageCount = params.usageCount ?? 0;
    this.usageLimit = params.usageLimit ?? 1;
    this.userId = params.userId || "";
    this.email = params.email || "";
    this.wallets = params.wallets || [];
    this.identifiers = unique(
      [
        ...(params.identifiers || []),
        ...this.wallets,
        this.userId,
        this.email,
      ].map((i) => normalizeId(i))
    );
    if (params.validNFTCollections)
      this.validNFTCollections = params.validNFTCollections;
    if (params.validWhitelists) this.validWhitelists = params.validWhitelists;
    if (params.previousUsages) this.previousUsages = params.previousUsages;
  }

  get json() {
    return toJSON(this);
  }
}

export interface NFTEventEligibilityResult {
  nfts: NFTOwnership[];
  whitelists: Transaction[];
  moonpasses: Moonpass[];
}
