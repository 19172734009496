import { isEmpty, sum } from "lodash";
import { Logic } from "../../misc";
import { Network } from "../../network";
import { NFT, NFTOwnership, toNFTId } from "../../nft";
import { compareText, normalizeId, toJSON, unique } from "../../utils";

export interface NFTConstraintCollectionConfigParam {
  id?: string;
  network: Network;
  collectionId: string;
  attributes?: { traitType: string; values: string[] }[];
}

export class NFTConstraintCollectionConfig
  implements NFTConstraintCollectionConfigParam
{
  id: string;
  network: Network;
  collectionId: string;
  attributes?: { traitType: string; values: string[] }[]; // OR relation across traitType

  constructor(params: NFTConstraintCollectionConfigParam) {
    this.network = params.network;
    this.collectionId = normalizeId(params.collectionId);
    this.id = toNFTId([this.network, this.collectionId]);
    if (params.attributes && !isEmpty(params.attributes))
      this.attributes = params.attributes.filter(
        (attribute) => attribute.traitType && attribute.values
      );
  }

  validate(nft: NFT | NFTOwnership) {
    if (nft.network != this.network) return false;
    if (nft.collectionId != this.collectionId) return false;
    if (this.attributes && this.attributes.length > 0) {
      let valid = false;
      for (const attribute of this.attributes) {
        if (!attribute.traitType || isEmpty(attribute.values)) continue;
        // OR relation across traitType
        const _nftTrait = nft.attributes.find((a) =>
          compareText(a.traitType, attribute.traitType)
        );
        if (
          _nftTrait &&
          attribute.values
            .map((v) => normalizeId(v))
            .includes(normalizeId(String(_nftTrait.value)))
        )
          return true;
      }
      return valid;
    }
    return true;
  }
}

export interface NFTConstraintResult {
  collections: NFTConstraintResultCollection[];
  quantity: number;
}

export interface NFTConstraintResultCollection {
  network: Network;
  collectionId: string;
  tokens: string[];
  nfts: NFTOwnership[];
}
export interface NFTConstraintParam
  extends Omit<Partial<NFTConstraint>, "collections"> {
  collections?: NFTConstraintCollectionConfigParam[];
}

export class NFTConstraint implements NFTConstraintParam {
  collections: NFTConstraintCollectionConfig[];
  logic: Logic;

  constructor(params?: NFTConstraintParam) {
    this.collections =
      params?.collections && params.collections.length > 0
        ? params.collections
            .filter((c) => c && c.network && c.collectionId)
            .map((c) => new NFTConstraintCollectionConfig(c))
        : [];
    this.logic = params?.logic || Logic.AND;
  }

  get json() {
    return toJSON(this);
  }

  validate(nfts: NFTOwnership[]) {
    const results: NFTConstraintResultCollection[] = [];
    if (isEmpty(this.collections)) return [];
    for (const collection of this.collections) {
      const _nfts = nfts.filter((n) => collection.validate(n));
      const tokens = unique(_nfts.map((n) => n.tokenId));
      if (tokens.length == 0) {
        if (this.logic == Logic.AND) return [];
        continue;
      }
      results.push({
        network: collection.network,
        collectionId: collection.collectionId,
        nfts: _nfts,
        tokens,
      });
    }
    return results;
  }

  countValidEntry(collections: NFTConstraintResultCollection[]) {
    if (!collections || isEmpty(collections)) return 0;
    return this.logic == Logic.OR
      ? sum(collections.map((e) => sum(e.nfts.map((nft) => nft.quantity))))
      : Math.min(
          ...collections.map((e) => sum(e.nfts.map((nft) => nft.quantity)))
        );
  }

  getValidCount(nfts: NFTOwnership[]) {
    const collections = this.validate(nfts);
    return this.countValidEntry(collections);
  }

  getValidResult(nfts: NFTOwnership[]): NFTConstraintResult {
    const collections = this.validate(nfts);
    const quantity = this.countValidEntry(collections);
    return {
      collections,
      quantity,
    };
  }
}
