import { isEmpty } from "lodash";
import { Base } from "../base";
import { CryptoCurrency } from "../currency";
import { BankAccount, BankAccountParam } from "../payment/bank";
import { compareText, normalizeId } from "../utils";

export enum OrganizationRole {
  OWNER = "owner",
  ADMIN = "admin",
  EDITOR = "editor",
  READONLY = "readonly",
}

export interface OrganizationMemberParam extends Partial<OrganizationMember> {
  email: string;
}
export class OrganizationMember {
  email: string;
  role: OrganizationRole;

  constructor(params: OrganizationMemberParam) {
    this.email = normalizeId(params.email);
    this.role = params.role || OrganizationRole.EDITOR;
  }
}

export interface OrganizerParam
  extends Omit<Partial<Organizer>, "bankAccounts" | "wallets" | "members"> {
  bankAccounts?: BankAccountParam[];
  wallets?: OrganizerWalletParam[];
  members?: OrganizationMemberParam[];
}

export class Organizer extends Base implements OrganizerParam {
  email: string;
  name: string;
  members: OrganizationMember[];
  profiles: OrganizerProfile[];
  bankAccounts: BankAccount[];
  wallets: OrganizerWallet[];
  defaultPayment: {
    bankAccountId?: string;
    walletId?: string;
  };
  customEventHost?: string;

  constructor(params?: OrganizerParam) {
    super(params);
    this.email = params?.email || "";
    this.name = params?.name || "";
    this.members =
      params?.members && !isEmpty(params.members)
        ? params.members.map((member) => new OrganizationMember(member))
        : [];
    this.profiles =
      params?.profiles && !isEmpty(params.profiles) ? params.profiles : [];
    this.bankAccounts =
      params?.bankAccounts && !isEmpty(params.bankAccounts)
        ? params.bankAccounts.map((account) => new BankAccount(account))
        : [];
    this.wallets =
      params?.wallets && !isEmpty(params.wallets)
        ? params.wallets.map((wallet) => new OrganizerWallet(wallet))
        : [];
    this.defaultPayment = params?.defaultPayment || {};
    if (params?.customEventHost) this.customEventHost = params.customEventHost;
  }

  isOwner(id: string) {
    return this.id === id;
  }

  isRole(email: string, role: OrganizationRole) {
    return this.members.find(
      (member) => compareText(member.email, email) && member.role == role
    );
  }

  isMember(email: string) {
    return this.email === email || this.members.some((m) => m.email === email);
  }

  isAdmin(email: string) {
    return this.isRole(email, OrganizationRole.ADMIN);
  }

  isEditor(email: string) {
    return this.isRole(email, OrganizationRole.EDITOR);
  }

  isReadonly(email: string) {
    return this.isRole(email, OrganizationRole.READONLY);
  }
}

export interface OrganizerProfile {
  name?: string;
  url?: string;
  logo?: string;
  description?: string;
  organization?: string;
}

export interface OrganizerWalletParam extends Partial<OrganizerWallet> {
  currency: CryptoCurrency;
  address: string;
}

export class OrganizerWallet extends Base implements OrganizerWalletParam {
  currency: CryptoCurrency;
  address: string;

  constructor(params: OrganizerWalletParam) {
    super(params);
    this.currency = params.currency;
    this.address = params.address;
  }
}
