import { isEmpty, omit, pick } from "lodash";
import { BaseIdOnly } from "../base";
import { RunnerConfig, RunnerConfigParam } from "../job";
import {
  DEFAULT_LANGUAGE,
  Language,
  Translation,
  translate,
} from "../language";
import { Moonpass } from "../moonpass";
import { AirdropConfig, AirdropConfigParam, NFTOwnership } from "../nft";
import { PaymentConfig, PaymentConfigParam } from "../payment";
import { ERC20TokenBalance } from "../token";
import { toJSON } from "../utils";
import {
  NFTEventTierMoonpassConstraint,
  NFTEventTierMoonpassConstraintParam,
} from "./constraint/moonpass";
import { NFTConstraint, NFTConstraintParam } from "./constraint/nft";
import { TokenConstraint } from "./constraint/token";
import { NFTEventTierEmailConfig, NFTEventTierEmailConfigParam } from "./email";
import {
  NFTEventTierRewardConfig,
  NFTEventTierRewardConfigParam,
} from "./reward";
import {
  NFTEventTierUsageConstraint,
  NFTEventTierUsageConstraintParam,
} from "./usage";
import {
  NFTEventTierWhitelistConstraint,
  NFTEventTierWhitelistConstraintParam,
} from "./whitelist";

export interface NFTEventTierInfo {
  name?: string;
  description?: string;
  rewards?: string[];
  instruction?: string; // @deprecated
}

export interface NFTEventTierParam
  extends Omit<
    Partial<NFTEventTier>,
    | "whitelistConstraint"
    | "nftConstraint"
    | "moonpassConstraint"
    | "usageConstraint"
    | "paymentConfig"
    | "airdropConfig"
    | "emailConfig"
    | "attendanceAirdropConfig"
    | "runnerConfig"
    | "rewardConfig"
  > {
  whitelistConstraint?: NFTEventTierWhitelistConstraintParam;
  nftConstraint?: NFTConstraintParam;
  moonpassConstraint?: NFTEventTierMoonpassConstraintParam;
  usageConstraint?: NFTEventTierUsageConstraintParam;
  paymentConfig?: PaymentConfigParam;
  airdropConfig?: AirdropConfigParam;
  emailConfig?: NFTEventTierEmailConfigParam;
  attendanceAirdropConfig?: AirdropConfigParam;
  runnerConfig?: RunnerConfigParam;
  rewardConfig?: NFTEventTierRewardConfigParam;
}

const privateProperties = [
  "emailConfig",
  "attendanceAirdropConfig",
  "runnerConfig",
];
export class NFTEventTier extends BaseIdOnly {
  order: number;
  info: Translation<NFTEventTierInfo>;
  imageUrl: string;
  eligible?: boolean;
  whitelistConstraint?: NFTEventTierWhitelistConstraint;
  nftConstraint?: NFTConstraint;
  tokenConstraint?: TokenConstraint;
  moonpassConstraint?: NFTEventTierMoonpassConstraint; // Only set if user owns specific moonpass, allow access without further checking
  usageConstraint?: NFTEventTierUsageConstraint;
  paymentConfig?: PaymentConfig;
  airdropConfig?: AirdropConfig;
  emailConfig?: NFTEventTierEmailConfig;
  attendanceAirdropConfig?: AirdropConfig;
  runnerConfig?: RunnerConfig;
  rewardConfig?: NFTEventTierRewardConfig;
  hidden?: boolean;

  // NFT metadata, non-changeable after deploy
  nftName: string;
  nftDescription: string;

  constructor(params?: NFTEventTierParam) {
    super(params);
    this.order = params?.order ?? 1;
    this.info = {};
    if (params?.info && !isEmpty(params?.info)) {
      this.info = toJSON(params.info);
      for (const [language, info] of Object.entries(params.info)) {
        this.info[language as Language]!.description =
          info.instruction || info.description || "";
        delete this.info[language as Language]!.instruction;
      }
    } else {
      this.info[DEFAULT_LANGUAGE] = {
        name: params?.name || "",
        description: params?.instruction || params?.description || "",
        rewards: params?.rewards || [],
      };
    }

    this.nftName = params?.nftName || this.name || "";
    this.nftDescription =
      params?.nftDescription ||
      this.name ||
      translate(params?.info).description ||
      this.description ||
      "";

    this.imageUrl = params?.imageUrl || "";
    if (params?.nftConstraint && !isEmpty(params.nftConstraint.collections)) {
      this.nftConstraint = new NFTConstraint(params.nftConstraint);
      if (this.nftConstraint.collections.length == 0) delete this.nftConstraint;
    }
    if (params?.tokenConstraint && !isEmpty(params.tokenConstraint.contracts)) {
      this.tokenConstraint = new TokenConstraint(params.tokenConstraint);
      if (this.tokenConstraint.contracts.length == 0)
        delete this.tokenConstraint;
    }
    if (params?.whitelistConstraint)
      this.whitelistConstraint = new NFTEventTierWhitelistConstraint({
        ...params.whitelistConstraint,
        nftRequirement: !isEmpty(this.nftConstraint?.collections)
          ? Math.max(1, params.whitelistConstraint.nftRequirement || 0)
          : 0,
      });
    if (params?.moonpassConstraint)
      this.moonpassConstraint = new NFTEventTierMoonpassConstraint(
        params.moonpassConstraint
      );
    this.usageConstraint = new NFTEventTierUsageConstraint(
      params?.usageConstraint
    );
    if (params?.paymentConfig) {
      if (params.paymentConfig.autoDiscounts) {
        params.paymentConfig.autoDiscounts.forEach((config) => {
          config.discount.productId = this.id;
        });
      }
      this.paymentConfig = new PaymentConfig(params.paymentConfig);
    }
    if (params?.airdropConfig) {
      this.airdropConfig = new AirdropConfig(params.airdropConfig);
      if (this.paymentConfig && this.airdropConfig.presetCount) {
        this.paymentConfig.max = this.airdropConfig.presetCount;
      }
    }
    if (params?.attendanceAirdropConfig)
      this.attendanceAirdropConfig = new AirdropConfig(
        params.attendanceAirdropConfig
      );
    if (params?.emailConfig)
      this.emailConfig = new NFTEventTierEmailConfig(params.emailConfig);
    if (params?.runnerConfig)
      this.runnerConfig = new RunnerConfig(params.runnerConfig);
    if (params?.rewardConfig)
      this.rewardConfig = new NFTEventTierRewardConfig(params.rewardConfig);
    if (params?.hidden) this.hidden = true;
  }

  get publicData() {
    return omit(this.json, privateProperties) as NFTEventTierParam;
  }

  get privateData(): Partial<NFTEventTierParam> {
    return pick(this.json, ["id", ...privateProperties]);
  }

  get name() {
    return translate(this.info).name || "";
  }

  get description() {
    return translate(this.info).description || "";
  }

  get instruction() {
    return this.description;
  }

  get rewards() {
    return translate(this.info).rewards || [];
  }

  get isWhitelisted(): boolean {
    return !isEmpty(this.whitelistConstraint);
  }

  get isNFTGated(): boolean {
    return !isEmpty(this.nftConstraint);
  }

  get isTokenGated(): boolean {
    return !isEmpty(this.tokenConstraint);
  }

  get isMoonpassGated(): boolean {
    return !isEmpty(this.moonpassConstraint);
  }

  // isPaid mean need to go through checkout
  get isPaid(): boolean {
    return !isEmpty(this.paymentConfig);
  }

  get hasUsageLimit(): boolean {
    return !!(
      this.usageConstraint &&
      (this.usageConstraint.limit > 0 || this.usageConstraint.boundToUser)
    );
  }

  get hasAirdrop(): boolean {
    return !!(this.airdropConfig && this.airdropConfig.contractId);
  }

  get hasAttendanceAirdrop(): boolean {
    return !!(
      this.attendanceAirdropConfig && this.attendanceAirdropConfig.contractId
    );
  }

  get hasJobs(): boolean {
    return !!(this.runnerConfig && !isEmpty(this.runnerConfig.jobs));
  }

  getEligibleNFTCollectionCount(nfts: NFTOwnership[]) {
    if (!this.nftConstraint) return 0;
    return this.nftConstraint.getValidCount(nfts);
  }

  validateNFTConstraint(nfts: NFTOwnership[]) {
    return this.getEligibleNFTCollectionCount(nfts) > 0;
  }

  getEligibleTokenCount(balances: ERC20TokenBalance[]) {
    if (!this.tokenConstraint) return 0;
    return this.tokenConstraint.getValidCount(balances);
  }

  validateTokenConstraint(balances: ERC20TokenBalance[]) {
    return this.getEligibleTokenCount(balances) > 0;
  }

  validateMoonpassConstraint(moonpass?: Moonpass) {
    if (!this.moonpassConstraint) return true;
    if (!moonpass) return false;
    if (this.moonpassConstraint.edition == "all") return true;
    return this.moonpassConstraint.edition === moonpass.edition;
  }

  validatePaymentNFTConstraint(nfts: NFTOwnership[]) {
    if (
      !this.paymentConfig?.nftConstraint ||
      isEmpty(this.paymentConfig?.nftConstraint)
    )
      return false;
    return this.paymentConfig.nftConstraint.getValidCount(nfts) > 0;
  }
}
