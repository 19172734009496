import { ComponentStyleConfig } from "@chakra-ui/react";

export const Drawer: ComponentStyleConfig = {
  baseStyle: {
    overlay: {
      bg: "rgba(0, 0, 0, 0.5)",
    },
    dialog: {
      boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.05)",
      backdropFilter: "blur(50px)",
      borderTopRadius: "16px",
      minWidth: "375px",
      minHeight: "12rem",
      // maxHeight: "100vh",
      p: 2,
      overflowY: "scroll",
      _dark: {
        bg: "rgba(255, 255, 255, 0.1)",
      },
    },
    header: {
      fontWeight: 500,
      px: 8,
    },
    closeButton: {
      _focusVisible: {
        boxShadow: "none",
      },
    },
    dark: {
      bgColor: "rgba(255, 255, 255, 0.1) !important",
    },
  },
  sizes: {
    md: {
      dialog: {
        minWidth: "100%",
      },
    },
    lg: {
      dialog: {
        minWidth: "30rem",
      },
    },
    ghost: {
      overlay: {
        bg: "rgba(0, 0, 0, 0.5)",
      },
      dialog: {
        maxWidth: "100vw",
        minWidth: "auto",
        p: 0,
        m: 0,
        bgColor: "transparent",
        shadow: "none",
        height: "100%",
        width: "100%",
      },
    },
    full: {
      overlay: {
        bg: "rgba(0, 0, 0, 0.5)",
      },
      dialog: {
        bgColor: "neutral.900",
        borderRadius: "0",
        p: 0,
        mt: 0,
        maxWidth: "100vw",
        minWidth: "100vw",
      },
      header: {
        fontSize: "2em",
        fontWeight: 500,
        color: "white",
        px: 8,
        pt: 16,
        pb: 6,
      },
      body: {
        flexGrow: 1,
      },
      footer: {
        mt: 8,
      },
    },
  },
  defaultProps: {
    size: "md",
  },
};

export default Drawer;
