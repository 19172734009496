import LoadingPage from "@components/Loader/LoadingPage";
import { isSupportedMobileBrowser } from "@lib/browser";
import { to } from "@lib/utils";
import { AuthError } from "@models/error";
import { matchRegex } from "@models/regex";
import { UserInfo } from "@particle-network/auth-core";
import {
  AuthCoreContextProvider,
  ConnectOptions,
  useConnect,
} from "@particle-network/auth-core-modal";
import {
  RainbowKitProvider,
  darkTheme,
  lightTheme,
} from "@rainbow-me/rainbowkit";
import * as Sentry from "@sentry/browser";
import {
  getIdToken,
  isSignInWithEmailLink,
  onAuthStateChanged,
  onIdTokenChanged,
  signInWithEmailLink,
  signOut,
} from "firebase/auth";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import type { ParsedUrlQuery } from "querystring";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { WagmiConfig } from "wagmi";
import AuthErrorMessage from "./AuthErrorMessage";
import InputEmailModal from "./InputEmailModal";
import {
  STORAGE_AUTH_EMAIL_KEY,
  STORAGE_AUTH_ID_KEY,
  checkExistingUser,
  firebaseAuth,
  initFirebaseClient,
  logOut,
  matchUrlPath,
  signIn,
  signInByAuthToken,
  signInWithNewWallet,
  useUserStore,
} from "./auth/services";
import { initializeParticleAuthCoreConfig } from "./auth/services/particle/core";
import { chains, initializeWagmiConfig } from "./auth/services/rainbowkit";

const AuthServiceProvider = ({
  appHost,
  apiHost,
  apiKey,
  firebaseProjectId,
  firebaseAPIKey,
  firebaseAppId,
  walletConnectProjectId: walletConnectProjectId = "",
  particleProjectId: particleProjectId = "",
  particleClientKey: particleClientKey = "",
  particleAppId: particleAppId = "",
  authFallbackPath: authFallbackPath = "/auth",
  publicRoutesWithAuth: publicRoutesWithAuth = [/^\/auth(\/.*)?/],
  publicRoutesNoAuth: publicRoutesNoAuth = [],
  protectedRoutes: protectedRoutes = [],
  requireEmail: requireEmail = true,
  children,
}: {
  appHost: string;
  apiHost: string;
  apiKey: string;
  firebaseProjectId: string;
  firebaseAPIKey: string;
  firebaseAppId: string;
  walletConnectProjectId?: string;
  particleProjectId?: string;
  particleClientKey?: string;
  particleAppId?: string;
  authFallbackPath?: string;
  publicRoutesWithAuth?: RegExp[];
  publicRoutesNoAuth?: RegExp[];
  protectedRoutes?: RegExp[];
  requireEmail?: boolean;
  children?: ReactNode;
}) => {
  const router = useRouter();
  const user = useUserStore((state) => state.user);
  const authenticating = useUserStore((state) => state.authenticating);
  const initialized = useUserStore((state) => state.initialized);
  const creatingWallet = useUserStore((state) => state.creatingWallet);
  const supportedBrowser = useUserStore((state) => state.supportedBrowser);
  const enableWalletLogin = useUserStore((state) => state.enableWalletLogin);
  // const [approvePopup, setApprovePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const publicRoutes = [...publicRoutesNoAuth, ...publicRoutesWithAuth];
  const [wagmiConfig, setWagmiConfig] = useState(null);

  const authenticatingRef = useRef(useUserStore.getState().authenticating);
  const sentEmailLinkRef = useRef(useUserStore.getState().sentEmailLink);
  const firebaseAuthenticatingRef = useRef(
    useUserStore.getState().firebaseAuthenticating
  );

  const authFallbackPathRegex = useMemo(() => {
    return new RegExp(`^${authFallbackPath || "/auth"}`, "im");
  }, [authFallbackPath]);

  const enableParticle = !!(
    particleProjectId &&
    particleClientKey &&
    particleAppId
  );

  let connect: (options?: ConnectOptions) => Promise<UserInfo | undefined>;
  let disconnect: () => Promise<void>;
  if (enableParticle) {
    ({ connect, disconnect } = useConnect());
  }

  // const { userInfo } = useAuthCore();
  // useEffect(() => {
  //   if (!initialized || !creatingWallet || !userInfo) return;
  //   const wallet =
  //     userInfo.wallets.find((w) => w.chain_name == "evm_chain")
  //       ?.public_address || "";
  //   console.log("particle connect", userInfo);
  //   if (!wallet) return;
  //   console.log("particle connected", wallet);

  //   const _signIn = async () => {
  //     const _user = await signInWithNewWallet(wallet);
  //     console.log("particle signInWithNewWallet", _user);
  //     if (!_user) {
  //       setError(AuthError.UNKNOWN_USER);
  //       logOut(disconnect, "", 5);
  //       return;
  //     }
  //     setLoading(false);
  //   };

  //   _signIn();
  // }, [initialized, creatingWallet, userInfo]);

  Sentry.configureScope((scope) => {
    scope.setTag("appHost", appHost);
    scope.setTag("apiHost", apiHost);
    scope.setTag("firebaseProjectId", firebaseProjectId);
    scope.setTag("firebaseAppId", firebaseAppId);
    scope.setTag("particleProjectId", particleProjectId);
    scope.setTag("particleAppId", particleAppId);
    scope.setTag("walletConnectProjectId", walletConnectProjectId);
  });
  // Subscribe to firebase auth state
  useEffect(() => {
    const init = async () => {
      const _wagmiConfig = initializeWagmiConfig();
      setWagmiConfig(_wagmiConfig);
      const enableWalletLogin = !!walletConnectProjectId && !!_wagmiConfig;
      useUserStore.setState({ enableParticle, enableWalletLogin });
      // const _approvePopup = enableParticle && (isMobile() || isSafari());
      // setApprovePopup(_approvePopup);

      Sentry.addBreadcrumb({
        category: "initialization",
        level: "info",
      });

      console.log("AuthProvider: initialized", {
        appHost,
        apiHost,
        firebaseProjectId,
        firebaseAppId,
        particleProjectId,
        particleAppId,
        walletConnectProjectId,
      });
      if (!apiKey) console.error("Missing apiKey");
      if (!firebaseAPIKey) console.error("Missing firebaseAPIKey");
      if (enableParticle && !particleClientKey)
        console.error("Missing particleClientKey");
      if (enableWalletLogin && !walletConnectProjectId)
        console.error("Missing walletConnectProjectId");

      await initFirebaseClient();
      console.log("AuthProvider: firebase initialized");
      firebaseAuth.useDeviceLanguage();

      useUserStore.subscribe((state) => {
        authenticatingRef.current = state.authenticating;
        sentEmailLinkRef.current = state.sentEmailLink;
        firebaseAuthenticatingRef.current = state.firebaseAuthenticating;
      });

      onAuthStateChanged(firebaseAuth, async (authUser) => {
        useUserStore.setState({ initialized: true });
        Sentry.addBreadcrumb({
          category: "onAuthStateChanged",
          level: "info",
        });
        if (authenticatingRef.current || sentEmailLinkRef.current) return;
        if (authUser && window.location.search.includes("oobCode")) {
          Sentry.addBreadcrumb({
            category: "onAuthStateChanged:logout",
            level: "info",
          });
          Sentry.setUser({ email: authUser.email || "", authId: authUser.uid });
          console.log(
            "AuthProvider:onAuthStateChanged",
            authUser,
            "Logging out previous user"
          );
          window.localStorage.removeItem(STORAGE_AUTH_ID_KEY);
          await to(signOut(firebaseAuth));
          return;
        }
        console.log(
          "AuthProvider:onAuthStateChanged",
          authUser,
          user,
          authenticatingRef.current,
          sentEmailLinkRef.current
        );
        useUserStore.setState({ authenticating: true });

        if (!authUser || !authUser.uid || !authUser.email) {
          // sign in by existing auth token
          let authId = "";
          try {
            authId = JSON.parse(
              window.localStorage.getItem(STORAGE_AUTH_ID_KEY) || ""
            );
          } catch (error) {
            window.localStorage.removeItem(STORAGE_AUTH_ID_KEY);
          }

          let authToken = "";
          try {
            if (router.query?.auth)
              authToken = String(router.query.auth) || authToken;
          } catch (error) {}

          if (authId || authToken) {
            console.log(
              "onAuthStateChanged",
              "sign in by existing credentials",
              authId,
              authToken
            );
            const { res: _user } = await to(
              signInByAuthToken(authToken, authId)
            );
            if (!_user) {
              logOut(disconnect);
              return;
            }
            setLoading(false);
            return;
          }
          useUserStore.setState({ authenticating: false });
          setLoading(false);
          return;
        }

        const { res: idToken } = await to(getIdToken(authUser), 10);
        if (!idToken) return;
        console.log("onAuthStateChanged", idToken);
        useUserStore.setState({
          authenticating: true,
          authId: authUser.uid,
          email: authUser.email,
          idToken,
        });

        if (enableParticle && connect) {
          const existingUser = await checkExistingUser(
            authUser.uid,
            idToken,
            authUser.email
          );
          console.log("onAuthStateChanged:checkExistingUser", existingUser);

          if (!existingUser || isEmpty(existingUser.evmWallets)) {
            Sentry.addBreadcrumb({
              category: "createUserWallet",
              level: "info",
            });
            console.log("creating new wallet...");
            useUserStore.setState({ creatingWallet: true });

            const { err, res: particleUser } = await to(
              connect({
                jwt: idToken,
              })
            );
            const wallet =
              particleUser?.wallets?.find((w) => w.chain_name == "evm_chain")
                ?.public_address || "";
            if (err || !wallet) {
              console.error("createUserWallet", err);
              setError(AuthError.CREATE_WALLET_FAILED);
              logOut(disconnect, "", 5);
              return;
            }
            console.log("particle connected", particleUser, wallet);
            const _user = await signInWithNewWallet(wallet);
            console.log("particle signInWithNewWallet", _user);
            if (!_user) {
              setError(AuthError.UNKNOWN_USER);
              logOut(disconnect, "", 5);
              return;
            }
            setLoading(false);
            return;
          }
        }

        const _user = await signIn(authUser.uid, idToken, authUser.email);
        if (!_user) {
          setError(AuthError.UNKNOWN_USER);
          logOut(disconnect, "", 5);
          return;
        }
        setLoading(false);
      });

      onIdTokenChanged(firebaseAuth, async (authUser) => {
        if (!authUser) return;
        const { res: idToken } = await to(getIdToken(authUser), 10);
        if (!idToken) {
          return;
        }
        // console.log("onIdTokenChanged", idToken);
        useUserStore.setState({ idToken });
      });
    };
    init();
  }, []);

  // Check auth on route changes
  const checkAuth = async (pathname: string) => {
    Sentry.addBreadcrumb({
      category: "checkAuth",
      level: "info",
    });
    Sentry.setTag("url", pathname);
    console.log("AuthProvider:checkAuth", pathname);
    if (authenticatingRef.current) return false;
    [pathname] = pathname.split("?");
    useUserStore.setState({ authenticating: false });
    setLoading(false);
    if (matchUrlPath(pathname, publicRoutesNoAuth)) return true;
    if (user) return true;

    if (
      !user &&
      (matchUrlPath(pathname, protectedRoutes) ||
        !matchUrlPath(pathname, publicRoutes)) &&
      !authenticatingRef.current
    ) {
      const url =
        (authFallbackPath === "/" ? "" : authFallbackPath) +
        `/${encodeURIComponent(router.asPath)}`;
      console.log("AuthProvider:checkAuth: Redirecting to ", url);
      router.push(url);
      return false;
    }
    return true;
  };

  // Handle sign in email
  const emailLinkSignIn = async () => {
    Sentry.addBreadcrumb({
      category: "emailLinkSignIn",
      level: "info",
    });
    if (!isSupportedMobileBrowser()) {
      Sentry.captureMessage(AuthError.UNSUPPORTED_BROWSER);
      useUserStore.setState({ supportedBrowser: false });
      return;
    }
    window.localStorage.removeItem(STORAGE_AUTH_ID_KEY);
    if (firebaseAuth.currentUser) await to(signOut(firebaseAuth));
    if (authenticatingRef.current || firebaseAuthenticatingRef.current) return;
    useUserStore.setState({
      authenticating: true,
      firebaseAuthenticating: true,
    });
    setLoading(true);

    const email =
      decodeURIComponent(String(router.query.email || "")) ||
      window.localStorage.getItem(STORAGE_AUTH_EMAIL_KEY) ||
      "";
    Sentry.setTag("email", email);
    console.log("AuthProvider:emailLinkSignIn", email);

    const href = router.asPath;
    Sentry.setTag("href", href);
    console.log("AuthProvider:emailLinkSignIn", href);

    const redirect = decodeURIComponent(String(router.query.redirect || ""));
    const redirectUrl = new URL(redirect, window.location.origin);
    const redirectQuery = Object.fromEntries(redirectUrl.searchParams) || {};
    const params = new URLSearchParams(router.query as any);
    params.delete("oobCode");
    params.delete("mode");
    params.delete("apiKey");
    params.delete("email");
    params.delete("lang");
    let pathname = router.pathname;
    if (redirect && redirectUrl.pathname != router.pathname) {
      pathname = redirectUrl.pathname;
      params.delete("redirect");
    }
    router.push(
      { pathname, query: { ...Object.fromEntries(params), ...redirectQuery } },
      undefined,
      {
        shallow: true,
      }
    );

    useUserStore.setState({
      authenticating: false,
    });
    console.log("AuthProvider:emailLinkSignIn:signInWithEmailLink");
    const { err } = await to(signInWithEmailLink(firebaseAuth, email, href));
    useUserStore.setState({
      firebaseAuthenticating: false,
    });
    if (err) {
      Sentry.captureException(err);
      console.log(err.code, err.message);
      setError(err.code);
      return;
    }
    console.log(
      "AuthProvider:emailLinkSignIn:signInWithEmailLink",
      "firebase signed in"
    );
    window.localStorage.removeItem(STORAGE_AUTH_EMAIL_KEY);
  };

  // Handle redirect actions
  const handleRedirectAction = async (queries: ParsedUrlQuery) => {
    Sentry.addBreadcrumb({
      category: "handleRedirectAction",
      level: "info",
    });
    const action = queries.action;
    if (!action) return;
    setLoading(true);
    try {
    } catch (error) {
      console.log("handleRedirectAction", error);
    }
    useUserStore.setState({ authenticating: false });
    setLoading(false);
    return;
  };

  // const handleOAuthRedirect = (method: string) => {
  //   console.log("handleOAuthRedirect", method);
  //   setLoading(true);
  // };

  useEffect(() => {
    if (
      !router.isReady ||
      !initialized ||
      authenticatingRef.current ||
      firebaseAuthenticatingRef.current
    )
      return;
    console.log("AuthProvider", router.pathname, router.query, user);
    setLoading(true);
    useUserStore.setState({ authenticating: true });

    // Redirect if authenticated
    if (user) {
      if (router.query.redirect) {
        const redirect = decodeURIComponent(String(router.query.redirect));
        Sentry.setTag("redirect", redirect);
        console.log("AuthProvider: user ready, redirecting to", redirect);
        router.push(redirect);
      } else if (
        authFallbackPath != "/" &&
        matchRegex(authFallbackPathRegex, router.pathname)
      ) {
        console.log(
          "AuthProvider: user ready, redirecting",
          router.pathname,
          "/",
          authFallbackPathRegex
        );
        router.push("/");
      }
      useUserStore.setState({ authenticating: false });
      setLoading(false);
      return;
    }

    useUserStore.setState({ authenticating: false });
    // Handle sign in email
    if (router.query.oobCode && router.query.mode == "signIn") {
      console.log("AuthProvider:isSignInWithEmailLink", window.location.href);
      if (!isSignInWithEmailLink(firebaseAuth, window.location.href)) {
        console.log("AuthProvider:isSignInWithEmailLink redirecting to /");
        router.push("/");
        return;
      }
      emailLinkSignIn();
      return;
    }

    // Handle redirect actions
    if (router.query.action) {
      handleRedirectAction(router.query);
      return;
    }

    // if (router.query.oauth) {
    //   handleOAuthRedirect(String(router.query.oauth));
    //   return;
    // }

    checkAuth(router.asPath);
  }, [user?.id, initialized, router.isReady, router.pathname, router.query]);

  useEffect(() => {
    if (!supportedBrowser) setError(AuthError.UNSUPPORTED_BROWSER);
  }, [supportedBrowser]);

  // if (authenticating && approvePopup && creatingWallet)
  //   return <CreateWalletInstruction />;
  if (error) return <AuthErrorMessage error={error} />;
  if (loading || authenticating || !initialized) return <LoadingPage />;
  if (!enableWalletLogin || !walletConnectProjectId || !wagmiConfig)
    return <>{children}</>;
  return (
    <>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider
          appInfo={{
            appName: "Moongate",
            learnMoreUrl: "https://moongate.id",
          }}
          chains={chains}
          modalSize="compact"
          theme={{
            lightMode: lightTheme(),
            darkMode: darkTheme(),
          }}
        >
          <>{children}</>
          {requireEmail && <InputEmailModal />}
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
};

const AuthProvider = (props: {
  appHost: string;
  apiHost: string;
  apiKey: string;
  firebaseProjectId: string;
  firebaseAPIKey: string;
  firebaseAppId: string;
  walletConnectProjectId?: string;
  particleProjectId?: string;
  particleClientKey?: string;
  particleAppId?: string;
  authFallbackPath?: string;
  publicRoutesWithAuth?: RegExp[];
  publicRoutesNoAuth?: RegExp[];
  protectedRoutes?: RegExp[];
  requireEmail?: boolean;
  children?: ReactNode;
}) => {
  const { particleProjectId, particleClientKey, particleAppId } = props;
  if (!particleProjectId || !particleClientKey || !particleAppId)
    return <AuthServiceProvider {...props} />;

  const particleConfig = initializeParticleAuthCoreConfig(
    particleProjectId,
    particleClientKey,
    particleAppId
  );

  return (
    <AuthCoreContextProvider options={particleConfig}>
      <AuthServiceProvider {...props} />
    </AuthCoreContextProvider>
  );
};

export default AuthProvider;
