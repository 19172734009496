import { compact, List, uniq } from "lodash";

export const to = <T>(
  promise: Promise<T>,
  timeout = 60
): Promise<{ err?: any; res?: T }> => {
  return new Promise((resolve) => {
    let timerId: any;
    if (timeout > 0)
      timerId = setTimeout(() => {
        resolve({ err: `timeout after ${timeout}s` });
      }, timeout * 1000);

    promise
      .then((res) => {
        if (timerId) clearTimeout(timerId);
        resolve({ res });
      })
      .catch((err) => {
        if (timerId) clearTimeout(timerId);
        resolve({ err });
      });
  });
};

export const toAll = async <T>(
  promiseArr: Promise<T>[],
  timeout = 60
): Promise<{
  results: (T | null)[];
  succeed: T[];
  failed: any[];
}> => {
  return new Promise((resolve) => {
    let timerId: any;
    if (timeout > 0)
      timerId = setTimeout(() => {
        resolve({
          results: [],
          succeed: [],
          failed: [`timeout after ${timeout}s`],
        });
      }, timeout * 1000);

    Promise.allSettled(promiseArr)
      .then((values) => {
        if (timerId) clearTimeout(timerId);
        const results: any[] = values.map((v: any) =>
          v.status == "fulfilled" ? v.value : null
        );
        const failed: any[] = values
          .filter((v) => v.status != "fulfilled")
          .map((v: any) => v.reason || v.value || "unknown error");
        const succeed = compact(results);
        resolve({ results, succeed, failed });
      })
      .catch((err) => {
        if (timerId) clearTimeout(timerId);
        resolve({ results: [], succeed: [], failed: [err] });
      });
  });
};

export const sleep = async (second: number) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), second * 1000);
  });
};

export const unique = <T>(array: List<T>) => {
  return uniq(compact(array));
};

export const intervalRetry = async <T>(
  promise: () => Promise<T>,
  retryInterval = 1,
  maxRetry = 3,
  eachTimeout?: number
): Promise<T> => {
  let retryCount = 0;
  let err: any | undefined;
  let res: T | undefined;
  do {
    if (retryCount > 0 && retryInterval > 0) await sleep(retryInterval);
    retryCount++;
    ({ err, res } = await to(promise(), eachTimeout));
  } while (!res && retryCount <= maxRetry);
  if (res !== undefined) return res;
  throw err;
};

export const exponentialBackOffRetry = async <T>(
  promise: () => Promise<T>,
  maxRetry = 3,
  eachTimeout?: number
): Promise<T> => {
  let retryCount = 0;
  let err: any | undefined;
  let res: T | undefined;
  do {
    if (retryCount > 0) {
      const delay = Math.min(
        Math.pow(2, retryCount - 1) +
          Math.round((Math.random() + Number.EPSILON) * 100) / 100,
        10
      );
      // console.log("retry delay", delay);
      await sleep(delay);
    }
    retryCount++;
    ({ err, res } = await to(promise(), eachTimeout));
  } while (!res && retryCount <= maxRetry);
  if (res !== undefined) return res;
  throw err;
};

export const delayedRun = async <T>(
  promise: () => Promise<T>,
  delay = 1
): Promise<T> => {
  if (delay > 0) await sleep(delay);
  return promise();
};

export const timeoutAfter = (second: number = 0) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error("timeout"));
    }, second * 1000);
  });
};
