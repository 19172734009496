import { Network } from "./network";
import { User } from "./user";
import { WalletProvider } from "./wallet";

export enum AuthError {
  UNKNOWN_USER = "unknown_user",
  DUPLICATE_SIGNIN = "duplicate_signin",
  SESSION_EXPIRED = "session_expired",
  INVALID_AUTH_TOKEN = "invalid_auth_token",
  INVALID_WALLET_SIGNATURE = "invalid_wallet_signature",
  ISSUE_AUTH_TOKEN_FAILED = "issue_auth_token_failed",
  RETRIEVE_USER_DATA_FAILED = "retrieve_user_data_failed",
  CREATE_WALLET_FAILED = "create_wallet_failed",
  SAVE_WALLET_FAILED = "save_wallet_failed",
  UNSUPPORTED_BROWSER = "unsupported_browser",
  INVALID_LINK = "auth/invalid-action-code",
  EXPIRED_LINK = "auth/expired-action-code",
}

export interface AuthResult {
  user: User | null;
  authToken: string;
}

export interface AuthParam {
  // Sign in by firebase
  email?: string;
  authId?: string;
  authIdToken?: string;

  // Sign in by wallet
  address?: string;
  network?: Network;
  signature?: string;
  siweMessage?: any;
  provider?: WalletProvider;

  ip?: string;
  source?: string;
}

export const AUTH_ID_COOKIE_KEY = "authId";
export const AUTH_TOKEN_COOKIE_KEY = "authToken";
