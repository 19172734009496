import {
  Button,
  ButtonProps,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTheme } from "next-themes";
import { ReactNode, useEffect, useMemo } from "react";
import { DarkModeIcon, LightModeIcon } from "../../icons";

const ColorModes = ["light", "dark"] as const;
export type ColorMode = (typeof ColorModes)[number];

export const useSetTheme = () => {
  const { setTheme } = useTheme();
  const { colorMode, toggleColorMode } = useColorMode();

  const setColorMode = (mode: ColorMode) => {
    if (!ColorModes.includes(mode)) return;
    console.log("setColorMode", mode, colorMode);
    setTheme(mode);
    if (colorMode !== mode) toggleColorMode();
  };

  return setColorMode;
};

export const FixedThemeProvider = ({
  theme,
  children,
}: {
  theme: ColorMode;
  children: ReactNode;
}) => {
  const setTheme = useSetTheme();
  useEffect(() => {
    if (theme) setTheme(theme);
  }, [theme]);

  return <>{children}</>;
};

export const useToggleTheme = () => {
  const { theme, setTheme } = useTheme();
  const { colorMode, toggleColorMode } = useColorMode();

  const toggleTheme = () => {
    switch (colorMode) {
      case "dark": {
        setTheme("light");
        break;
      }
      case "light": {
        setTheme("dark");
        break;
      }
    }
    toggleColorMode();
  };

  return toggleTheme;
};

interface Props extends ButtonProps {
  showtext?: string;
}

const ToggleTheme = (props?: Props) => {
  const toggleTheme = useToggleTheme();
  const showtext = props?.showtext === "true";
  const toggleThemeIcon = useColorModeValue(
    <DarkModeIcon boxSize={6} />,
    <LightModeIcon boxSize={6} />
  );
  const toggleThemeText = useColorModeValue("Dark Mode", "Light Mode");
  const text = useMemo(() => {
    return showtext ? toggleThemeText : "";
  }, [showtext, toggleThemeText]);

  return (
    <Button
      size="md"
      variant="outline"
      onClick={toggleTheme}
      leftIcon={text ? toggleThemeIcon : undefined}
      {...props}
    >
      {text || toggleThemeIcon}
    </Button>
  );
};

export default ToggleTheme;
