import { extendTheme } from "@chakra-ui/react";
import Accordion from "./accordion";
import Button from "./button";
import colors from "./colors";
import Drawer from "./drawer";
import Form from "./form";
import FormLabel from "./formlabel";
import Input from "./input";
import Modal from "./modal";
import PaymentCard from "./paymentCard";
import { Select } from "./select";
import Skeleton from "./skeleton";
import styles from "./styles";
import Table from "./table";
import Tooltip from "./tooltip";

export const theme = extendTheme({
  config: {
    initialColorMode: "dark",
  },
  fontSizes: {
    sm: "12px",
    md: "14px",
    lg: "16px",
  },
  breakpoints: {
    sm: "40rem",
    md: "45rem",
    lg: "60rem",
    xl: "75rem",
  },
  styles,
  colors,
  components: {
    Button,
    Modal,
    Table,
    FormLabel,
    Input,
    Tooltip,
    Form,
    Select,
    Accordion,
    Drawer,
    Skeleton,
    PaymentCard,
  },
});

export default theme;
