const glow = "#9C7B64";
const darkBlue = "#FFFFFF";

export const colors = {
  transparent: "transparent",
  current: "currentColor",
  white: "#ffffff",
  black: "#000000",
  primary: {
    50: "#F3FBFA",
    100: glow,
    200: glow,
    300: glow,
    400: darkBlue,
    500: glow,
    default: glow,
    600: glow,
    700: darkBlue,
    800: glow,
    900: glow,
  },
  neutral: {
    10: "#FAFAFB",
    20: "#F6F6F6",
    40: "#ECECED",
    60: "#DEDEE0",
    100: "#D0D0D3",
    200: "#B8B8BD",
    300: "#A0A0A7",
    400: "#898992",
    500: "#71717C",
    default: "#71717C",
    600: "#595966",
    700: "#414150",
    800: "#2A2A3A",
    850: "#1E1E2F",
    900: "#010613",
  },
  message: "#CAC4D0",
  secondary: "rgb(238, 238, 238, 0.65)",
  disabled: "rgb(238, 238, 238, 0.30)",
  placeholder: "#827D9D",
  error: "#EB5757",
  warn: "#FFC700",
  info: "#777E90",
  gradientDark:
    "linear-gradient(rgba(250, 250, 251, 0.16), rgba(250, 250, 251, 0.04))",
  tags: {
    benefit: {
      dark: {
        text: darkBlue,
        bg: glow,
      },
      light: {
        text: darkBlue,
        bg: glow,
      },
    },
    membership: {
      dark: {
        text: darkBlue,
        bg: glow,
      },
      light: {
        text: darkBlue,
        bg: glow,
      },
    },
    ticket: {
      dark: {
        text: darkBlue,
        bg: glow,
      },
      light: {
        text: darkBlue,
        bg: glow,
      },
    },
    custom: {
      dark: {
        text: darkBlue,
        bg: glow,
      },
      light: {
        text: darkBlue,
        bg: glow,
      },
    },
    whitelistOnly: {
      text: darkBlue,
      bg: glow,
    },
    holderOnly: {
      text: darkBlue,
      bg: glow,
    },
    inviteOnly: {
      text: darkBlue,
      bg: glow,
    },
    discount: {
      text: "#F6BE2C",
      bg: "rgba(243, 251, 250, 0.10)",
    },
  },
};

export default colors;
