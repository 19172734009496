import { Base } from "../base";
import { floor, minus, times } from "../math";
import { cryptoId, normalizeId } from "../utils";
import { PAYMENT_AMOUNT_PRECISION } from "./constants";

export enum DiscountType {
  PERCENT_OFF = "percent_off",
  AMOUNT_OFF = "amount_off",
  FIXED_PRICE = "fixed_price",
}

export enum DiscountError {
  INVALID = "invalid_code",
  DUPLICATED = "duplicated_code",
}

export interface DiscountParam extends Partial<Discount> {
  eventId: string;
  type: DiscountType;
  value: number;
  remainUse?: number; //@deprecated
}

export class Discount extends Base implements DiscountParam {
  code: string; // discount code
  eventId: string;
  productId?: string; // usually tierId
  type: DiscountType;
  value: number; // discounted value based on type
  reusable: boolean;
  expiry?: number;
  applyLimit: number; // if tierId is set, limit how many item of this tier can apply discounted price. -1 for unlimited
  max: number; // max number of transactions can apply, -1 for unlimited, default to 1
  usageCount: number;
  auto?: boolean;
  referrer?: string; // referrer email / wallet
  referrerId?: string;
  refId?: string; // transactionId for transaction generated discounts

  constructor(params: DiscountParam) {
    super(params);
    this.code = normalizeId(params.code || cryptoId(4));
    this.eventId = params.eventId;
    if (params.productId) this.productId = params.productId;
    this.reusable = !!params.reusable;
    this.type = params.type;
    switch (this.type) {
      case DiscountType.FIXED_PRICE: {
        this.value = Math.max(Number(params.value || 0), 0);
        break;
      }
      case DiscountType.AMOUNT_OFF: {
        this.value = Number(params.value || 0);
        break;
      }
      case DiscountType.PERCENT_OFF: {
        this.value = Math.min(Math.max(Number(params.value || 0), 0), 1);
        break;
      }
    }
    if (params.expiry) this.expiry = Number(params.expiry);
    this.applyLimit = Number(params.applyLimit ?? -1);
    this.max = this.reusable
      ? -1
      : params.max !== undefined
      ? Number(params.max ?? 1)
      : Number(params.remainUse || 1);

    if (params.usageCount !== undefined) {
      this.usageCount = Number(params.usageCount ?? 0);
    } else {
      if (this.max === -1) {
        this.usageCount = 0;
      } else {
        this.usageCount = Math.max(
          this.max - Math.max(params.remainUse ?? this.max, 0),
          0
        );
      }
    }
    if (params.auto !== undefined) this.auto = !!params.auto;
    if (params.referrer) this.referrer = params.referrer;
    if (params.referrerId) this.referrerId = params.referrerId;
    if (params.refId) this.refId = params.refId;
  }

  get isReferral() {
    return !!this.referrerId;
  }

  get remainUse() {
    if (this.max === -1) return -1;
    return Math.max(this.max - this.usageCount, 0);
  }

  get valid() {
    if (this.expiry && Date.now() > this.expiry) return false;
    if (this.reusable || this.max === -1) return true;
    return this.remainUse > 0;
  }

  calculateDiscount(originalPrice: number) {
    try {
      if (!this.valid || this.value == undefined || this.value == null)
        return 0;
      switch (this.type) {
        case DiscountType.FIXED_PRICE: {
          return floor(
            Math.max(minus(originalPrice, this.value), 0),
            PAYMENT_AMOUNT_PRECISION
          );
        }
        case DiscountType.AMOUNT_OFF: {
          return floor(
            Math.min(this.value, originalPrice),
            PAYMENT_AMOUNT_PRECISION
          );
        }
        case DiscountType.PERCENT_OFF: {
          return floor(
            times(originalPrice, this.value),
            PAYMENT_AMOUNT_PRECISION
          );
        }
      }
    } catch (error) {}
    return 0;
  }

  markUsed() {
    if (!this.valid) return this;
    this.usageCount += 1;
    this.updatedAt = Date.now();
    return this;
  }

  unmarkUsed() {
    if (this.reusable || this.remainUse == -1) return this;
    this.usageCount -= 1;
    this.usageCount = Math.max(this.usageCount, 0);
    this.updatedAt = Date.now();
    return this;
  }
}

export interface AutoDiscountConfig {
  priority: number; // priority of discount to be applied, 0 is most important
  criteria: {
    quantity?: number;
  };
  discount: Discount;
}

export interface AutoDiscountConfigParam
  extends Omit<AutoDiscountConfig, "priority" | "discount"> {
  priority?: number;
  criteria: {
    quantity?: number;
  };
  discount: DiscountParam;
}
