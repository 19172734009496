import { isEmpty } from "lodash";
import { Base } from "../base";

export enum NFTRewardType {
  TEXT = "text",
  URL = "url",
  CODE = "code",
  IMAGE = "image",
}

export enum NFTRewardError {}

export interface NFTRewardParam extends Partial<NFTReward> {
  eventId: string;
  tierId: string;
}

export class NFTReward extends Base {
  eventId: string;
  tierId: string;
  transactionId?: string;
  type: NFTRewardType;
  content: string;
  instruction?: string;

  // Random unique preset
  presetUsed?: boolean;

  constructor(params: NFTRewardParam) {
    super(params);
    this.eventId = params.eventId || "";
    this.tierId = params.tierId || "";
    if (params.transactionId) this.transactionId = params.transactionId;

    this.type = params.type || NFTRewardType.TEXT;
    this.content = params.content || "";
    if (params.instruction) this.instruction = params.instruction;
    if (params.presetUsed !== undefined) this.presetUsed = !!params.presetUsed;
  }
}

export interface NFTRewardCustomization {}

export interface NFTEventTierRewardConfigParam
  extends Partial<NFTEventTierRewardConfig> {}

export class NFTEventTierRewardConfig {
  rewardId?: string; // single reward
  customization?: NFTRewardCustomization; // UI customizations etc.

  // Random unique preset
  presetCount?: number;

  constructor(params: NFTEventTierRewardConfigParam) {
    if (params.rewardId) this.rewardId = params.rewardId;
    if (params.customization && !isEmpty(params.customization))
      this.customization = params.customization;
    if (params.presetCount) {
      this.presetCount = Number(params.presetCount);
      delete this.rewardId;
    }
  }
}
