export enum AppError {
  UNKNOWN = "unknown_error",
  TIMEOUT = "timeout",
  UNSUPPORTED_NETWORK = "unsupported_network",
  TEMPORARILY_UNAVAILABLE = "temporarily_unavailable",
  MISSING_NFT_COLLECTION = "missing_nft_collection",
}

export enum DataError {
  UNKNOWN_DOCUMENT = "data/unknown_document",
  UPDATE_FAILED = "data/update_failed",
  INSERT_FAILED = "data/insert_failed",
  REPLACE_FAILED = "data/replace_failed",
  DELETE_FAILED = "data/delete_failed",
}
