import { isEmpty } from "lodash";
import { v4 as uuid } from "uuid";
import { DEFAULT_LANGUAGE, Translation } from "../language";
import { MS_END } from "../timezone";
import { toJSON } from "../utils";

export interface IPaymentPrice extends Partial<PaymentPrice> {}
export class PaymentPrice implements IPaymentPrice {
  id: string;
  info: Translation<{
    name: string;
    description?: string;
  }>;
  price: number;
  // start: number;
  end: number;
  max: number; // -1 for unlimited
  sold: number;

  constructor(params?: IPaymentPrice) {
    this.id = params?.id || uuid();
    this.info = {};
    if (params?.info && !isEmpty(params.info)) {
      this.info = params.info;
    } else {
      this.info[DEFAULT_LANGUAGE] = {
        name: "",
        description: "",
      };
    }
    this.price = Number(params?.price || 0);
    // this.start = Number(params?.start ?? 0);
    this.end = Number(params?.end ?? MS_END);
    this.max = Number(params?.max || -1);
    this.sold = Number(params?.sold || 0);
  }

  get json() {
    return toJSON(this);
  }
}
