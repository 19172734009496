import { Base } from "../base";
import { MS_END } from "../timezone";
import { toJSON } from "../utils";

export interface NFTEventTierWhitelistConstraintParam
  extends Partial<NFTEventTierWhitelistConstraint> {}
export class NFTEventTierWhitelistConstraint
  implements NFTEventTierWhitelistConstraintParam
{
  start: number; // start time for registration period
  end: number; // end time for registration period
  max: number; // max number of entries, -1 for unlimited
  maxPerUser: number; // max number of claims per user , -1 for unlimited
  nftRequirement: number; // number of NFT required per claim, 0 for not required
  externalUrl?: string;

  constructor(params?: NFTEventTierWhitelistConstraintParam) {
    this.start = Number(params?.start ?? 0);
    this.end = Number(params?.end ?? MS_END);
    this.max = Number(params?.max ?? -1);
    this.nftRequirement = Number(params?.nftRequirement || 0);
    this.maxPerUser = Number(params?.maxPerUser || -1);
    if (params?.externalUrl) this.externalUrl = params.externalUrl;
  }

  get json() {
    return toJSON(this);
  }
}

export interface NFTEventWhitelistParam extends Partial<NFTEventWhitelist> {
  eventId: string;
  tierId: string;
  identifier: string;
}

export class NFTEventWhitelist extends Base implements NFTEventWhitelistParam {
  eventId: string;
  tierId: string;
  identifier: string;
  quantity: number;
  wallets: string[];
  email: string;
  revoked: boolean;
  order?: number;

  constructor(params: NFTEventWhitelistParam) {
    super(params);
    this.eventId = params.eventId;
    this.tierId = params.tierId;
    this.quantity = Number(params.quantity || 1);

    this.identifier = params.identifier;
    this.wallets = params.wallets || [];
    this.email = params.email || "";
    this.revoked = !!params.revoked;

    if (params.order) this.order = Number(params.order);
  }
}
