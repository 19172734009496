import { intToHex } from "./utils";

export enum Network {
  EVM = "evm",
  ETHEREUM = "ethereum",
  POLYGON = "polygon",
  BSC = "bsc",
  AVALANCHE = "avalanche",
  FANTOM = "fantom",
  CRONOS = "cronos",
  OPTIMISM = "optimism",
  ARBITRUM = "arbitrum",
  CELO = "celo",
  HARMONY = "harmony",
  MOONBEAM = "moonbeam",
  MOONRIVER = "moonriver",
  KLAYTN = "klaytn",
  GNOSIS = "gnosis",

  SOLANA = "solana",

  ETHEREUM_GOERLI = "ethereum-goerli",
  POLYGON_MUMBAI = "polygon-mumbai",
  AVALANCHE_FUJI = "avalanche-fuji",
  BSC_TESTNET = "bsc-testnet",
}

export const EVM_MAINNETS = [
  Network.ETHEREUM,
  Network.POLYGON,
  Network.BSC,
  Network.AVALANCHE,
  Network.FANTOM,
  Network.CRONOS,
  Network.OPTIMISM,
  Network.ARBITRUM,
  Network.CELO,
  Network.HARMONY,
  Network.MOONBEAM,
  Network.MOONRIVER,
  Network.KLAYTN,
];
export const EVM_TESTNETS = [
  Network.ETHEREUM_GOERLI,
  Network.POLYGON_MUMBAI,
  Network.AVALANCHE_FUJI,
  Network.BSC_TESTNET,
];

export const EVM_NETWORKS = [Network.EVM, ...EVM_MAINNETS, ...EVM_TESTNETS];
export const TEST_NETWORKS = [...EVM_TESTNETS];

export const EVM_SUPPORTED_MAINNETS = [
  Network.ETHEREUM,
  Network.POLYGON,
  Network.AVALANCHE,
  Network.BSC,
] as const;
export const EVM_SUPPORTED_TESTNETS = [
  Network.ETHEREUM_GOERLI,
  Network.POLYGON_MUMBAI,
  Network.AVALANCHE_FUJI,
  Network.BSC_TESTNET,
] as const;
export const EVM_SUPPORTED_NETWORKS = [
  ...EVM_SUPPORTED_MAINNETS,
  ...EVM_SUPPORTED_TESTNETS,
] as const;
export const SUPPORTED_MAINNETS = [...EVM_SUPPORTED_MAINNETS, Network.SOLANA];
export const SUPPORTED_TESTNETS = [...EVM_SUPPORTED_TESTNETS];
export const SUPPORTED_NETWORKS = [
  Network.EVM,
  ...SUPPORTED_MAINNETS,
  ...SUPPORTED_TESTNETS,
];

export type EVMSupportedMainnets = (typeof EVM_SUPPORTED_MAINNETS)[number];
export type EVMSupportedNetworks = (typeof EVM_SUPPORTED_NETWORKS)[number];
export type SupportedNetworks = EVMSupportedNetworks;

export const CASE_SENSITIVE_NETWORKS = [Network.SOLANA];
export const WEB3AUTH_SUPPORTED_NETWORKS = [
  Network.SOLANA,
  Network.ETHEREUM,
  Network.POLYGON,
  Network.BSC,
  Network.AVALANCHE,
  Network.CRONOS,
  Network.OPTIMISM,
  Network.ARBITRUM,
  Network.CELO,
  Network.HARMONY,
  Network.MOONBEAM,
  Network.MOONRIVER,
  Network.KLAYTN,
];

export const EVM_CHAINS = new Map([
  [Network.ETHEREUM, 1],
  [Network.POLYGON, 137],
  [Network.BSC, 56],
  [Network.AVALANCHE, 43114],
  [Network.FANTOM, 250],
  [Network.CRONOS, 25],
  [Network.OPTIMISM, 10],
  [Network.ARBITRUM, 42161],
  [Network.CELO, 42220],
  [Network.HARMONY, 1666600000],
  [Network.MOONBEAM, 1284],
  [Network.MOONRIVER, 1285],
  [Network.KLAYTN, 8217],

  [Network.ETHEREUM_GOERLI, 5],
  [Network.POLYGON_MUMBAI, 80001],
  [Network.AVALANCHE_FUJI, 43113],
  [Network.BSC_TESTNET, 97],
]);

export const EVM_CHAIN_IDS = Array.from(EVM_CHAINS.values());
export const EVM_CHAIN_IDS_HEX = EVM_CHAIN_IDS.map((id) => intToHex(id));

export const toTestnet = (network: Network) => {
  network = String(network).trim().toLowerCase() as Network;
  switch (network) {
    case Network.ETHEREUM:
      return Network.ETHEREUM_GOERLI;
    case Network.POLYGON:
      return Network.POLYGON_MUMBAI;
    case Network.AVALANCHE:
      return Network.AVALANCHE_FUJI;
    case Network.BSC:
      return Network.BSC_TESTNET;
    default:
      return network;
  }
};

export const toMainnet = (network: Network) => {
  return String(network).trim().toLowerCase().replace(/-.+/, "") as Network;
};

export const toTestnets = (networks: Network[]) => {
  return networks.map((network) => toTestnet(network));
};

export const DEFAULT_DEPLOYMENT_NETWORK = Network.POLYGON;

export const INFURA_SUPPORTED_NETWORKS = [
  Network.ETHEREUM,
  Network.POLYGON,
  Network.AVALANCHE,
  Network.ETHEREUM_GOERLI,
  Network.POLYGON_MUMBAI,
  Network.AVALANCHE_FUJI,
] as const;
export type InfuraSupportedNetworks =
  (typeof INFURA_SUPPORTED_NETWORKS)[number];
