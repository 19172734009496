import { List, compact, kebabCase, mergeWith, uniq } from "lodash";
import { DateTime } from "luxon";
import randomBytes from "randombytes";
import { REGEX_NON_ALPHANUMERIC, escapeRegexSpecialChars } from "./regex";
import { MS_END, Timezone } from "./timezone";
import { DeepPartial } from "./types";

export const toJSON = (obj: any): any => {
  try {
    return JSON.parse(JSON.stringify(obj));
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const unique = <T>(array: List<T>) => {
  return uniq(compact(array));
};

export const walletAddressPreview = (address: string): string => {
  if (!address) return "";
  return `${address.substring(0, 8)}...${address.slice(address.length - 5)}`;
};

export const API_RETRY_HEADER = "MOONGATE-RETRY";

export const intToHex = (int: number): string => {
  return `0x${int.toString(16)}`;
};

export const hexToInt = (hex: string): number => {
  return parseInt(hex, 16);
};

export const joinDateTime = (date: string, time: string, sep = ",") => {
  return unique([date, time]).join(`${sep} `);
};

export const parseDateText = (
  start: number,
  end: number,
  timezone: Timezone,
  dateFormat = "d MMM yy",
  timeFormat = "HH:mm"
) => {
  let startDate = "";
  let startTime = "";
  let endDate = "";
  let endTime = "";
  let tz = "";
  let date = "";
  let time = "";

  if (start == 0 && end == MS_END) {
    date = "Forever";
  } else {
    const _start = DateTime.fromMillis(start, { zone: timezone });
    startDate = start == 0 ? "Now" : _start.toFormat(dateFormat);
    startTime = start == 0 ? "" : _start.toFormat(timeFormat);
    if (startTime === "00:00") startTime = "";

    const _end = DateTime.fromMillis(end, { zone: timezone });
    tz =
      start > 0
        ? _start.offsetNameShort || ""
        : end < MS_END
        ? _end.offsetNameShort || ""
        : "";

    if (end > start) {
      endDate =
        end == MS_END
          ? "Forever"
          : _end.hasSame(_start, "day")
          ? ""
          : _end.toFormat(dateFormat);
      endTime = end == MS_END ? "" : _end.toFormat(timeFormat);
      if (endTime === "00:00" || endTime === startTime) endTime = "";
    }

    date = unique([startDate, endDate]).join(" - ");
    time = unique([startTime, endTime]).join(" - ");
  }

  return {
    startDate,
    startTime,
    endDate,
    endTime,
    tz,
    date,
    time,
  };
};

export const toDuration = (
  start: number,
  end: number,
  timezone: Timezone,
  sep = ",",
  dateFormat = "d MMM yy",
  timeFormat = "HH:mm",
  showTimezone = false
): string => {
  const { tz, date, time } = parseDateText(
    start,
    end,
    timezone,
    dateFormat,
    timeFormat
  );
  const timezoneText = showTimezone && tz ? ` (${tz})` : "";
  return joinDateTime(date, time, sep) + timezoneText;
};

export const formatDate = (
  timestamp: number,
  timezone?: Timezone,
  format = "d MMM yy, HH:mm"
) => {
  const date = DateTime.fromMillis(timestamp, { zone: timezone });
  return date.toFormat(format);
};

export const cryptoId = (length = 16) => {
  return randomBytes(length).toString("hex");
};

export const toSearchText = (text: string) => {
  return (text || "")
    .toLowerCase()
    .replace(REGEX_NON_ALPHANUMERIC, "")
    .replace(/\s+/g, "");
};

export const toSlug = (text: string) => {
  return kebabCase(text || "");
};

export const compareText = (t1?: string, t2?: string) => {
  if (!t1 || !t2) return false;
  return normalizeId(t1 || "") === normalizeId(t2 || "");
};

export const normalizeId = (id: string) => {
  try {
    return id.trim().toLowerCase();
  } catch (error) {
    return id;
  }
};

export const normalizeEmail = (email: string) => {
  try {
    return normalizeId(email).replace(/\+.*@/, "@");
  } catch (error) {
    return email;
  }
};

export const normalizeIdentifier = (identifier: string) => {
  return normalizeEmail(identifier);
};

export const toRegexQueryArray = (identifiers: string[]) => {
  return identifiers.map(
    (i) => new RegExp(`^${escapeRegexSpecialChars(i)}$`, "im")
  );
};

export const compareEmail = (t1?: string, t2?: string) => {
  if (!t1 || !t2) return false;
  if (t1 === t2) return true;
  return normalizeEmail(t1 || "") === normalizeEmail(t2 || "");
};

export const leftOuterJoin = <T>(
  key: string,
  original: T,
  update: DeepPartial<T>
): T => {
  return mergeWith(original, update, (original: any, update: any) => {
    if (
      Array.isArray(original) &&
      Array.isArray(update) &&
      original.some((v) => v.hasOwnProperty(key)) &&
      update.some((v) => v.hasOwnProperty(key))
    ) {
      let result = { ...original };
      result = original.map((obj) => {
        const updateObj = update.find((_obj) => _obj[key] === obj[key]);
        return updateObj ? { ...obj, ...updateObj } : obj;
      });
      return result;
    }
  });
};

export const leftOuterJoinById = <T>(
  original: T,
  update: DeepPartial<T>
): T => {
  return leftOuterJoin("id", original, update);
};
