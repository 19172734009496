import { Network } from "../../network";

export const CENTER_SUPPORTED_EVM_NETWORKS = [
  Network.ARBITRUM,
  Network.AVALANCHE,
  Network.BSC,
  Network.CELO,
  Network.ETHEREUM,
  Network.FANTOM,
  Network.HARMONY,
  Network.OPTIMISM,
  Network.POLYGON,
];

export interface CenterItem {
  address: string;
  tokenId: string;
}

export interface CenterNFTResponse {
  items: CenterItem[];
  paging: {
    offset: number;
    limit: number;
    onLastPage: boolean;
  };
}
