const PWAMeta = () => {
  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
      />
      <link rel="manifest" href="/manifest.json" />
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="green" />
      <meta name="apple-mobile-web-app-title" content="Moongate" />
      <link
        rel="apple-touch-icon"
        href="/images/icons/icon-72x72.png"
        sizes="72x72"
      />
      <link
        rel="apple-touch-icon"
        href="/images/icons/icon-96x96.png"
        sizes="96x96"
      />
      <link
        rel="apple-touch-icon"
        href="/images/icons/icon-128x128.png"
        sizes="128x128"
      />
      <link
        rel="apple-touch-icon"
        href="/images/icons/icon-144x144.png"
        sizes="144x144"
      />
      <link
        rel="apple-touch-icon"
        href="/images/icons/icon-152x152.png"
        sizes="152x152"
      />
      <link
        rel="apple-touch-icon"
        href="/images/icons/icon-192x192.png"
        sizes="192x192"
      />
      <link
        rel="apple-touch-icon"
        href="/images/icons/icon-384x384.png"
        sizes="384x384"
      />
      <link
        rel="apple-touch-icon"
        href="/images/icons/icon-512x512.png"
        sizes="512x512"
      />
    </>
  );
};

export default PWAMeta;
