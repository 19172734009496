import { DateTime } from "luxon";
import { v4 as uuid } from "uuid";
import { INFTOwnership, NFTOwnership } from "../nft";
import { Transaction } from "../payment";
import { Timezone } from "../timezone";
import { normalizeId, toJSON, unique } from "../utils";

export enum UsageInterval {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
  YEAR = "year",
}
export interface NFTEventTierUsageConstraintParam
  extends Partial<NFTEventTierUsageConstraint> {}
export class NFTEventTierUsageConstraint
  implements NFTEventTierUsageConstraintParam
{
  boundToUser: boolean; // Only the same user can redeem after first usage
  interval?: UsageInterval; // If set, limit use within period. otherwise consider as within lifetime
  limit: number; // -1 for unlimited

  constructor(params?: NFTEventTierUsageConstraintParam) {
    this.boundToUser = !!params?.boundToUser;
    this.limit = params?.limit ?? 1;
    if (params?.interval) this.interval = params.interval || UsageInterval.DAY;
  }

  get json() {
    return toJSON(this);
  }

  intervalStart(timezone: Timezone = Timezone["UTC"]): number {
    if (!this.interval) return 0;
    try {
      return DateTime.now().setZone(timezone).startOf(this.interval).valueOf();
    } catch (error) {
      return 0;
    }
  }
}

export interface NFTEventUsageParam
  extends Omit<Partial<NFTEventUsage>, "nfts"> {
  eventId: string;
  tierId: string;
  identifiers: string[];
  nfts?: INFTOwnership[];
}

export class NFTEventUsage implements NFTEventUsageParam {
  eventId: string;
  tierId: string;
  tierName: string;
  identifiers: string[];
  validCount: number;

  id: string;
  timestamp: number;

  wallets?: string[];
  email?: string;
  userId?: string;

  nftIds?: string[];
  nfts?: NFTOwnership[];
  whitelists?: Transaction[];

  constructor(params: NFTEventUsageParam) {
    this.id = params.id || uuid();
    this.eventId = params.eventId;
    this.tierId = params.tierId;
    this.tierName = params.tierName || "";
    this.validCount = Number(params.validCount || 1);
    this.timestamp = Number(params.timestamp || Date.now());
    if (params.userId) this.userId = params.userId;
    if (params.wallets) this.wallets = unique(params.wallets);
    if (params.email) this.email = normalizeId(params.email);
    if (params.nfts && params.nfts.length > 0)
      this.nfts = params.nfts.map((nft) => new NFTOwnership(nft));
    if (this.nfts && this.nfts.length > 0)
      this.nftIds = unique(this.nfts.map((nft) => nft.id));
    if (params.whitelists)
      this.whitelists = params.whitelists.map(
        (transaction) => new Transaction(transaction)
      );
    this.identifiers = unique(
      [
        ...(params.identifiers || []),
        ...(this.wallets || []),
        this.userId || "",
        this.email || "",
      ].map((i) => normalizeId(i))
    );
  }

  get json() {
    return toJSON(this);
  }
}
