import {
  GoogleIcon,
  MoongateLogoColorBlack,
  MoongateLogoColorWhite,
} from "@assets";
import {
  FormControl,
  FormErrorMessage,
  Input,
  useColorMode,
} from "@chakra-ui/react";
import { MOONGATE_PRIVACY_URL, MOONGATE_TERMS_URL } from "@lib/misc";
import { to } from "@lib/utils";
import { REGEX_EMAIL } from "@models/regex";
import { normalizeEmail } from "@models/utils";
import * as Sentry from "@sentry/browser";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { SiweMessage } from "siwe";
import LoadingAnimation from "../../components/Loader/LoadingAnimation";
import { NextImage } from "../../components/NextImage/NextImage";
import ConnectWalletButton from "./ConnectWalletButton";
import VerifyEmail from "./VerifyEmail";
import {
  OAuthProvider,
  sendSignInEmail,
  signInByWallet,
  signInWithGoogle,
  useUserStore,
} from "./auth/services";

const LoginButton = ({ children }: { children?: ReactNode }) => {
  const { colorMode } = useColorMode();
  return (
    <div
      className={
        "flex flex-row justify-center items-center bg-glass-flat hover:bg-glass-green rounded-lg border border-neutral-600 cursor-pointer hover:border-primary w-full p-2 capitalize text-neutral text-sm min-h-[40px] " +
        (colorMode === "dark" ? "text-white" : "")
      }
    >
      {children}
    </div>
  );
};

const SignInModal = ({
  oauthMethods: oauthMethods = [OAuthProvider.GOOGLE],
  onAuthenticated: onAuthenticated = () => {},
}: {
  oauthMethods?: OAuthProvider[];
  onAuthenticated?: () => void;
}) => {
  const user = useUserStore((state) => state.user);
  const authenticating = useUserStore((state) => state.authenticating);
  const enableWalletLogin = useUserStore((state) => state.enableWalletLogin);

  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [_email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { colorMode } = useColorMode();

  const loginMethods = useMemo(() => oauthMethods, [oauthMethods]);

  const showExtraMethods = useMemo(
    () => loginMethods.length > 0,
    [loginMethods]
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  useEffect(() => {
    if (!user) return;
    onAuthenticated();
  }, [user]);

  const _signInByWallet = async (message: SiweMessage, signature: string) => {
    if (authenticating || !message?.address || !signature) return;
    Sentry.addBreadcrumb({
      category: "signInForm:signInByWallet",
      level: "info",
    });
    Sentry.setTag("wallet.address", message.address);
    console.log("_signInByWallet", message, signature);
    setLoading(true);
    const { res: user } = await to(
      signInByWallet(message.address, message, signature)
    );
    console.log("_signInByWallet", user);
    setTimeout(() => setLoading(false), 500);
  };

  const _signInByEmail = async (email: string) => {
    if (authenticating || !email) return;
    Sentry.addBreadcrumb({
      category: "signInForm:signInByEmail",
      level: "info",
    });
    Sentry.setTag("email", email);
    email = normalizeEmail(email);
    setEmail(email);
    console.log("_signInByWallet", email);
    setLoading(true);
    const { err: emailErr, res: _sent } = await to(sendSignInEmail(email));
    if (emailErr || !_sent) {
      console.error(emailErr);
      setError(String(emailErr || ""));
    } else {
      setSent(true);
    }
    setLoading(false);
  };

  const _signInWithOAuth = (provider: OAuthProvider) => {
    if (authenticating) return;
    Sentry.addBreadcrumb({
      category: `signInForm:signInWithOAuth:${provider}`,
      level: "info",
    });
    // router.replace({
    //   query: { ...router.query, oauth: String(provider) },
    // });
    setLoading(true);
    switch (provider) {
      case OAuthProvider.GOOGLE:
        return signInWithGoogle();
    }
  };

  const OAuthLoginButton = ({ provider }: { provider: OAuthProvider }) => {
    const Icon = () => {
      switch (provider) {
        case OAuthProvider.GOOGLE:
          return GoogleIcon;
      }
    };
    return (
      <button className="w-full" onClick={() => _signInWithOAuth(provider)}>
        <LoginButton>
          <NextImage className="w-6 mr-2" src={Icon()} alt={provider} />
          {provider} Login
        </LoginButton>
      </button>
    );
  };

  if (user)
    return (
      <div className="flex flex-col justify-center p-16 ">
        <p className="text-primary text-center">
          You've logged in successfully.
          <br />
          You'll be redirected shortly.
        </p>
      </div>
    );

  return (
    <div className="py-12 px-6">
      {loading && (
        <div className="flex flex-col justify-center p-16 m-auto">
          <LoadingAnimation />
        </div>
      )}

      {!loading && sent && (
        <VerifyEmail email={_email} resend={() => _signInByEmail(_email)} />
      )}

      {!loading && !user && error && (
        <div className="flex flex-col items-center justify-center m-8 text-center">
          <p className="my-8">{error}</p>
          <a className="text-primary" href="/">
            Back
          </a>
        </div>
      )}

      {!loading && !sent && !error && (
        <div className="sm:max-w-[18rem] min-w-fit w-72">
          <div className="flex flex-col items-center justify-center text-center">
            <NextImage
              className="w-40"
              src={
                colorMode === "light"
                  ? MoongateLogoColorBlack
                  : MoongateLogoColorWhite
              }
              alt="moongate"
            />
            <p
              className={
                "text-base my-6 text-neutral " +
                (colorMode === "dark" ? "text-white" : "")
              }
            >
              Log in / Sign up
            </p>
          </div>

          <div className="flex flex-col items-center justify-center">
            <form
              className="w-full"
              onSubmit={handleSubmit((data) => {
                _signInByEmail(data.email);
              })}
            >
              <FormControl isInvalid={isDirty && !!errors.email}>
                <Input
                  className="bg-glass-flat rounded-lg w-full border border-neutral-600 cursor-pointer"
                  placeholder="Email"
                  padding="4"
                  {...register("email", {
                    required: "Required",
                    pattern: {
                      value: REGEX_EMAIL,
                      message: "Invalid email",
                    },
                  })}
                />
                <FormErrorMessage>
                  {errors.email && String(errors.email.message || "")}
                </FormErrorMessage>
              </FormControl>
              <button
                className="mt-4 bg-glass-green rounded-lg border cursor-pointer border-primary text-primary text-sm capitalize justify-center w-full hover:opacity-80 h-12"
                type="submit"
              >
                Let&#39;s Get Started
              </button>
            </form>

            {showExtraMethods && (
              <>
                <div
                  className={
                    "w-full text-neutral text-sm text-center my-6 " +
                    (colorMode === "dark" ? "text-white" : "")
                  }
                >
                  or
                </div>

                <div className="w-full grid grid-cols-1 gap-4">
                  {oauthMethods.map((provider, i) => (
                    <OAuthLoginButton key={i} provider={provider} />
                  ))}

                  {enableWalletLogin && (
                    <ConnectWalletButton onSuccess={_signInByWallet}>
                      <LoginButton>Connect Wallet</LoginButton>
                    </ConnectWalletButton>
                  )}
                </div>
              </>
            )}

            <p
              className={
                "text-xs text-neutral-300 text-center px-4 my-6 " +
                (colorMode === "dark" ? "text-white" : "")
              }
            >
              By logging in, you agree to our <br />
              <a
                href={MOONGATE_TERMS_URL}
                target="_blank"
                className="text-primary font-semibold"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href={MOONGATE_PRIVACY_URL}
                target="_blank"
                className="text-primary font-semibold"
              >
                Privacy Policy
              </a>
              .
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignInModal;
