import axios, { AxiosInstance, AxiosRequestConfig, Method } from "axios";
import { to } from "./utils";

export class APIClient {
  private _http: AxiosInstance;
  private _apiHost: string;
  private _apiKey: string;
  // session?: string;
  // authId?: string;
  // authToken?: string;

  constructor(apiHost: string, apiKey: string) {
    this._apiHost = apiHost;
    this._apiKey = apiKey;
    this._http = axios.create({
      baseURL: this._apiHost,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this._apiKey}`,
      },
      withCredentials: true,
    });
  }

  get apiHost() {
    return this._apiHost;
  }

  async request(
    method: Method,
    url: string,
    params?: object,
    data?: object,
    throwError?: boolean,
    timeout?: number,
    contentType: string = "application/json"
  ) {
    const config: AxiosRequestConfig = {
      url,
      method,
      params,
      data,
    };
    config.headers = {
      "Content-Type": contentType,
      // "X-Session-ID": this.session || "",
      // "X-Auth-ID": this.authId || "",
      // "X-Auth-Token": this.authToken || "",
    };

    const { err, res } = await to(this._http.request(config), timeout ?? 0);
    if (!res?.data) {
      const errMsg = err?.response?.data?.error || err || "unknown_error";
      console.log(url, errMsg);
      if (throwError) throw errMsg;
      return null;
    }

    const result = res.data;
    //console.log(url, result);
    if (!result || !result.success || result.error) {
      const errMsg = result?.error || "unknown_error";
      console.log(url, errMsg);
      if (throwError) throw errMsg;
      return null;
    }
    return result.data || true;
  }

  async post(
    url: string,
    data?: object,
    throwError?: boolean,
    timeout?: number,
    contentType: string = "application/json"
  ) {
    return this.request(
      "POST",
      url,
      undefined,
      data,
      throwError,
      timeout,
      contentType
    );
  }

  async get(
    url: string,
    params?: object,
    throwError?: boolean,
    timeout?: number
  ) {
    return this.request("GET", url, params, undefined, throwError, timeout);
  }

  async patch(
    url: string,
    data?: object,
    throwError?: boolean,
    timeout?: number,
    contentType: string = "application/json"
  ) {
    return this.request(
      "PATCH",
      url,
      undefined,
      data,
      throwError,
      timeout,
      contentType
    );
  }

  async del(
    url: string,
    params?: object,
    throwError?: boolean,
    timeout?: number
  ) {
    return this.request("DELETE", url, params, undefined, throwError, timeout);
  }
}
