import { Network, toMainnet } from "../network";
import { compareText, toJSON } from "../utils";
import { NFT, NFTStandard } from "./nft";

export interface AirdropConfigParam extends Partial<AirdropConfig> {
  network: Network;
  standard: NFTStandard;
  contractId: string;
}

export class AirdropConfig implements AirdropConfigParam {
  network: Network;
  contractId: string;
  standard: NFTStandard;
  contractAddress: string;

  tokenId?: string; // ERC1155
  metadataTemplateId?: string; // ERC721
  metadataTemplateName?: string; // ERC721

  // Unique random nft metadata
  presetCount?: number;

  constructor(params: AirdropConfigParam) {
    this.network = params.network;
    this.contractId = params.contractId;
    this.contractAddress = params.contractAddress || "";
    this.standard = params.standard || NFTStandard.ERC721;

    switch (this.standard) {
      case NFTStandard.ERC1155: {
        this.tokenId = params.tokenId || "";
        break;
      }
      case NFTStandard.ERC721: {
        this.metadataTemplateId = params.metadataTemplateId || "";
        this.metadataTemplateName = params.metadataTemplateName || "";
        break;
      }
    }

    if (params.metadataTemplateId)
      this.metadataTemplateId = params.metadataTemplateId;
    if (params.metadataTemplateName)
      this.metadataTemplateName = params.metadataTemplateName;
    if (params.tokenId) this.tokenId = params.tokenId;
    if (params.presetCount !== undefined)
      this.presetCount = Number(params.presetCount);
  }

  get json() {
    return toJSON(this);
  }

  checkNFT(nft: NFT) {
    return (
      this.network &&
      toMainnet(this.network) === toMainnet(nft.network) &&
      compareText(this.contractAddress, nft.collectionId)
    );
  }
}

export const formatTokenURI = (uri: string) => {
  try {
    if (!(uri.startsWith("http") || uri.startsWith("ipfs"))) {
      uri = `ipfs://${uri}`;
    }
  } catch (error) {}
  return uri;
};
