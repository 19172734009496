import { MoongateLoader } from "@assets";
import { Player } from "@lottiefiles/react-lottie-player";

const Loader = ({
  size: size = 48,
  className: className = "",
}: {
  size?: number;
  className?: string;
}) => {
  return (
    <div className={`p-4 ${className}`}>
      <Player
        autoplay
        loop
        src={MoongateLoader}
        style={{ height: `${size}px`, width: `${size}px` }}
      ></Player>
    </div>
  );
};

export default Loader;
