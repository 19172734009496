import { flattenDeep, isEmpty, omit, pick } from "lodash";
import { Base } from "../base";
import { Currency, toCurrency } from "../currency";
import {
  DEFAULT_LANGUAGE,
  Language,
  Translation,
  translate,
} from "../language";
import { Network } from "../network";
import { NFTStandard, parseCollectionId, toNFTId } from "../nft";
import {
  DEFAULT_TRANSACTION_FEE,
  PaymentConfig,
  PaymentMethod,
} from "../payment";
import { MS_END, Timezone } from "../timezone";
import {
  cryptoId,
  joinDateTime,
  normalizeId,
  parseDateText,
  toDuration,
  toJSON,
  toSearchText,
  unique,
} from "../utils";
import { NFTEventTier, NFTEventTierParam } from "./tier";
import { NFTEventTierWhitelistConstraint } from "./whitelist";

export const EventDetailComponentTypes = {
  generalInfo: "generalInfo",
  ticketInfo: "ticketInfo",
  info: "info",
  ticketType: "ticketType",
  eligibility: "eligibility",
  about: "about",
  hostInfo: "hostInfo",
  aboutMoongate: "aboutMoongate",
  faqLink: "faqLink",
  faq: "faq",
} as const;

export type EventDetailComponentType =
  (typeof EventDetailComponentTypes)[keyof typeof EventDetailComponentTypes];

export * from "./constants";

export interface NFTEventOrganizer {
  name?: string;
  url?: string;
  logo?: string;
  description?: string;
}

export enum EventStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
  DELETED = "deleted",
  HIDDEN = "hidden",
}

export enum EventType {
  TICKET = "ticket",
  BENEFIT = "benefit",
  MEMBERSHIP = "membership",
}

export interface NFTEventInfoParam extends Partial<NFTEventInfo> {}

export class NFTEventInfo {
  name: string;
  descriptions: { title: string; content: string }[];
  organizer: NFTEventOrganizer;
  location?: string;
  locationQuery?: string;
  locationUrl?: string;
  // date?: string;
  // time?: string;
  dateText?: string;
  timeText?: string;
  banner?: string;
  hero?: string;
  logo?: string;
  gallery?: string[];
  externalLinks?: { name: string; url: string }[];
  faqs?: { question: string; answer: string }[];
  customActions?: { name: string; url: string }[];
  url: string;
  subtitle?: string;

  constructor(params?: NFTEventInfoParam) {
    this.name = params?.name || "";
    this.descriptions = params?.descriptions || [];
    this.organizer = params?.organizer || { name: "", description: "" };
    this.url = params?.url || "";
    // if (params?.date) this.date = params.date;
    // if (params?.time) this.time = params.time;
    if (params?.dateText) this.dateText = params.dateText;
    if (params?.timeText) this.timeText = params.timeText;
    if (params?.location) this.location = params.location;
    if (params?.locationQuery) this.locationQuery = params.locationQuery;
    if (params?.locationUrl) this.locationUrl = params.locationUrl;
    if (params?.banner) this.banner = params.banner;
    if (params?.hero) this.hero = params.hero;
    if (params?.logo) this.logo = params.logo;
    if (params?.gallery) this.gallery = params.gallery;
    if (params?.externalLinks) this.externalLinks = params.externalLinks;
    if (params?.faqs) this.faqs = params.faqs;
    if (params?.customActions) this.customActions = params.customActions;
    if (params?.subtitle) this.subtitle = params.subtitle;
  }

  get json() {
    return toJSON(this);
  }

  get searchText(): string {
    return toSearchText(
      flattenDeep([
        this.name,
        this.organizer.name || "",
        this.location || "",
        ...this.descriptions.map((d) => [d.title || "", d.content || ""]),
      ]).join(" ")
    );
  }
}

export interface NFTEventCustomization {
  showCountdown?: boolean;
  redirectAfterSuccessUrl?: string;
  redirectAfterSuccessTimeout?: number;
  overrideActionUrl?: string;
  overrideActionText?: string;
  eventDetailComponentsOrder?: EventDetailComponentType[];
  eventDetailShowDivider?: boolean;
  eventDetailHideMap?: boolean;
  checkoutDiscountInputPlaceholder?: string;
}

export interface NFTEventParam
  extends Omit<Partial<NFTEvent>, "tiers" | "info"> {
  organizerId: string;
  info?: Translation<NFTEventInfoParam>;
  tiers?: NFTEventTierParam[];
}

const privateProperties = ["customPaymentAppConfig"];
const immutableProperties = ["transactionFee", "code", "vatRate"];
export class NFTEvent extends Base implements NFTEventParam {
  type: EventType;
  organizerId: string;
  tiers: NFTEventTier[];
  networks: Network[];
  collections: string[];
  start: number;
  end: number;
  timezone: Timezone;
  tags?: string[];
  info: Translation<NFTEventInfo>;
  slug?: string;
  domain?: string;
  code: string;
  status: EventStatus;

  airdropCollections?: string[];
  constraintCollections?: string[];

  // Payment Configs
  currency?: Currency;
  paymentMethods?: PaymentMethod[];
  customPaymentAppConfig?: {
    [method in PaymentMethod]?: {
      appId: string;
      [key: string]: any;
    };
  };
  transactionFee?: number;
  vatRate?: number;

  allowCrossTierPurchase?: boolean;
  allowBulkPurchase?: boolean;

  allowDirectAccess?: boolean;

  customizations?: NFTEventCustomization;

  disableReminderEmail?: boolean;

  constructor(params: NFTEventParam) {
    super(params);

    // Default paymentConfig based on event type
    params.type = params.type || EventType.TICKET;
    switch (params.type) {
      case EventType.BENEFIT: {
        if (params.tiers && !isEmpty(params.tiers)) {
          for (let i = 0; i < params.tiers.length; i++) {
            if (isEmpty(params.tiers[i].paymentConfig)) continue;
            if (!params.tiers[i].paymentConfig!.maxPerTransaction) {
              params.tiers[i].paymentConfig!.maxPerTransaction = 1;
            }
            if (
              params.tiers[i].paymentConfig!.disableBuyForOthers === undefined
            ) {
              params.tiers[i].paymentConfig!.disableBuyForOthers = true;
            }
          }
        }
        break;
      }
      case EventType.MEMBERSHIP: {
        if (params.tiers && !isEmpty(params.tiers)) {
          for (let i = 0; i < params.tiers.length; i++) {
            if (isEmpty(params.tiers[i].paymentConfig)) continue;
            if (!params.tiers[i].paymentConfig!.maxPerTransaction) {
              params.tiers[i].paymentConfig!.maxPerTransaction = 1;
            }
            if (
              params.tiers[i].paymentConfig!.disableBuyForOthers === undefined
            ) {
              params.tiers[i].paymentConfig!.disableBuyForOthers = true;
            }
            if (!params.tiers[i].paymentConfig!.maxPerUser) {
              params.tiers[i].paymentConfig!.maxPerUser = 1;
            }
          }
        }
        break;
      }
      default: {
        params.type = EventType.TICKET;
      }
    }

    this.organizerId = params.organizerId || "";

    if (params.slug) this.slug = normalizeId(params.slug);
    if (params.domain) this.domain = normalizeId(params.domain);
    this.code = normalizeId(params.code || cryptoId(4));

    this.info = {};
    if (!isEmpty(params.info)) {
      for (const language of Object.keys(params.info)) {
        const lang = language as Language;
        if (params.info[lang])
          this.info[lang] = new NFTEventInfo(params.info[lang]!);
      }
    } else {
      this.info[DEFAULT_LANGUAGE] = new NFTEventInfo({
        name: "",
      });
    }

    this.start = Number(params.start ?? 0);
    this.end = Number(params.end ?? MS_END);
    this.timezone = params.timezone || Timezone["Asia/Hong_Kong"];
    this.tiers =
      params.tiers
        ?.map((tier) => {
          if (tier.paymentConfig?.autoDiscounts) {
            tier.paymentConfig.autoDiscounts.forEach((config) => {
              config.discount.eventId = this.id;
            });
          }
          return new NFTEventTier(tier);
        })
        .sort((a, b) => a.order - b.order) || [];
    this.networks = params.networks || [];

    this.collections = params.collections || [];
    if (params.airdropCollections && !isEmpty(params.airdropCollections))
      this.airdropCollections = params.airdropCollections;
    if (params.constraintCollections && !isEmpty(params.constraintCollections))
      this.constraintCollections = params.constraintCollections;
    this.tags = params.tags || [];

    if (params.customizations) {
      this.customizations = params.customizations;
    }
    this.status = params.published // backward compatible
      ? EventStatus.PUBLISHED
      : params.hidden
        ? EventStatus.HIDDEN
        : params.status || EventStatus.DRAFT;

    if (params.allowDirectAccess)
      this.allowDirectAccess = !!params.allowDirectAccess;

    this.type =
      params.type ||
      (this.isWhitelisted ? EventType.BENEFIT : EventType.TICKET);

    // Override configs based on event type
    switch (this.type) {
      case EventType.BENEFIT: {
        for (let i = 0; i < this.tiers.length; i++) {
          const tier = this.tiers[i];
          if (!this.allowDirectAccess) {
            if (isEmpty(tier.whitelistConstraint)) {
              this.tiers[i].whitelistConstraint =
                new NFTEventTierWhitelistConstraint();
            }
            this.tiers[i].paymentConfig = new PaymentConfig({
              ...(tier.paymentConfig ? tier.paymentConfig.json : {}),
              // Override paymentConfig according to whitelistConstraint
              ...(tier.whitelistConstraint
                ? {
                    start: tier.whitelistConstraint.start,
                    end: tier.whitelistConstraint.end,
                    max: tier.whitelistConstraint.max,
                    maxPerUser: tier.whitelistConstraint.maxPerUser,
                  }
                : {}),
            });
          }

          if (tier.rewardConfig?.presetCount && tier.paymentConfig) {
            this.tiers[i].paymentConfig!.max = tier.rewardConfig.presetCount;
          }
        }
        break;
      }
    }

    // isPaid mean need to go through checkout
    if (this.isPaid) {
      // Initialize payment settings
      this.paymentMethods = !isEmpty(params.paymentMethods)
        ? params.paymentMethods
        : [PaymentMethod.STRIPE];
      this.transactionFee = Number(
        params.transactionFee ?? DEFAULT_TRANSACTION_FEE
      );
      this.currency = toCurrency(params.currency);
      this.vatRate = Number(params.vatRate ?? 0);
    } else {
      if (params.paymentMethods && !isEmpty(params.paymentMethods))
        this.paymentMethods = params.paymentMethods;
      if (params.transactionFee)
        this.transactionFee = Number(params.transactionFee);
      if (params.vatRate) this.vatRate = Number(params.vatRate);
      if (params.currency) this.currency = toCurrency(params.currency);
    }

    if (params.allowCrossTierPurchase) this.allowCrossTierPurchase = true;
    if (params.allowBulkPurchase) this.allowBulkPurchase = true;
    if (
      params.customPaymentAppConfig &&
      !isEmpty(params.customPaymentAppConfig)
    ) {
      this.customPaymentAppConfig = {};
      for (const [method, config] of Object.entries(
        params.customPaymentAppConfig
      )) {
        if (
          Object.values(PaymentMethod).includes(method as PaymentMethod) &&
          config?.appId
        ) {
          this.customPaymentAppConfig[method as PaymentMethod] = config;
        }
      }
      if (isEmpty(this.customPaymentAppConfig))
        delete this.customPaymentAppConfig;
    }
    if (params.disableReminderEmail) this.disableReminderEmail = true;

    this.updateCollections();
    this.updateNetworks();
  }

  get publicData() {
    const tiers = this.tiers.map((t) => t.publicData);
    const data = { ...omit(this.json, privateProperties), tiers };
    return data as NFTEventParam;
  }

  get updatableData() {
    return omit(this.publicData, immutableProperties) as NFTEventParam;
  }

  get privateData() {
    const tiers = this.tiers.map((t) => t.privateData);
    const data = {
      ...pick(this.json, ["id", ...privateProperties, ...immutableProperties]),
      tiers,
    };
    return data as Partial<NFTEventParam>;
  }

  get name() {
    return translate(this.info).name;
  }

  get published() {
    return this.status === EventStatus.PUBLISHED;
  }

  get hidden() {
    return this.status === EventStatus.HIDDEN;
  }

  get dateTexts() {
    return parseDateText(this.start, this.end, this.timezone);
  }

  get datePeriod() {
    return this.dateTexts.date;
  }

  get timePeriod() {
    return this.dateTexts.time;
  }

  get duration(): string {
    return toDuration(this.start, this.end, this.timezone);
  }

  get isWhitelisted(): boolean {
    return this.tiers.some((tier) => tier.isWhitelisted);
  }

  get isNFTGated(): boolean {
    return this.tiers.some((tier) => tier.isNFTGated);
  }

  get isTokenGated(): boolean {
    return this.tiers.some((tier) => tier.isTokenGated);
  }

  get isMoonpassGated(): boolean {
    return this.tiers.some((tier) => tier.isMoonpassGated);
  }

  // isPaid mean need to go through checkout
  get isPaid(): boolean {
    return this.tiers.some((tier) => tier.isPaid);
  }

  get hasUsageLimit(): boolean {
    return this.tiers.some((tier) => tier.hasUsageLimit);
  }

  get hasAirdrop(): boolean {
    return this.tiers.some((tier) => tier.hasAirdrop);
  }

  get hasAttendanceAirdrop(): boolean {
    return this.tiers.some((tier) => tier.hasAttendanceAirdrop);
  }

  get hasJobs(): boolean {
    return this.tiers.some((tier) => tier.hasJobs);
  }

  get isPublic(): boolean {
    return !(
      this.isWhitelisted ||
      this.isNFTGated ||
      this.isMoonpassGated ||
      this.isTokenGated
    );
  }

  get isStarted(): boolean {
    return Date.now() >= this.start;
  }

  get isEnded(): boolean {
    return Date.now() > this.end;
  }

  get isERC1155BulkPurchase(): boolean {
    return !!(
      this.allowBulkPurchase &&
      this.tiers.some(
        (tier) => tier.airdropConfig?.standard === NFTStandard.ERC1155
      )
    );
  }

  get searchText(): string {
    return toSearchText(
      unique([
        ...(this.tags || []),
        ...Object.keys(this.info).map((language) => {
          const lang = language as Language;
          return this.info[lang]?.searchText || "";
        }),
      ]).join(" ")
    );
  }

  get allowDirectRegister() {
    try {
      if ([EventType.MEMBERSHIP, EventType.TICKET].includes(this.type))
        // Not benefit
        return false;
      if (this.tiers.length > 1) return false; // Multiple tiers
      if (this.tiers.some((t) => t.airdropConfig)) return false; // Require airdrop
      if (this.tiers.some((t) => t.hidden)) return false; // tier is hidden
      const tier = this.tiers[0];
      if ((tier.paymentConfig?.max ?? -1) > 0) return false; // Have limit
      if ((tier.paymentConfig?.defaultPrice?.price ?? 0) > 0) return false; // Require payment
      if (!isEmpty(tier.paymentConfig?.customForm)) return false; // Require custom form
      return true;
    } catch (error) {
      return false;
    }
  }

  get extraConstraintCollections() {
    const collectionIds = unique(
      flattenDeep(
        this.tiers.map((t) => [
          ...(t.nftConstraint?.collections || []).map((c) => c.id),
          ...(t.paymentConfig?.nftConstraint?.collections || []).map(
            (c) => c.id
          ),
        ])
      )
    );

    return collectionIds.filter((id) => {
      const { network, collectionId } = parseCollectionId(id);
      if (
        this.tiers.some((tier) =>
          tier.airdropConfig
            ? network.includes(tier.airdropConfig.network) &&
              tier.airdropConfig.contractAddress === collectionId
            : false
        )
      )
        return false;
      return true;
    });
  }

  validate() {
    const now = Date.now();
    if (now < this.start) return false;
    if (now > this.end) return false;
    return true;
  }

  updateCollections() {
    this.collections = unique(
      flattenDeep(
        this.tiers.map(
          (tier) =>
            tier.nftConstraint?.collections.map((n) =>
              toNFTId([n.network, n.collectionId])
            ) ||
            (tier.airdropConfig?.network && tier.airdropConfig?.contractAddress
              ? toNFTId([
                  tier.airdropConfig.network,
                  tier.airdropConfig.contractAddress,
                ])
              : "")
        )
      )
    );

    this.airdropCollections = unique(
      this.tiers.map((t) =>
        t.airdropConfig?.network && t.airdropConfig?.contractAddress
          ? toNFTId([t.airdropConfig.network, t.airdropConfig.contractAddress])
          : ""
      )
    );

    this.constraintCollections = unique(
      flattenDeep([
        ...this.collections,
        ...this.tiers.map((t) =>
          (t.paymentConfig?.nftConstraint?.collections || []).map((c) => c.id)
        ),
      ])
    );
  }

  updateNetworks() {
    this.networks = unique(
      flattenDeep(
        this.tiers.map(
          (tier) =>
            tier.nftConstraint?.collections.map((n) => n.network) ||
            tier.airdropConfig?.network
        ) as any
      )
    );
  }

  update() {
    this.updateCollections();
    this.updateNetworks();
    this.updatedAt = Date.now();
  }

  addTier(constraint: NFTEventTier) {
    this.tiers.push(constraint);
    this.update();
  }

  removeTier(constraintId: string) {
    this.tiers = this.tiers.filter((c) => c.id != constraintId);
    this.update();
  }

  localeInfo(language: Language = DEFAULT_LANGUAGE): NFTEventInfo {
    if (language in this.info) return this.info[language]!;
    return this.info[DEFAULT_LANGUAGE] || new NFTEventInfo();
  }

  toURL(host = "app.moongate.id", basePath = "/e", https = true) {
    try {
      let eventHost = host;
      let protocol = "https";
      ({ eventHost, host, protocol, basePath } = toEventHost(
        host,
        basePath,
        https
      ));
      const url = `${eventHost}/${this.slug || this.id}`;
      const _url = this.localeInfo().url;
      return this.domain
        ? `${protocol}://${this.domain}`
        : !_url || _url.includes(host)
          ? url
          : _url;
    } catch (error) {}
    return "";
  }

  localeDatePeriod(language: Language = DEFAULT_LANGUAGE) {
    return translate(this.info, language)?.dateText || this.dateTexts.date;
  }

  localeTimePeriod(language: Language = DEFAULT_LANGUAGE) {
    return translate(this.info, language)?.timeText || this.dateTexts.time;
  }

  localePeriod(language: Language = DEFAULT_LANGUAGE, showTimezone = false) {
    const { tz, date, time } = parseDateText(
      this.start,
      this.end,
      this.timezone
    );
    const info = this.localeInfo(language);
    const isCustom = info?.dateText || info?.timeText;
    const dateText = isCustom ? info?.dateText || "" : date;
    const timeText = isCustom ? info?.timeText || "" : time;
    const timezoneText = showTimezone && tz && !isCustom ? ` (${tz})` : "";
    return joinDateTime(dateText, timeText) + timezoneText;
  }
}

export const toEventHost = (host: string, basePath = "", https = true) => {
  const protocol = https ? "https" : "http";
  if (!host.match(/^https?:\/\//im)) host = `${protocol}://${host}`;
  if (host.endsWith("/")) host = host.slice(0, -1);
  if (basePath) {
    if (!basePath.startsWith("/")) basePath = "/" + basePath;
    if (basePath.endsWith("/")) basePath = basePath.slice(0, -1);
  }
  return { eventHost: `${host}${basePath}`, protocol, host, basePath };
};
