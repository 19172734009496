import Script from "next/script";
import { ReactNode } from "react";

const GoogleAnalyticsProvider = ({
  measurementId: measurementId = "",
  children,
}: {
  measurementId: string;
  children?: ReactNode;
}) => {
  if (!measurementId) return <>{children}</>;
  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`}
      />
      <Script strategy="lazyOnload" id="gtag">
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${measurementId}', {
          page_path: window.location.pathname,
          });`}
      </Script>
      {children}
    </>
  );
};

export default GoogleAnalyticsProvider;
