import { ComponentStyleConfig } from "@chakra-ui/react";

export const PaymentCard: ComponentStyleConfig = {
  baseStyle: (props) => ({
    cursor: "pointer",
    borderWidth: "1px",
    borderRadius: "md",
    boxShadow: "md",
    _checked: {
      borderColor: "primary.300",
      borderWidth: "2px",
      boxShadow: "md",
    },
    _focus: {
      boxShadow: "outline",
      borderColor: "primary.300!important",
    },
    px: 5,
    py: 3,
    h: "6rem",
    w: "10rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: 2,
  }),
};

export default PaymentCard;
